import './Orders.scss'
import image01 from './images/img_01.png'
import React from 'react'
import { OrdersDesktop, OrdersMobile } from './components'

// const orders = [
//     {
//         id: 111001, date: '01.01.2023', newPrice: 2300, oldPrice: 3000, status: 'Ожидает получения', delivery:
//             { 
//                 type: 'Самовывоз',
//                 address: 'СДЭК, г. Москва, Ходынский б-р, д. 4, ТРЦ "Авиапарк", 3 этаж'
//             },
//         items: [{
//             collection: 'Streetwear Collection',
//             name: 'Велосипедки slim',
//             newPrice: 999,
//             oldPrice: 1999,
//             size: 'S',
//             color: 'Голубой',
//             article: '12046067',
//             images: [image01],
//             quantity: 1,
//         }],
//     },
//     {
//         id: 111002, date: '01.01.2023', newPrice: 2300, oldPrice: 3000, status: 'Ожидает получения', delivery:
//             { 
//                 type: 'Самовывоз',
//                 address: 'СДЭК, г. Москва, Ходынский б-р, д. 4, ТРЦ "Авиапарк", 3 этаж'
//             },
//         items: [{
//             collection: 'Streetwear Collection',
//             name: 'Велосипедки slim',
//             newPrice: 999,
//             oldPrice: 1999,
//             size: 'S',
//             color: 'Голубой',
//             article: '12046067',
//             images: [image01],
//             quantity: 1,
//         }, {
//             collection: 'Streetwear Collection',
//             name: 'Велосипедки slim',
//             newPrice: 999,
//             oldPrice: 1999,
//             size: 'S',
//             color: 'Голубой',
//             article: '12046067',
//             images: [image01],
//             quantity: 1,
//         }],
//     }
// ]

export const Orders = ({ orders, isPayButton, showOrder, hideOrder, order }) => {
    return (
        <>
            <OrdersDesktop isPayButton={isPayButton} orders={orders} />
            <OrdersMobile
                isPayButton={isPayButton}
                orders={orders}
                showOrder={showOrder}
                hideOrder={hideOrder}
                order={order}
            />
        </>
    )
}