import { ProductCardSmall, TotalPriceBlock } from 'src/components'
import './OrderInfo.scss'
import React from 'react'
import { NavLink } from 'react-router-dom'

export const OrderInfo = ({ deleteOrder, order }) => {
    return (
        <div className='order-info'>
            <button onClick={deleteOrder} className='flex flex--ai-c flex--g-10'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M13.5 9L10.5 12L13.5 15' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                    <rect x='23' y='1' width='22' height='22' rx='11' transform='rotate(90 23 1)' stroke='#676785' strokeOpacity='0.5' strokeWidth='2' />
                </svg>
                <p className='text text--16-14 text--500'>Вернуться назад</p>
            </button>

            <div className='order-info__grid mt--40-20'>
                <div className='order-info__info flex flex--column flex--g-20'>
                    <div>
                        <h3 className='order-info__order-title'>№{order.id}</h3>
                        <p className='text' style={{ marginTop: 3 }}>{order.status}</p>
                    </div>

                    <div>
                        <p className='text text--500 text--inter text--half-grey'>Дата заказа:</p>
                        <p className='text text--16-14' style={{ marginTop: 3 }}>{order.date}</p>
                    </div>

                    <div>
                        <p className='text text--500 text--inter text--half-grey'>Доставка:</p>
                        <div>
                            <p className='text text--16-14 text--dark' style={{ marginTop: 3 }}>{order.delivery.type}</p>
                            <p className='text text--16-14'>{order.delivery.address}</p>
                        </div>
                    </div>

                    <div className='divider'></div>

                    <TotalPriceBlock delivery={order.deliveryPrice} totalPrice={order.itemsPrice} totalPriceWithoutDiscount={order.itemsPrice} />

                </div>

                <NavLink to={order.paymentUrl} className='order-info__pay-btn'>
                    <button className='btn btn--sm'>Оплатить</button>
                </NavLink>

                <div className='order-info__products flex flex--column flex--g-20'>
                    <h3 className='order-info__basket-title'>Товары</h3>

                    {order.items.map((orderItem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className='divider'></div>
                                <ProductCardSmall product={orderItem} index={index} />
                            </React.Fragment>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}