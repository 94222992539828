import { useContext } from 'react'
import './BasketOrder.scss'
import { BasketOrderAuthorized, BasketOrderUnauthorized } from './components'
import { AppContext } from 'src'

export const BasketOrder = ({ className }) => {
    const { basketStore } = useContext(AppContext)

    const auth = true
    const isBasketLoading = !basketStore.basket ? (!basketStore.basket || basketStore.loading) : false

    return (
        isBasketLoading
            ? <div className={'basket-order placeholder ' + className} data-attr='placeholder'></div>
            : <div className={'block basket-order ' + className}>
                {auth
                    ? <BasketOrderAuthorized />
                    : <BasketOrderUnauthorized /> 
                }
            </div>
    )
}