import { makeAutoObservable, toJS } from 'mobx'
import { getPendingOrders } from 'src/api/checkout'

import image01 from '../components/Orders/images/img_01.png'

class OrderStore {
    
    pendingOrders = []

    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    *getPendingOrders() {
        try {
            this.loading = true
            const getResponse = yield getPendingOrders()

            // this.pendingOrders = getResponse

            const statusMap = {
                'pending': 'Ожидание оплаты'
            }

            const mapPendingOrders = (orders) => {
                return orders.map(order => {
                    const deliveryTypesObj = {
                        '138': 'СДЕК ПВЗ',
                        '139': 'СДЕК Курьер',
                        '366': 'СДЕК Постамат',
                    }

                    const deliveryData = {}

                    // самовывоз из клуба
                    const isPickupPoint = order.meta.find(meta => meta.key === 'pickup_point_code')
                    if (isPickupPoint) {
                        const deliveryClub = order.meta.find(meta => meta.key === 'notes')?.value
                        deliveryData.type = 'Самовывоз'
                        deliveryData.address = deliveryClub
                    } else {
                        // cdek

                        // 366 = постамат
                        // 138 = ПВЗ
                        const tariffCode = order.meta.find(meta => meta.key === 'tariff_code')?.value
                        if (tariffCode === '138' || tariffCode === '366') {
                            deliveryData.type = deliveryTypesObj[tariffCode]
                            deliveryData.address = order.meta.find(meta => meta.key === 'pvz_address')?.value
                        } else if (tariffCode === '139') {
                            // 139 = курьер
                            deliveryData.type = deliveryTypesObj[tariffCode]
                            deliveryData.address = `${order.shipping.state}, ${order.shipping.city}, ${order.shipping.address_1}`
                        }
                    }

                    const orderItemsPrice = order.shipping_total
                        ? parseFloat(order.price) - parseFloat(order.shipping_total)
                        : parseFloat(order.price)

                    return {
                        id: order.id,
                        date: new Date(order.date.date).toLocaleDateString(),
                        meta: order.meta,
                        newPrice: order.price,
                        oldPrice: null,
                        itemsPrice: orderItemsPrice,
                        status: statusMap[order.status],
                        paymentUrl: 'https://yoomoney.ru/checkout/payments/v2/contract/bankcard?orderId=' + order.transaction_id,
                        delivery: deliveryData,
                        deliveryPrice: order.shipping_total,
                        items: Object.values(order.items).map((item) => {

                            return {
                                id: item.id,
                                size: item.name.split(' - ')[1],
                                newPrice: item.price,
                                oldPrice: null,
                                quantity: item.quantity,
                                images: [image01],
                            }
                        })
                    }
                })
            }
            const mappedPendingOrders = mapPendingOrders(getResponse)
            // console.log(toJS(mappedPendingOrders))
            this.pendingOrders = mappedPendingOrders
        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    setLoading(loading) {
        this.loading = loading
    }

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default OrderStore