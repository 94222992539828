import axios from 'axios'

export const getStreets = async (cityFias = '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', streetName = 'Миклухо') => {
    try {
        const response = await axios.post('https://api.ddxmerch.ru/wp-json/wc/custom/streets', {
            city_fias: cityFias,
            street_name: streetName,
        })
        
        return response.data
    } catch (err) {
        console.log(err)
    }
}