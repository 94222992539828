import { SelectBase } from '../common'
import './SelectSmall.scss'

export const SelectSmall = ({ placeholder, options = [], value, onChange, onBlur, error }) => {
    return (
        <SelectBase
            classPostfix='small'
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
        />
    )
}