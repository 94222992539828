import { NavLink } from 'react-router-dom'
import './BasketIcon.scss'

export const BasketIcon = ({ itemCount, ...props }) => {
    const basketIconClassNames = itemCount
        ? 'flex flex--jc-c flex--ai-c basket-icon__count basket-icon__count--active'
        : 'flex flex--jc-c flex--ai-c basket-icon__count'

    return (
        <NavLink to='/basket'>
            <div className='header-icon-stroke basket-icon' {...props}>
                <div className={basketIconClassNames}>
                    <p className='text text--inter text--10 text--700 text--white'>{itemCount}</p>
                </div>

                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8 8L8 7C8 4.79086 9.79086 3 12 3V3C14.2091 3 16 4.79086 16 7L16 8' strokeWidth='2' strokeLinecap='round' />
                    <path d='M15 14V12' strokeWidth='2' strokeLinecap='round' />
                    <path d='M9 14V12' strokeWidth='2' strokeLinecap='round' />
                    <path d='M4 12C4 10.1144 4 9.17157 4.58579 8.58579C5.17157 8 6.11438 8 8 8H16C17.8856 8 18.8284 8 19.4142 8.58579C20 9.17157 20 10.1144 20 12V13C20 16.7712 20 18.6569 18.8284 19.8284C17.6569 21 15.7712 21 12 21V21C8.22876 21 6.34315 21 5.17157 19.8284C4 18.6569 4 16.7712 4 13V12Z' strokeWidth='2' />
                </svg>
            </div>
        </NavLink>
    )
}