export const PaginationButton = ({ left, ...props }) => {
    const buttonClasses = 'pagination__btn'
        + (left ? ' pagination__btn--left' : '')

    return (
        <button {...props} className={buttonClasses}>
            <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1 1L7 7L1 13' strokeWidth='2' strokeLinecap='round' />
            </svg>
        </button>
    )
}
