import React from 'react'
import './OrderList.scss'
import { addSeparators } from 'src/utils'

export const OrderList = ({ selectOrder, orders }) => {
    return (
        <div className='order-list'>
            {orders.map((order, index) => {
                const isDivider = index < orders.length - 1
                return (
                    <React.Fragment key={index}>
                        <div className='order-list__order'>
                            <button onClick={selectOrder(order)} className='order-list__order-btn'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='0 0 6 8' fill='none'>
                                    <path d='M1.5 7L4.5 4L1.5 1' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                </svg>
                            </button>

                            <div className='order-list__order-header'>
                                <h3>№{order.id}</h3>
                                <p className='text text--14-12'>{order.status}</p>
                                <p className='text text--14-12'>
                                    Сумма заказа:
                                    <span className='text text--14-12 text--700 text--dark text--uppercase'> {addSeparators(parseInt(order.newPrice))} ₽</span>
                                </p>
                            </div>

                            <div className='order-list__order-images'>
                                {order.items.map((orderItem, index) => {
                                    return (
                                        <img key={index} src={orderItem.images[0]} alt='order' />
                                    )
                                })}
                            </div>
                        </div>

                        {isDivider &&
                            <div className='divider'></div>}
                    </React.Fragment>
                )
            })}
        </div>
    )
}