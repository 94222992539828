import { useNavigate } from 'react-router-dom'
import { Price } from '../common'
import './ProductCardSlide.scss'
import { useImageOnLoad } from 'src/hooks'
import { yandexEcommerce, ym } from 'src/analytics'


export const ProductCardSlide = ({ product, index }) => {
    const navigate = useNavigate()
    const handleCardClick = () => {
        ym.ProductClick()

        const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
            ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
            : product.collection + '/Одежда/' + product.name.split(' «')[0]

        const mappedForYandexEcommerce = {
            id: product.databaseId,
            name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
            price: parseFloat(product.price),
            brand: 'DDX Fitness',
            category,
            variant: product.name.split(' ').slice(-1).join(''),
            list: '',
            position: index + 1,
        }

        yandexEcommerce.clickProduct(mappedForYandexEcommerce)
        
        navigate('/products/' + product.databaseId)
    }

    const images = product.galleryImages?.nodes
    const isSecondImage = images[1]

    const isMainImageLoading = useImageOnLoad(images[0]?.sourceUrl)
    const isSecondImageLoading = useImageOnLoad(images[1]?.sourceUrl)

    const oldPrice = product?.regularPrice?.split(',').shift()
    const newPrice = product?.price?.split(',').shift()
    
    return (
        <div onClick={handleCardClick} className='product-card-slide'>
            <div className='product-card-slide__image-container'>
                <div className='product-card-slide__image'>
                    {!isMainImageLoading &&
                        <img
                            src={images[0]?.sourceUrl}
                            srcSet={images[0]?.srcSet}
                            alt={images[0]?.altText}
                        />}
                </div>

                {!isSecondImageLoading && isSecondImage &&
                    <div className='product-card-slide__image-hover'>
                        <img
                            src={images[1]?.sourceUrl}
                            srcSet={images[1]?.srcSet}
                            alt={images[1]?.altText} 
                        />
                    </div>}
            </div>

            <div className='product-card-slide__info'>
                <p className='product-card-slide__name'>{product.name}</p>

                <Price
                    className='product-card-slide__price'
                    onSale={product.onSale}
                    oldPrice={oldPrice}
                    newPrice={newPrice}
                />
            </div>
        </div>
    )
}