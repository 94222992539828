import { addSeparators } from 'src/utils'
import './TotalPriceBlock.scss'

export const TotalPriceBlock = ({ totalPrice, totalPriceWithoutDiscount, promoDiscount, delivery }) => {
    const discount = totalPriceWithoutDiscount - totalPrice
    // ДОБАВИТЬ ПРОВЕРКУ ЧТО ЕСТЬ ЧИСЛО
    const isPromoDiscount = !isNaN(promoDiscount)
    const isDiscount = totalPrice !== totalPriceWithoutDiscount
    const isDelivery = !isNaN(delivery) && (delivery > 0)
    const totalPriceWithDelivery = parseFloat(totalPrice) + parseFloat(delivery || 0)

    return (
        <div className='total-price-block flex flex--column'>
            <div className='flex flex--jc-sb flex--ai-c'>
                <p className='text text--16-14'>Общая стоимость товаров</p>
                <p className='text text--16-14 text--dark'>{addSeparators(parseInt(totalPriceWithoutDiscount))} ₽</p>
            </div>

            {isDiscount && 
                <div className='flex flex--jc-sb flex--ai-c'>
                    <p className='text text--16-14'>Скидка</p>
                    <p className='text text--16-14'>- {addSeparators(parseInt(discount))} ₽</p>
                </div>}

            {isPromoDiscount &&
                <div className='flex flex--jc-sb flex--ai-c'>
                    <p className='text text--16-14'>Скидка по промокоду</p>
                    <p className='text text--16-14'>{addSeparators(parseInt(promoDiscount))} ₽</p>
                </div>}

            {isDelivery &&
                <div className='flex flex--jc-sb flex--ai-c'>
                    <p className='text text--16-14'>Доставка</p>
                    <p className='text text--16-14 text--dark'>{addSeparators(parseInt(delivery))} ₽</p>
                </div>}

            <div className='divider'></div>

            <div className='flex flex--jc-sb flex--ai-c'>
                <p className='text text--18 text--700'>Итого</p>
                <p className='text text--18 text--700 text--dark'>{addSeparators(parseInt(totalPriceWithDelivery))} ₽</p>
            </div>
        </div>
    )
}