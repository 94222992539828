import { Checkbox, PhoneInput, RadioButton, Select, SelectOnYandexMap, SelectSlide, TotalPriceBlock } from 'src/components'
import './OrderForm.scss'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { getRegions } from 'src/api/cdek/get-regions'
import { calculateTariffs } from 'src/api/cdek/calculate-tariffs'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validateEmail, validatePhone } from 'src/utils'

import personalData from 'src/files/personal_data.pdf'
import offer from 'src/files/offer.pdf'

import { createOrder } from 'src/api/checkout/create-order'
import { getStreets } from 'src/api/geohelper/get-streets'
import { ym } from 'src/analytics'
import { TotalPriceBlockPlaceholder } from 'src/components/TotalPriceBlock/TotalPriceBlockPlaceholder'
import { toJS } from 'mobx'


//////////////////////////////////////////////////////////
//// ОБЩИЕ ДАННЫЕ И ФУНКЦИИ //////////////////////////////

const deliveryWays = [
    { id: 1, name: 'Сдек' },
    { id: 0, name: 'Самовывоз' },
]

const paymentWays = [
    { id: 1, name: 'Картой онлайн' }
]

const handleCdekDeliveryDaysWord = (number) => {     
    return number > 1
        ? 'дней'
        : 'дня'
}

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const OrderForm = observer(({ openOrderChangedStockModal }) => {
    const { basketStore, productStore } = useContext(AppContext)

    const isBasketLoading = !basketStore.basket ? (!basketStore.basket || basketStore.loading) : false

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            
            comment: '', // optional
            personalData: true,
            rim: true,
            
            // способ доставки
            deliveryWay: deliveryWays[0],

            // способ доставки - самовывоз
            pickupPoint: {},

            // способ доставки - сдек
            region: {},
            cdekDeliveryWay: {},

            // сдек - пвз
            cdekPvz: {},

            // сдек - постамат
            cdekPostamat: {},

            // сдек - до двери
            addressStreet: '',
            addressHome: '',
            addressFlat: '',

            paymentWay: paymentWays[0],
        },
        validate: values => {
            const errors = {}

            if (values.deliveryWay.name === 'Сдек' && !values.region?.id) {
                errors.region = 'This field is required'
            }

            if (values.deliveryWay.name === 'Самовывоз' && values.pickupPoint?.id === undefined) {
                errors.pickupPoint = 'This field is required'
            }

            if (values.deliveryWay.name === 'Сдек' && values.region?.id) {
                switch (values.cdekDeliveryWay.id) {
                    case 138:
                        // pvz
                        if (!values.cdekPvz?.id) {
                            errors.cdekPvz = 'This field is required'
                        }
                        break
                    case 366:
                        // postamat
                        if (!values.cdekPostamat?.id) {
                            errors.cdekPostamat = 'This field is required'
                        }
                        break
                    case 139:
                        // delivery
                        if (!values.addressStreet.id) {
                            errors.addressStreet = 'This field is required'
                        }
                        if (!values.addressHome.trim().length) {
                            errors.addressHome = 'This field is required'
                        }
                        if (!values.addressFlat.trim().length) {
                            errors.addressFlat = 'This field is required'
                        }
                        break
                    default:
                        break
                }
            }
            return errors
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required().max(50),
            lastName: Yup.string().required().max(50),
            phone: Yup.string().required().test('invalid phone', 'invalid phone', validatePhone),
            email: Yup.string().max(50).test('invalid email', 'invalid email', validateEmail),
            comment: Yup.string().max(255),
            personalData: Yup.bool().required().test('invalid pd', 'invalid pd', (pd) => pd),
        }),
        onSubmit: async (values, actions) => {
            const data = {
                billing: {
                    first_name: values.firstName.trim(),
                    last_name: values.lastName.trim(),
                    email: values.email.trim(),
                    phone: `+${values.phone.replace(/[^\d]/g, '')}`,
                    country: 'RU',
                },
                shipping: {
                    first_name: values.firstName.trim(),
                    last_name: values.lastName.trim(),
                    email: values.email.trim(),
                    phone: `+${values.phone.replace(/[^\d]/g, '')}`,
                    country: 'RU',
                },
                meta_data: [
                    {
                        key: 'rim',
                        value: values.personalData,
                    },
                    {
                        key: 'personal_data',
                        value: values.rim,
                    }
                ],
                customer_note: values.comment,
                line_items: basketStore.basketItems,
                // line_items: [
                //     { product_id: 73, quantity: 5 },
                //     { product_id: 92, quantity: 1 },
                // ],
                payment_method: 'yookassa_bank_card',
                payment_method_title: 'Банковские карты — Мир, Visa, Mastercard и Maestro',
            }

            if (values.deliveryWay.name === 'Сдек') {
                data.shipping_lines = [
                    {
                        method_title: 'CDEK: cdek_official_366, (1-1 дней)',
                        method_id: 'official_cdek'
                    }
                ]

                data.meta_data = [
                    ...data.meta_data,
                    {
                        key: 'city_code',
                        value: values.region.id,
                    }
                ]

                data.billing.state = values.region.region
                data.billing.city = values.region.name

                data.shipping.state = values.region.region
                data.shipping.city = values.region.name

                switch (values.cdekDeliveryWay.id) {
                    case 138:
                        // pvz
                        data.billing.postcode = values.cdekPvz?.postcode
                        data.shipping.postcode = values.cdekPvz?.postcode

                        data.meta_data = [
                            ...data.meta_data,
                            {
                                key: 'tariff_code',
                                value: '138'
                            },
                            {
                                key: 'pvz_code',
                                value: values.cdekPvz?.id
                            },
                            {
                                key: 'pvz_address',
                                value: values.cdekPvz.address_full,
                            }
                        ]
                        break
                    case 366:
                        // postamat
                        data.billing.postcode = values.cdekPostamat?.postcode
                        data.shipping.postcode = values.cdekPostamat?.postcode

                        data.meta_data = [
                            ...data.meta_data,
                            {
                                key: 'tariff_code',
                                value: '366'
                            },
                            {
                                key: 'pvz_code',
                                value: values.cdekPostamat?.id
                            },
                            {
                                key: 'pvz_address',
                                value: values.cdekPostamat.address_full,
                            }
                        ]
                        break
                    case 139:
                        // delivery
                        data.billing.address_1 = `${values.addressStreet.name}, ${values.addressHome} кв.${values.addressFlat}`
                        data.billing.address_2 = ''
                        data.billing.postcode = ''

                        data.shipping.address_1 = `${values.addressStreet.name}, ${values.addressHome} кв.${values.addressFlat}`
                        data.shipping.address_2 = ''
                        data.shipping.postcode = ''

                        data.meta_data = [
                            ...data.meta_data,
                            {
                                key: 'tariff_code',
                                value: '139'
                            },
                        ]
                        break
                    default:
                        break
                }
            } else if (values.deliveryWay.name === 'Самовывоз') {
                data.shipping.address_1 = `${values.pickupPoint.address}`
                data.shipping_lines = [
                    {
                        method_title: `Самовывоз из фитнес-клуба ${values.pickupPoint?.name}`,
                        method_id: 'local_pickup'
                    }
                ]
                data.meta_data = [
                    ...data.meta_data,
                    {
                        key: 'pickup_point_code',
                        value: values.pickupPoint.id,
                    }
                ]
            }

            // console.log(data, values.cdekPvz, values.cdekPostamat)
            basketStore.setLoading(true)
            ym.InitiateCheckout()
            createOrder(data)
                .then(res => {
                    if (res.redirect) {
                        const orderProducts = basketStore.basketItemsWithBaseId.map((product) => {
                            const productFromStorage = productStore.getProductFromStorage(product.id)
                            return { ...productFromStorage, quantity: product.quantity }
                        })
            
                        const productsForYandexEcommerce = orderProducts.map((product, index) => {
                            const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                                ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                                : product.collection + '/Одежда/' + product.name.split(' «')[0]
            
                            const mappedForYandexEcommerce = {
                                id: product.databaseId,
                                name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                                price: parseFloat(product.price),
                                brand: 'DDX Fitness',
                                category,
                                variant: product.name.split(' ').slice(-1).join(''),
                                quantity: product.quantity,
                                list: '',
                                position: index + 1,
                            }
                            return mappedForYandexEcommerce
                        })
                        
                        localStorage.setItem(`order-${res.order_id}`, JSON.stringify(productsForYandexEcommerce))

                        window.location.href = res.redirect
                    } else {

                        if (res.includes('отсутствует')) {
                            const itemName = res.split('отсутствует')[0]
                            const errorText = itemName + ' закончились'
                            openOrderChangedStockModal(errorText)
                        }

                        if (res.includes('недостаточно единиц')) {
                            const itemName = res.split('товара')[1].split('для выполнения')[0]
                            const errorText = itemName + ' осталось меньше чем вы добавили в корзину. Измените количество чтобы продолжить оформление заказа'
                            openOrderChangedStockModal(errorText)
                        }

                    }
                    // console.log(res)
                })
                .catch((error) => {
                    console.log(error)
                    window.location.href = 'https://ddxmerch.ru/500'
                })
                .finally(() => {
                    basketStore.setLoading(false)
                })
        },
    })


    // РЕГИОНЫ ДОСТАВКИ (СДЕК)
    const [regions, setRegions] = useState([])
    const [regionSearchString, setRegionSearchString] = useState('')
    const isSelectedRegion = formik.values.region?.id

    // СПОСОБЫ ДОСТАВКИ (СДЕК)
    const [cdekDeliveryWays, setCdekDeliveryWays] = useState([])

    // ПОЛУЧЕМ РЕГИОНЫ
    const [regionsLoading, setRegionsLoading] = useState(false)

    useEffect(() => {
        const mapRegions = (data) => data.map((region) => ({ 
            id: region.code,
            region: region.region,
            sub_region: region.sub_region,
            name: region.city,
            address: `${region.country}, ${region.region}, ${region.sub_region}`,
            fias: region.fias_guid,
        }))

        // basketStore.setLoading(true)
        setRegionsLoading(true)
        getRegions()
            .then(res => setRegions(mapRegions(res)))
            .finally(() => setRegionsLoading(false))
    }, [])

    const regionsSearchText = regionsLoading
        ? 'Загрузка...'
        : 'Ничего не найдено'
        

    // ПОЛУЧАЕМ ТАРИФЫ ПО ВЫБРАННОМУ РЕГИОНУ (С ДЕБАУНСОМ)
    useEffect(() => {
        const customCdekNames = {
            '138': 'ПВЗ',
            '139': 'Курьер',
            '366': 'Постамат',
        }

        if (formik.values.region.id) {
            const tariffCodes = [139, 138, 366]
            const filterDeliveryWays = (data = []) => data.filter((deliveryWay) => tariffCodes.includes(deliveryWay.tariff_code))
            const mapTariffCodes = (data) => data.map((tariffCode) => {
                return { 
                    id: tariffCode.tariff_code,
                    name: customCdekNames['' + tariffCode.tariff_code],
                    price: tariffCode.delivery_sum,
                    periodMin: tariffCode.period_min,
                }
            })

            const timer = setTimeout(() => {
                basketStore.setLoading(true)

                const city = formik.values.region?.name || ''
                const street = formik.values.addressStreet?.name || ''
                const home = formik.values.addressHome || ''

                const fullAddress = `${city}, ${street}, ${home}`
                const code = formik.values.region.id

                calculateTariffs(code, fullAddress, basketStore.basketItems)
                    .then(res => {
                        const cdekDeliveryWaysTemp = mapTariffCodes(filterDeliveryWays(res))
                        setCdekDeliveryWays(cdekDeliveryWaysTemp)
                        formik.setFieldValue('cdekDeliveryWay', cdekDeliveryWaysTemp[0])
                    })
                    .finally (() => {
                        basketStore.setLoading(false)
                    })
            }, 300)
    
            return () => {
                clearTimeout(timer)
            }
        }
    },[
        formik.values.region?.id, 
        formik?.values.addressStreet?.id,
        formik?.values.addressHome
    ])

    const isCdekDeliveryWayChecked = (id) => id === formik.values.cdekDeliveryWay?.id
    const handleCdekDeliveryWay = (way) => () => formik.setFieldValue('cdekDeliveryWay', way)

    const isDeliveryPickup = formik.values.deliveryWay.id === 0

    const handleInputClassNames = (name) => {
        const isError = formik.errors[name] && formik.touched[name]
        return isError ? 'input input--error' : 'input'
    }

    const isOrderButtonDisabled = Object.keys(formik.errors).length
    const isCdekDeliveryWays = cdekDeliveryWays.length > 0

    // УЛИЦЫ ПО GEOHELPER
    const [streetSearchString, setStreetSearchString] = useState('')
    const [streets, setStreets] = useState([])

    // ПОЛУЧЕМ УЛИЦЫ (С ДЕБАУНСОМ)
    const [streetsLoading, setStreetsLoading] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (streetSearchString.trim().length > 0) {
                setStreetsLoading(true)
                getStreets(formik.values.region.fias, streetSearchString)
                    .then(res => {
                        // console.log(res.result)
                        const mappedStreets = res.result.map(street => {
                            return { 
                                id: street.id, 
                                name: `${street.localityType.localizedNamesShort.ru} ${street.name}`,
                                address: street.postCode ? `Почтовый индекс ${street.postCode}` : '' } 
                        })
                        setStreets(mappedStreets)
                    })
                    .finally(() => {
                        setStreetsLoading(false)
                    })
            }
        }, 450)

        return () => {
            clearTimeout(timer)
        }
    }, [streetSearchString])

    const streetsSearchText = streetsLoading
        ? 'Поиск...'
        : 'Ничего не найдено'


    // ОЧИЩАЕМ УЛИЦЫ ПОСЛЕ СМЕНЫ РЕГИОНА
    useEffect(() => {
        setStreetSearchString('')
        setStreets([])
        formik.setFieldValue('addressStreet', {})
    }, [formik.values.region?.id])

    useEffect(() => {
        if (!isDeliveryPickup) {
            basketStore.updateDeliveryPrice(formik.values.cdekDeliveryWay?.price)
        } else {
            basketStore.updateDeliveryPrice(0)
        }
    }, [formik.values.cdekDeliveryWay?.price, isDeliveryPickup])


    return (
        <form onSubmit={formik.handleSubmit} className='order-form mt--60-30'>
           
            <fieldset className='order-form__fieldset'>
                <label className='order-form__label'>Способ доставки</label>
                
                <SelectSlide
                    name='deliveryWay' id='deliveryWay'
                    onChange={formik.handleChange}
                    value={formik.values.deliveryWay} options={deliveryWays}
                />
            </fieldset>

            {isDeliveryPickup &&
                <fieldset className='order-form__fieldset'>
                    <label className='order-form__label'>Выберите пункт самовывоза</label>
                    <SelectOnYandexMap
                        select
                        height='340px'
                        key={formik.values.pickupPoint?.id + formik.values.deliveryWay?.id}
                        type='PICKUP'
                        error={formik.errors.pickupPoint && formik.touched.pickupPoint}
                        placeholder='Выберите клуб самовывоза' name='pickupPoint' id='pickupPoint'
                        onChange={formik.handleChange}
                        value={formik.values.pickupPoint}
                        formik
                    />
                </fieldset>}

            {!isDeliveryPickup && <>
                <fieldset className='order-form__fieldset'>
                    <label className='order-form__label'>Выберите ваш город</label>
                    <Select
                        search formik
                        searchString={regionSearchString}
                        setSearchString={setRegionSearchString}
                        notFoundText={regionsSearchText}
                        error={formik.errors.region && formik.touched.region}
                        placeholder='Ваш город' name='region' id='region'
                        onChange={formik.handleChange}
                        value={formik.values.region} options={regions}
                    />
                </fieldset>

                {isSelectedRegion && 
                    <fieldset className='order-form__fieldset'>
                        <label className='order-form__label'>Выберите способ доставки СДЭК</label>
                        <div className='flex flex--column flex--g-14'>
                            {isCdekDeliveryWays ?

                                cdekDeliveryWays.map((way, index) => {
                                    return (
                                        <RadioButton
                                            key={index}
                                            value={way}
                                            checked={isCdekDeliveryWayChecked(way.id)}
                                            onChange={handleCdekDeliveryWay(way)}
                                        >
                                            {/* прибавляем + 2 дня к минимальной доставке */}
                                            {`${way.name} от ${way.periodMin + 2} ${handleCdekDeliveryDaysWord(way.periodMin + 2)}, от ${way.price} руб.`}
                                        </RadioButton>
                                    )
                                })

                                :
                                
                                <>
                                    <div className='placeholder placeholder--h-16' style={{ maxWidth: 350 }}></div>
                                    <div className='placeholder placeholder--h-16' style={{ maxWidth: 350 }}></div>
                                    <div className='placeholder placeholder--h-16' style={{ maxWidth: 250 }}></div>
                                </>
                            }
                        </div>
                    </fieldset>}

                {formik.values.cdekDeliveryWay?.id === 138 && formik.values.region?.id && 
                    <fieldset className='order-form__fieldset'>
                        <SelectOnYandexMap
                            select
                            height='340px'
                            key={formik.values.region?.id + formik.values.cdekDeliveryWay?.id}
                            regionId={formik.values.region?.id}
                            type='PVZ'
                            error={formik.errors.cdekPvz && formik.touched.cdekPvz}
                            placeholder='Выберите пункт получения' name='cdekPvz' id='cdekPvz'
                            onChange={formik.handleChange}
                            value={formik.values.cdekPvz}
                            formik
                        />
                    </fieldset>}
                    
                {formik.values.cdekDeliveryWay?.id === 366 && formik.values.region?.id && 
                    <fieldset className='order-form__fieldset'>
                        <SelectOnYandexMap
                            select
                            height='340px'
                            key={formik.values.region?.id + formik.values.cdekDeliveryWay?.id}
                            regionId={formik.values.region?.id}
                            type='POSTAMAT'
                            error={formik.errors.cdekPostamat && formik.touched.cdekPostamat}
                            placeholder='Выберите постамат' name='cdekPostamat' id='cdekPostamat'
                            onChange={formik.handleChange}
                            value={formik.values.cdekPostamat}
                            formik
                        />
                    </fieldset>}

                {formik.values.cdekDeliveryWay?.id === 139 && 
                    <fieldset className='order-form__fieldset'>
                        <label className='order-form__label'>Укажите адрес доставки</label>

                        <div>
                            <Select
                                search formik
                                searchString={streetSearchString}
                                setSearchString={setStreetSearchString}
                                notFoundText={streetsSearchText}
                                error={formik.errors.addressStreet && formik.touched.addressStreet}
                                placeholder='Улица' name='addressStreet' id='addressStreet'
                                onChange={formik.handleChange}
                                value={formik.values.addressStreet} options={streets}
                            />

                            <div className='grid grid--c-2-2-1 grid--g-10 mt--10'>
                                <input
                                    className={handleInputClassNames('addressHome')}
                                    type='text' placeholder='Дом' name='addressHome' id='addressHome'
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.addressHome}
                                />
                                <input
                                    className={handleInputClassNames('addressFlat')}
                                    type='text' placeholder='Квартира/Офис' name='addressFlat' id='addressFlat'
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.addressFlat}
                                />
                            </div>
                        </div>
                    </fieldset>}
            </>}

            <fieldset className='order-form__fieldset'>
                <label className='order-form__label'>Данные получателя</label>
                    
                <div className='grid grid--c-2-2-1 grid--g-10 mt--10'>
                    <input
                        className={handleInputClassNames('firstName')}
                        type='text' placeholder='Имя' name='firstName' id='firstName'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                    />

                    <input
                        className={handleInputClassNames('lastName')}
                        type='text' placeholder='Фамилия' name='lastName' id='lastName'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                    />

                    <PhoneInput
                        className={handleInputClassNames('phone')}
                        type='text' placeholder='Телефон' name='phone' id='phone'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.phone}
                    />

                    <input
                        className={handleInputClassNames('email')}
                        type='text' placeholder='Email' name='email' id='email'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                </div>
            </fieldset>

            <fieldset className='order-form__fieldset'>
                <label className='order-form__label'>Способ оплаты</label>
                
                <div className='grid grid--c-2-2-1 grid--g-10'>
                    <SelectSlide
                        name='paymentWay' id='paymentWay'
                        onChange={formik.handleChange}
                        value={formik.values.paymentWay} options={paymentWays}
                    />
                </div>
            </fieldset>

            <fieldset className='order-form__fieldset'>
                <label className='order-form__label'>Комментарии к заказу</label>
                <textarea className='textarea' placeholder='Введите комментарии...'></textarea>
            </fieldset>

            {/* tablet and mobile */}
            <div className='order-form__tablet'>

                {isBasketLoading
                    ? <TotalPriceBlockPlaceholder />
                    : <TotalPriceBlock
                        delivery={basketStore.deliveryPrice}
                        totalPrice={basketStore.newPrice}
                        totalPriceWithoutDiscount={basketStore.basketPriceWithoutDiscount}
                    />}

                {/* <div className='grid grid--c-2-2-1 grid--g-10'>
                    <input className='input' placeholder='Введите промокод' type='text' />
                    <button className='order-form__promo-btn btn btn--dark'>Применить</button>
                </div> */}
            </div>

            <div className='flex flex--column flex--g-10'>
                <Checkbox
                    name='personalData' id='personalData'
                    checked={formik.values.personalData}
                    onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                >
                    Я согласен с <a href={offer} target='_blank' rel='noreferrer'>публичным договором-офертой</a> и <a href={personalData} target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                </Checkbox>
                <Checkbox
                    name='rim' id='rim'
                    checked={formik.values.rim}
                    onClick={() => formik.setFieldValue('rim', !formik.values.rim)}
                >
                    Я согласен(а) на <a href='https://www.ddxfitness.ru/consent-advertising/' target='_blank' rel='noreferrer'>рассылку новостей и предложений DDX Fitness</a>
                </Checkbox>
            </div>

            <button
                disabled={isOrderButtonDisabled}
                type='submit'
                className='order-form__pay-btn btn'
            >
                Оплатить
            </button>
        </form>
    )
})