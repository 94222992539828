import { NavLink } from 'react-router-dom'
import './Navigation.scss'

export const Navigation = ({ active, onClick }) => {
    const isActive = (item) => active === item
    const navItemClassNames = (item) => isActive(item)
        ? 'text text--16 text--uppercase text--hover-dark text--dark'
        : 'text text--16 text--uppercase text--hover-dark'

    // const handleClick = (item) => () => onClick(item)

    return (
        <div className='for-customers-navigation flex flex--column flex--g-10 flex--ai-fs'>
            <NavLink to='/for-customers?category=contacts'>
                <button className={navItemClassNames('contacts')}>Контакты</button>
            </NavLink>

            <NavLink to='/for-customers?category=delivery'>
                <button className={navItemClassNames('delivery')}>Доставка и оплата</button>
            </NavLink>

            <NavLink to='/for-customers?category=refund'>
                <button className={navItemClassNames('refund')}>Возврат и обмен</button>
            </NavLink>

            {/* <button onClick={handleClick('loyalty')} className={navItemClassNames('loyalty')}>Программа лояльности</button> */}

            <NavLink to='/for-customers?category=faq'>
                <button className={navItemClassNames('faq')}>Faq</button>
            </NavLink>

            {/* <NavLink to='/for-customers?category=politics'>
                <button className={navItemClassNames('politics')}>Политика конфиденциальности</button>
            </NavLink> */}
        </div>
    )
}