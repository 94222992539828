import { Select, SelectOnYandexMap, YandexMaps } from 'src/components'
import './Contacts.scss'
import { useContext, useState } from 'react';
import { AppContext } from 'src';

export const Contacts = () => {
    const { basketStore } = useContext(AppContext)
    const [pickupPoint, setPickupPoint] = useState({})

    const handlePickupPointSelect = (point) => () => setPickupPoint(point)

    return (
        <div className='for-customers-contacts'>

            <div className='for-customers-contacts__info-grid'>

                <div className='for-customers-contacts__info-grid-wide'>
                    <p className='text'>Режим работы интернет-магазина:</p>
                    <p className='text text--16-14 text--dark mt--10'>9:00-21:00 ежедневно</p>
                </div>

                {/* <div className='for-customers-contacts__info-grid-reg-1'>
                    <p className='text'>Телефон магазина:</p>
                    <p className='text text--16-14 text--dark mt--10'>+ 7 (800) 888-88-88</p>
                </div> */}

                <div className='for-customers-contacts__info-grid-reg-2'>
                    <p className='text'>Email:</p>
                    <p className='text text--16-14 text--dark mt--10'>info@ddxmerch.ru</p>
                </div>

            </div>

            <div>
                <p className='text'>Адреса для самовывоза:</p>
                {/* <Select value={warehouse} onChange={setWarehouse} placeholder='Ничего не выбрано' options={basketStore.warehouses} /> */}
                <div className='flex flex--column flex--g-10 mt--12'>
                    {basketStore.warehouses.map(w => {
                        return (
                            <div onClick={handlePickupPointSelect(w)} key={w.id}>
                                <p className='text text--dark'>{w.name}</p>
                                <p className='text'>{w.address}</p>
                            </div>
                        )
                    })}
                </div>
            </div>

            <SelectOnYandexMap
                // key={1}
                height='340px'
                zoom='9'
                type='PICKUP'
                placeholder='Выберите клуб самовывоза' name='pickupPoint' id='pickupPoint'
                onChange={setPickupPoint}
                value={pickupPoint}
            />
        </div>
    )
}