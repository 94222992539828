import { useContext, useEffect, useRef, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Footer, Header } from 'src/layout'
import { About, Basket, Catalog, Error, ForCustomers, Main, Order, PendingOrders, Product, Profile, Thanks } from 'src/pages'
import { AppContext } from 'src'
import { PaymentError } from './pages/PaymentError/PaymentError'

export const App = () => {
    const scrollWatcher = useRef(null)
    const [scrolledY, setScrolledY] = useState(false)

    const { pathname, search } = useLocation()
    const isMainPage = pathname === '/'

    useEffect(() => {
        const scrollObserver = new IntersectionObserver((entries) => {
            const newValue = !entries[0].isIntersecting
            if (newValue !== scrolledY) {
                setScrolledY(newValue)
            }
        })
        scrollObserver.observe(scrollWatcher.current)
    })

    useEffect(() => {
        scrollWatcher.current.scrollIntoView({ behavior: 'smooth' })
    }, [pathname])

    const { productStore, basketStore, orderStore } = useContext(AppContext)

    useEffect(() => {
        productStore.getProducts()
        basketStore.getBasket()
        // orderStore.getPendingOrders()
    }, []) // eslint-disable-line

    return (
        <div className='app'>
            <div className='app__scroll-watcher' ref={scrollWatcher} />
            <div className='app__hidden-60px-bar'></div>

            <Header scrolledY={scrolledY} light={!isMainPage} />
            <Routes>
                <Route path='/' element={<Main />} />
                {/* <Route path='/products' element={<Catalog key={search + Date.now()} />} /> */}
                <Route path='/products' element={<Catalog key={search} />} />
                <Route path='/products/:id' element={<Product />} />
                <Route path='/basket' element={<Basket />} />
                <Route path='/order' element={<Order />} />
                <Route path='/pending-orders' element={<PendingOrders />} />
                {/* <Route path='/profile' element={<Profile />} /> */}
                <Route path='/for-customers' element={<ForCustomers />} />
                <Route path='/about' element={<About />} />
                <Route path='/thanks' element={<Thanks />} />
                <Route path='/error' element={<PaymentError />} />
                <Route path='/500' element={<Error />} />

                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
            
            <Footer small={isMainPage} />
        </div>
    )
}
