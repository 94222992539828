import { ProductCardSmall } from 'src/components'
import './AddedToBasket.scss'
import { useContext } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const AddedToBasket = observer(({ variationId, onClose }) => {
    const { basketStore } = useContext(AppContext)
    const navigate = useNavigate()

    const formattedBasket = basketStore.basket.map((basketItem) => {
        return {
            id: basketItem.variation.node.parent.node.databaseId,
            variationId: basketItem.variation.node.databaseId,
            collection: 'Streetwear Collection',
            name: basketItem.variation.node.name.split('-').shift(),
            newPrice: parseFloat(basketItem.variation.node.price),
            oldPrice: parseFloat(basketItem.variation.node.regularPrice),
            size: basketItem.variation.node.name.split('-').pop(),
            images: [basketItem.variation.node.image.sourceUrl],
            quantity: basketItem.quantity,
            stockQuantity: basketItem.variation.node.stockQuantity,
        }
    })

    const addedToBasket = formattedBasket.find(basketItem => basketItem.variationId === variationId)
    const alreadyInBasket = formattedBasket.filter(basketItem => basketItem.variationId !== variationId)
    const alreadyInBasketQuantity = alreadyInBasket.reduce((sum, basketItem) => { return sum + (basketItem.quantity)}, 0)

    const handleProductsWord = (number) => {
        if (number > 10) {
            const lastTwoDigits = Number(String(number).split('').slice(-2).join(''))
            if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
                return 'товаров'
            } 
        }

        const lastDigit = Number(String(number).split('').slice(-1).join(''))
        if (lastDigit === 1) {
            return 'товар'
        }
        if (lastDigit >= 2 && lastDigit <= 4) {
            return 'товара'
        }
        if ((lastDigit >= 5 && lastDigit <= 9) || lastDigit === 0) {
            return 'товаров'
        }
    }
    const alreadyInBasketText = `У вас в корзине еще ${alreadyInBasketQuantity} ${handleProductsWord(alreadyInBasketQuantity)}`

    const isAddedToBasket = addedToBasket?.variationId
    const isAlreadyInBasket = alreadyInBasket.length > 0

    const handleQuantityChange = (basketItem = 1, action) => {
        if (action === 'increase' && basketItem.quantity + 1 <= basketItem.stockQuantity) {
            return () => basketStore.updateBasket(basketItem.variationId, basketItem.quantity + 1)
        }

        if (action === 'decrease' && basketItem.quantity - 1 >= 0) {
            return () => basketStore.updateBasket(basketItem.variationId, basketItem.quantity - 1)
        }
    }

    const handleBasketOpen = () => navigate('/basket')
    const stopPropagation = (event) => event.stopPropagation()

    return (
        <div className='added-to-basket' onClick={stopPropagation}>
            <div className='added-to-basket__container'>
                <div className='added-to-basket__header flex flex--ai-c'>
                    <button onClick={onClose}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <rect x='7.41406' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41406 6)' fill='#676785' />
                            <rect x='6' y='17.3138' width='16' height='2' rx='1' transform='rotate(-45 6 17.3138)' fill='#676785' />
                        </svg>
                    </button>
                </div>

                <div className='added-to-basket__body flex flex--column flex--g-30'>
                    <h3 className='added-to-basket__title'>Товар добавлен в корзину</h3>

                    {isAddedToBasket &&
                        <ProductCardSmall
                            product={addedToBasket}
                            key={Date.now()}
                            // onDelete={handleProductDelete(basketItem.variationId)}
                            onIncreaseQuantity={handleQuantityChange(addedToBasket, 'increase')}
                            onDecreaseQuantity={handleQuantityChange(addedToBasket, 'decrease')}
                        />}

                    {isAlreadyInBasket ? <>

                        <div className='divider mt--10'></div>
                        <p className='text text--16 mt--10'>{alreadyInBasketText}</p>
                        
                        <div className='added-to-basket__already-added flex flex--column flex--g-30'>
                            {alreadyInBasket.map(basketItem => {
                                return (
                                    <ProductCardSmall
                                        product={basketItem}
                                        key={basketItem.variationId}
                                        // onDelete={handleProductDelete(basketItem.variationId)}
                                        onIncreaseQuantity={handleQuantityChange(basketItem, 'increase')}
                                        onDecreaseQuantity={handleQuantityChange(basketItem, 'decrease')}
                                    />
                                )
                            })}
                        </div>
                        
                    </> : <div></div>}
                </div>

                <div className='added-to-basket__footer flex flex--jc-sb flex--g-10'>
                    <button onClick={onClose} className='btn btn--light'>ПРОДОЛЖИТЬ ПОКУПКИ</button>
                    <button onClick={handleBasketOpen} className='btn'>ПЕРЕЙТИ В КОРЗИНУ</button>
                </div>
            </div>
        </div>
    )
})