import { NavLink } from 'react-router-dom'
import { AboutBlockSmall, Container, SwiperSlider, ProductCardSlide } from 'src/components'
import './Main.scss'
import { Hero } from './Hero'
import { useContext, useEffect, useRef } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { ProductCardSlidePlaceholder } from 'src/components/ProductCard/ProductCardSlide/ProductCardSlidePlaceholder'
import { useIsInViewport } from 'src/hooks'
import { yandexEcommerce } from 'src/analytics'

export const Main = observer(() => {
    const { productStore } = useContext(AppContext)
    const slides = productStore.recommended.map((product, index) => {
        return <ProductCardSlide product={product} key={index} index={index} />
    })

    const slidesPlaceholder = [
        <ProductCardSlidePlaceholder key='placeholder_slide_01' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_02' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_03' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_04' />,
    ]

    // следим когда слайдер с рекомендациями попадает в зону видимости
    const popularProductsSwiperElement = useRef(null)
    const isIntersected = useIsInViewport(popularProductsSwiperElement)

    useEffect(() => {
        if (isIntersected && slides?.length !== 0) {
            const productsForYandexEcommerce = productStore.recommended.map((product, index) => {
                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(product.price),
                    brand: 'DDX Fitness',
                    category,
                    variant: product.name.split(' ').slice(-1).join(''),
                    list: 'популярное',
                    position: index + 1,
                }
                // console.log(mappedForYandexEcommerce)
                return mappedForYandexEcommerce
            })

            yandexEcommerce.openCatalog(productsForYandexEcommerce)
        }
    }, [isIntersected, slides])

    return (
        <div className='main'>
            <Hero />

            <Container>
                <div ref={popularProductsSwiperElement}>
                    <SwiperSlider
                        offset
                        title='Популярное'
                        slides={slides.length ? slides : slidesPlaceholder}
                    />
                </div>

                <NavLink to='/products' className='main__btn'>
                    <p>Перейти в каталог</p>
                    <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M1.5 9L9.5 1M9.5 1H1.5M9.5 1V9' strokeWidth='2' strokeLinecap='round' />
                    </svg>
                </NavLink>
                <div id='about'></div>
            </Container>

            <AboutBlockSmall />
        </div>
    )
})