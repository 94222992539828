import { useContext, useEffect, useState } from 'react'
import './Catalog.scss'
import { Container, Path, Pagination } from 'src/components'

import { CatalogFilter } from './CatalogFilter'
import { CatalogSort } from './CatalogSort'
import { CatalogGrid } from './CatalogGrid'
import { CategoryModal, FilterModal } from './modals'

import qs from 'qs'
import { AppContext } from 'src'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { CatalogFilterPlaceholder } from './CatalogFilter/CatalogFilterPlaceholder'
import { OrderModal } from './CatalogSort/Modals'
import { CatalogSortPlaceholder } from './CatalogSort/CatalogSortPlaceholer'

export const Catalog = observer(() => {
    const { productStore } = useContext(AppContext)
    const [catalogModal, setCatalogModal] = useState(false)

    const catalogPath = [
        { name: 'Главная', path: '/' },
    ]
    
    if (productStore.catalogBreadCrumbs.length > 0) {
        const path = productStore.catalogBreadCrumbs.map(bc => ({ name: bc.value, path: `/products?${bc.name}=${bc.value}` }))
        catalogPath.push(...path)
    } else {
        catalogPath.push({ name: 'Каталог', path: '/products' })
    }

    useEffect(() => {
        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })

        if (productStore.shared_catalog) {
            const formatParam = (param = []) => {
                if (typeof param === 'string') return [param]
                return param
            }

            // console.log(productStore.filtered_products.pages)
            const calcPage = +queryParams.page > productStore.filtered_products.pages ? 1 : +queryParams.page

            const filters = {
                colors: [],
                sizes: [],

                minPrice: Number(queryParams.minPrice) ? formatParam(Number(queryParams.minPrice)) : null,
                maxPrice: Number(queryParams.maxPrice) ? formatParam(Number(queryParams.maxPrice)) : null,
                
                genders: formatParam(queryParams.gender),
                recommendations: formatParam(queryParams.recommendation),
                collections: formatParam(queryParams.collection),
                clothes: formatParam(queryParams.cloth),
                accessories: formatParam(queryParams.accessory),

                sortBy: queryParams.sortBy,
                sortOrder: queryParams.sortOrder,

                limit: 9,
                page: calcPage,
            }
            // console.log(filters)
            productStore.setFilters(filters)
        }
    }, [productStore.shared_catalog]) // eslint-disable-line

    // useEffect(() => { console.warn('catalog rerender') })

    useEffect(() => {
        if (productStore.shared_catalog) {
            const searchParams = new URLSearchParams(window.location.search)
            const queryParams = qs.parse(searchParams.toString())
            
            queryParams.minPrice = productStore.filter.filters.minPrice || []
            queryParams.maxPrice = productStore.filter.filters.maxPrice || []
            queryParams.gender = toJS(productStore.filter.filters.genders)
            queryParams.recommendation = toJS(productStore.filter.filters.recommendations)
            queryParams.collection = toJS(productStore.filter.filters.collections)
            queryParams.cloth = toJS(productStore.filter.filters.clothes)
            queryParams.accessory = toJS(productStore.filter.filters.accessories)
            queryParams.sortBy = productStore.filter.filters.sortBy || []
            queryParams.sortOrder = productStore.filter.filters.sortOrder || []
            queryParams.page = productStore.filter.filters.page || 1
            
            const updatedQueryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' })
            window.history.replaceState({}, '', `?${updatedQueryString}`)
        }
    }, [
            productStore.filter.filters.minPrice,
            productStore.filter.filters.maxPrice,
            JSON.stringify(productStore.filter.filters.genders),
            JSON.stringify(productStore.filter.filters.recommendations),
            JSON.stringify(productStore.filter.filters.collections),
            JSON.stringify(productStore.filter.filters.clothes),
            JSON.stringify(productStore.filter.filters.accessories),
            productStore.filter.filters.sortBy,
            productStore.filter.filters.sortOrder,
            productStore.filter.filters.page,
            productStore.shared_catalog,
        ])

    const filterModal = catalogModal === 'filter'
    const categoryModal = catalogModal === 'category'

    const openCatalogModal = (modal) => {
        return () => setCatalogModal(modal)
    }

    const isPagination = productStore.filtered_products.pages > 1
    const handlePageChange = (page) => productStore.handlePageChange(page)

    const isProducts = productStore.filtered_products?.data?.length > 0
    const isProductsLoading = !isProducts && productStore.loading

    const { filters } = productStore.filter
    const isSort = filters.sortBy && filters.sortOrder
    const sortText = isSort && filters.sortBy === 'price'
        ? filters.sortOrder === 'asc'
            ? 'Сначала дешевле'
            : 'Сначала дороже'
        : 'Сортировать по'

    const handleSortSelect = (value) => {
        const notPriceSort = filters.sortBy !== 'price'
        const priceAsc = filters.sortOrder === 'asc' && filters.sortBy === 'price'
        const priceDesc = filters.sortOrder === 'desc' && filters.sortBy === 'price'

        if (notPriceSort || priceAsc) {
            productStore.setFilters({ ...filters, sortBy: 'price', sortOrder: 'desc' })
        } else if (priceDesc) {
            productStore.setFilters({ ...filters, sortBy: 'price', sortOrder: 'asc' })
        }
    }

    return (
        <Container>
            {/* <Path path={catalogPath} /> */}
            {/* {JSON.stringify(toJS(productStore.filter.filters))} */}

            <div className='catalog'>

                {/* only tablet and mobile */}
                {filterModal &&
                    <FilterModal onClose={openCatalogModal(false)} />}

                {categoryModal &&
                    <CategoryModal onClose={openCatalogModal(false)} />}

                {/* only desktop */}
                <div className='catalog__column catalog__only-desktop'>
                    <h2 className='title'>Каталог</h2>
                    
                    {isProductsLoading ? 
                        <CatalogFilterPlaceholder /> :
                        <CatalogFilter />}
                </div>


                <div className='catalog__column'>
                    <div className='catalog__mobile-bar'>
                        <h2 className='title'>Каталог</h2>

                        <div className='catalog__btn-group'>
                            {isProductsLoading
                                ? <button className='catalog__filter-btn placeholder' data-attr='placeholder'></button>
                                : <button onClick={openCatalogModal('category')} className='catalog__filter-btn'>
                                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <rect x='4' y='4' width='7' height='7' rx='3' fill='#676785' />
                                        <rect x='13' y='4' width='7' height='7' rx='3' fill='#676785' />
                                        <rect x='13' y='13' width='7' height='7' rx='3' fill='#676785' />
                                        <rect x='4' y='13' width='7' height='7' rx='3' fill='#676785' />
                                    </svg>
                                    <p>Выбрать категорию</p>
                                </button>}

                            <div style={{ position: 'relative' }}>
                                {isProductsLoading
                                    ? <button className='catalog__sort-btn placeholder' data-attr='placeholder'></button>
                                    : <button onClick={handleSortSelect} className='catalog__sort-btn'>
                                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path d='M12 7L20 7' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                            <path d='M4 7L8 7' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                            <path d='M17 17L20 17' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                            <path d='M4 17L12 17' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                            <circle cx='10' cy='7' r='2' transform='rotate(90 10 7)' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                            <circle cx='15' cy='17' r='2' transform='rotate(90 15 17)' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                        </svg>
                                        <p>{sortText}</p>
                                    </button>}
                                {/* <OrderModal
                                    sortBy={productStore.filter.filters.sortBy}
                                    sort={productStore.filter.filters.sort}
                                    handleSortClick={() => {}}
                                /> */}
                            </div>
                        </div>
                    </div>

                    {/* only desktop */}
                    <div className='catalog__only-desktop'>
                        {isProductsLoading
                            ? <CatalogSortPlaceholder />
                            : <CatalogSort />}
                    </div>

                    <CatalogGrid />

                    {isPagination &&
                        <div className='catalog__pagination'>
                            <Pagination page={productStore.filter.filters.page} pages={productStore.filtered_products.pages} setPage={handlePageChange} />
                        </div>}
                </div>

            </div>
        </Container>
    )
})