import { Checkbox } from 'src/components'
import './CatalogFilter.scss'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

export const CatalogFilter = observer(() => {
    const { productStore } = useContext(AppContext)
    const [filters, setFilters] = useState(productStore.filter.filters)

    const isChecked = (filterType, filterValue) => filters[filterType]?.find(param => param === filterValue)
    const handleFilterClick = (filterType, filterValue) => () => {
        if (isChecked(filterType, filterValue)) {
            setFilters(prevFilters => ({ ...prevFilters, [filterType]: prevFilters[filterType].filter(filterItem => filterItem !== filterValue) }))
        } else {
            setFilters(prevFilters => ({ ...prevFilters, [filterType]: [...prevFilters[filterType], filterValue] }))
        }
    }
    // const handleFilterClick = (filterType, filterValue) => () => productStore.handleFilterChange(filterType, filterValue)

    useEffect(() => {
        setFilters(productStore.filter.filters)
    }, [JSON.stringify(productStore.filter)])

    const handleFilterSubmit = () => {
        productStore.setFilters(filters)
    }
    
    const handleFilterDelete = () => {
        productStore.deleteFilters(['genders', 'recommendations', 'collections', 'clothes', 'accessories'])
        setFilters(productStore.filter.filters)
    }

    return (
        <div className='catalog-filter'>
            {/* <div className='catalog-filter__group'>
                <div className='catalog-filter__group-container'>
                    {productStore.genders.map((filterItem, index) => {
                        return (
                            <Checkbox
                                key={index}
                                checked={isChecked('genders', filterItem)}
                                onClick={handleFilterClick('genders', filterItem)}
                            >
                                {filterItem.split('').map((char, index) => {
                                    if (index !== 0) return char.toLowerCase() 
                                    return char
                                })}
                            </Checkbox>)})}
                </div>
            </div> */}

            <div className='catalog-filter__group'>
                <div className='catalog-filter__group-container'>
                    {productStore.shared_catalog?.recommendations?.map((filterItem, index) => {
                        return (
                            <Checkbox
                                key={index}
                                checked={isChecked('recommendations', filterItem)}
                                onClick={handleFilterClick('recommendations', filterItem)}
                            >
                                {filterItem}
                            </Checkbox>)})}
                </div>
            </div>

            {/* <div className='catalog-filter__group'>
                <div className='catalog-filter__group-title'>Коллекции:</div>

                <div className='catalog-filter__group-container'>
                    {productStore.shared_catalog?.collections?.map((filterItem, index) => {
                        return (
                            <Checkbox
                                key={index}
                                checked={isChecked('collections', filterItem)}
                                onClick={handleFilterClick('collections', filterItem)}
                            >
                                {filterItem}
                            </Checkbox>)})}
                </div>
            </div> */}
            
            <div className='catalog-filter__group'>
                <div className='catalog-filter__group-title'>Одежда:</div>

                <div className='catalog-filter__group-container'>
                    {productStore.shared_catalog?.clothes?.map((filterItem, index) => {
                        return (
                            <Checkbox
                                key={index}
                                checked={isChecked('clothes', filterItem)}
                                onClick={handleFilterClick('clothes', filterItem)}
                            >
                                {filterItem}
                            </Checkbox>)})}
                </div>
            </div>

            <div className='catalog-filter__group'>
                <div className='catalog-filter__group-title'>Аксессуары:</div>

                <div className='catalog-filter__group-container'>
                    {productStore.shared_catalog?.accessories?.map((filterItem, index) => {
                        return (
                            <Checkbox
                                key={index}
                                checked={isChecked('accessories', filterItem)}
                                onClick={handleFilterClick('accessories', filterItem)}
                            >
                                {filterItem}
                            </Checkbox>)})}
                </div>
            </div>

            <div className='flex flex--column flex--g-10 mt--10' style={{ maxWidth: 222 }}>
                <button onClick={handleFilterSubmit} className='btn'>Применить</button>
                <button onClick={handleFilterDelete} className='btn btn--light'>Сбросить</button>
            </div>
        </div>
    )
})