export const visiblePages = (pages, currentPage, small = true) => {
    // creating array of page numbers
    const pageList = new Array(pages).fill('').map((_, idx) => idx + 1)

    if (pages <= (small ? 4 : 5)) {
        return pageList
    }

    if (currentPage <= 1 + 1) {
        return [...pageList.slice(0, (small ? 2 : 3)), null, pages]
    }

    if (currentPage >= pages - (small ? 1 : 2)) {
        return [1, null, ...pageList.slice(pages - (small ? 2 : 3))]
    }

    return [1, ...pageList.slice(currentPage - 1, currentPage + (small ? 0 : 1)), null, pages]
}