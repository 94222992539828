import { useNavigate } from 'react-router-dom'
import './ProductCardExtraSmall.scss'
import { yandexEcommerce, ym } from 'src/analytics'
import { toJS } from 'mobx'

export const ProductCardExtraSmall = ({ product, isSearch, index }) => {
    const navigate = useNavigate()
    const handleProductClick = () => {
        ym.ProductClick()
        // console.log(toJS(product))

        const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
            ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
            : product.collection + '/Одежда/' + product.name.split(' «')[0]

        const mappedForYandexEcommerce = {
            id: product.id,
            name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
            price: parseFloat(product.newPrice),
            brand: 'DDX Fitness',
            category,
            variant: product.name.split(' ').slice(-1).join(''),
            list: '',
            position: index + 1,
        }

        yandexEcommerce.clickProduct(mappedForYandexEcommerce)

        navigate('/products/' + product.id)
    }

    // const isSearch = !(product.quantity && product.size && product.color) && product.collection

    return (
        <div className='product-card-extra-small flex flex--g-10'>
            <img className='product-card-extra-small__image' src={product.images[0]} alt='card' />

            <div className='flex flex--column flex--jc-sb'>
                {product.article &&
                    <p className='text text--12'>арт. {product.article}</p>}

                {/* {isSearch && */}
                    <p className='text text--12'>{product.collection}</p>

                <div className='flex flex--g-14'>
                    <h3 onClick={handleProductClick} className='text text--dark text--uppercase'>{product.name}</h3>

                    {!isSearch &&
                        <div className='flex flex--g-10'>
                            {product.newPrice && <p className='text text--dark text--700'>{product.newPrice} ₽</p>}
                            {product.oldPrice && <p className='text text--line-th'>{product.oldPrice} ₽</p>}
                        </div>}
                </div>
                
                <div className='flex flex--g-14 text'>
                    {!isSearch && product.quantity &&
                        <p>Кол-во: {product.quantity}</p>}
                    {!isSearch && product.size &&
                        <p>Размер: {product.size}</p>}
                    {!isSearch && product.color &&
                        <p>Цвет: {product.color}</p>}

                    {isSearch &&
                        <div className='flex flex--g-10'>
                            {product.newPrice && <p className='text text--dark text--700'>{product.newPrice} ₽</p>}
                            {product.oldPrice && <p className='text text--line-th'>{product.oldPrice} ₽</p>}
                        </div>}
                </div>

            </div>
        </div>
    )
}