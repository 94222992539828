import { Checkbox, Logo } from 'src/components'
import './styles.scss'
import { useContext, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

export const CategoryModal = observer(({ onClose }) => {
    const { productStore } = useContext(AppContext)
    const [filters, setFilters] = useState(productStore.filter.filters)


    const isChecked = (filterType, filterValue) => filters[filterType]?.find(param => param === filterValue)
    const handleFilterClick = (filterType, filterValue) => () => {
        if (isChecked(filterType, filterValue)) {
            setFilters(prevFilters => ({ ...prevFilters, [filterType]: prevFilters[filterType].filter(filterItem => filterItem !== filterValue) }))
        } else {
            setFilters(prevFilters => ({ ...prevFilters, [filterType]: [...prevFilters[filterType], filterValue] }))
        }
    }

    const handleFilterSubmit = () => {
        productStore.setFilters(filters)
        onClose()
    }
    
    const handleFilterDelete = () => {
        productStore.deleteFilters(['genders', 'recommendations', 'collections', 'clothes', 'accessories'])
        setFilters(productStore.filter.filters)
        onClose()
    }

    return (
        <div className='catalog-modal container'>
            <div className='catalog-modal__header'>
                <Logo />

                <button onClick={onClose}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' />
                        <rect x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' />
                    </svg>
                </button>
            </div>

            <div className='catalog-modal__body'>

                <h3 className='catalog-modal__title'>Выбор категории</h3>

                <div className='catalog-modal__container catalog-modal__container--category'>

                    <div className='flex flex--column flex--g-20-20-10'>

                        {/* <div className='flex flex--column flex--g-10'>
                            {productStore.genders.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={isChecked('genders', filterItem)}
                                        onClick={handleFilterClick('genders', filterItem)}
                                    >
                                        {filterItem}
                                    </Checkbox>)
                            })}
                        </div> */}

                        <div className='flex flex--column flex--g-10'>
                            {productStore.shared_catalog?.recommendations.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={isChecked('recommendations', filterItem)}
                                        onClick={handleFilterClick('recommendations', filterItem)}
                                    >
                                        {filterItem}
                                    </Checkbox>)
                            })}
                        </div>

                    </div>

                    {/* <div className='flex flex--column flex--g-20-20-10'>

                        <p className='text text--inter text--500'>Коллекции:</p>

                        <div className='flex flex--column flex--g-10'>
                            {productStore.shared_catalog?.collections.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={isChecked('collections', filterItem)}
                                        onClick={handleFilterClick('collections', filterItem)}
                                    >
                                        {filterItem}
                                    </Checkbox>)
                            })}
                        </div>

                    </div> */}

                    <div className='flex flex--column flex--g-20-20-10'>

                        <p className='text text--inter text--500'>Одежда:</p>

                        <div className='flex flex--column flex--g-10'>
                            {productStore.shared_catalog?.clothes.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={isChecked('clothes', filterItem)}
                                        onClick={handleFilterClick('clothes', filterItem)}
                                    >
                                        {filterItem}
                                    </Checkbox>)
                            })}
                        </div>

                    </div>

                    <div className='flex flex--column flex--g-20-20-10'>

                        <p className='text text--inter text--500'>Аксессуары:</p>

                        <div className='flex flex--column flex--g-10'>
                            {productStore.shared_catalog?.accessories.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={isChecked('accessories', filterItem)}
                                        onClick={handleFilterClick('accessories', filterItem)}
                                    >
                                        {filterItem}
                                    </Checkbox>)
                            })}
                        </div>

                    </div>

                </div>

            </div>

            <div className='catalog-modal__footer'>

                <div className='catalog-modal__footer-btns catalog-modal__footer-btns--200'>
                    <button onClick={handleFilterSubmit} className='btn btn--sm'>ПРИМЕНИТЬ</button>
                    <button onClick={handleFilterDelete} className='btn btn--sm btn--light'>СБРОСИТЬ</button>
                </div>

            </div>
        </div>
    )
})