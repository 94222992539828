import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import './styles/index.scss'
import { createContext } from 'react'
import { AuthStore, BaskettStore, OrderStore, ProductStore } from './store'
import { ApolloProvider } from '@apollo/client'
import client from './api/apollo-client'
// import { client } from './http/config'

export const AppContext = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <AppContext.Provider value={{
                productStore: new ProductStore(),
                basketStore: new BaskettStore(),
                authStore: new AuthStore(),
                orderStore: new OrderStore(),
            }}>
                <App />
            </AppContext.Provider>
        </ApolloProvider>
    </BrowserRouter>
)