import { Container, Path } from 'src/components'
import './Order.scss'
import { OrderBasket, OrderForm } from './components'
import { Link, NavLink } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { Overlay } from 'src/layout'
import { OrderChangedStockModal } from './modals'

// const orderPath = [{ name: 'Главная', path: '/' }, { name: 'Корзина', path: '/basket' }]

export const Order = observer(() => {
    const { basketStore } = useContext(AppContext)

    const isBasketEmpty = basketStore.basket?.length === 0

    const [isOrderChangedStockModal, setOrderChangedStockModal] = useState(false)

    const closeOrderChangedStockModal = () => setOrderChangedStockModal(false)
    const openOrderChangedStockModal = (text) => setOrderChangedStockModal(text)

    return (
        <Container>
            {isOrderChangedStockModal && 
                <Overlay center onClick={closeOrderChangedStockModal}>
                    <OrderChangedStockModal text={isOrderChangedStockModal} onClose={closeOrderChangedStockModal} />
                </Overlay>}

            <div className='order'>
                {/* <Path path={orderPath} /> */}

                <button className='mt--30-20'>
                    <NavLink to='/basket' className='flex flex--ai-c flex--g-10'>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M13.5 9L10.5 12L13.5 15' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                            <rect x='23' y='1' width='22' height='22' rx='11' transform='rotate(90 23 1)' stroke='#676785' strokeOpacity='0.5' strokeWidth='2' />
                        </svg>

                        <p className='text text--16-14 text--500'>Вернуться назад</p>
                    </NavLink>
                </button>

                {isBasketEmpty
                    ?
                        <>
                            <p className='text text--20-16 mt--60-30'>Ваша корзина пока пуста</p>
                            <Link to='/products'>
                                <button className='btn mt--60-30'>Перейти в каталог</button>
                            </Link>
                        </>
                    :

                        <div className='order__grid mt--30-20'>
                            <div className='order__info'>
                                <h2 className='order__title title'>Оформление заказа</h2>
                                <OrderForm openOrderChangedStockModal={openOrderChangedStockModal} />
                            </div>

                            <OrderBasket />
                        </div>}
            </div>
        </Container>
    )
})