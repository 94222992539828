import './CatalogFilter.scss'

export const CatalogFilterPlaceholder = () => {
    return (
        <div className='catalog-filter'>
            <div className='catalog-filter__group'>
                <div className='catalog-filter__group-container'>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                </div>
            </div>

            <div className='catalog-filter__group'>
                <p className='catalog-filter__placeholder-title placeholder'></p>

                <div className='catalog-filter__group-container'>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                </div>
            </div>
            <div className='catalog-filter__group'>
                <p className='catalog-filter__placeholder-title placeholder'></p>

                <div className='catalog-filter__group-container'>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                    <p className='catalog-filter__placeholder-item placeholder'></p>
                </div>
            </div>

            <div className='flex flex--column flex--g-10 mt--10' style={{ maxWidth: 222 }}>
                <button className='btn placeholder' data-attr='placeholder'></button>
                <button className='btn placeholder' data-attr='placeholder'></button>
            </div>
        </div>
    )
}