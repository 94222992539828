import './Quantity.scss'

export const Quantity = ({ value, onIncreaseQuantity, onDecreaseQuantity, className }) => {
    return (
        <div className={'product-quantity ' + className}>
            <button onClick={onIncreaseQuantity}>
                <svg xmlns='http://www.w3.org/2000/svg' width='10' height='24' viewBox='0 0 10 24' fill='none'>
                    <rect x='6' y='7' width='10' height='2' rx='1' transform='rotate(90 6 7)' />
                    <rect y='11' width='10' height='2' rx='1' />
                </svg>
            </button>

            <p>{value}</p>

            <button onClick={onDecreaseQuantity}>
                <svg xmlns='http://www.w3.org/2000/svg' width='10' height='24' viewBox='0 0 10 24' fill='none'>
                    <rect y='11' width='10' height='2' rx='1' />
                </svg>
            </button>
        </div>
    )
}