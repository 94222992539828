import './Overlay.scss'

export const Overlay = ({ center, children, ...props }) => {
    const overlayClassNamess = 'overlay'
        + (center ? ' overlay--center' : '')
    return (
        <div {...props} className={overlayClassNamess}>
                {/* <div className='overlay__scroll-y'>
                    {children}
                </div> */}
                
                {children}
        </div>
    )
}