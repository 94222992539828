import { ProductCardSlide } from 'src/components'
import './CatalogGrid.scss'
import { useContext, useEffect } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { ProductCardSlidePlaceholder } from 'src/components/ProductCard/ProductCardSlide/ProductCardSlidePlaceholder'
import { toJS } from 'mobx'
import { yandexEcommerce } from 'src/analytics'

export const CatalogGrid = observer(() => {
    const { productStore } = useContext(AppContext)

    const isProducts = productStore.filtered_products?.data?.length !== 0
    const isProductsLoading = !isProducts && productStore.loading

    const isFilteredProducts = isProducts && !isProductsLoading

    useEffect(() => {
        if (isProducts) {
            const productsForYandexEcommerce = productStore.filtered_products.data.map((product, index) => {
                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(product.price),
                    brand: 'DDX Fitness',
                    category,
                    variant: product.name.split(' ').slice(-1).join(''),
                    list: '',
                    position: index + 1,
                }
                // console.log(mappedForYandexEcommerce)
                return mappedForYandexEcommerce
            })

            yandexEcommerce.openCatalog(productsForYandexEcommerce)
        }
    }, [
        JSON.stringify(productStore.filtered_products.data)
    ])

    return (
        <div className='catalog-grid'>
            {isProductsLoading
                ? Array(9).fill('').map((_, index) => {
                    return (
                        <ProductCardSlidePlaceholder
                            key={index}
                        />)
                    })
            
                // if loaded
                : isFilteredProducts
                    ? productStore.filtered_products.data.map((product, index) => {
                            return (
                                <ProductCardSlide
                                    product={product}
                                    index={index}
                                    key={index}
                                />)
                            })
                    : <p className='text text--16-14'>Ничего не найдено</p>}
                        
        </div>
    )
})