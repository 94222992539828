import { useContext, useEffect, useRef, useState } from 'react'
import { Container, Path, ProductCardSlide, SwiperSlider } from 'src/components'
import { ProductCard } from './components/ProductCard/ProductCard'
import { AppContext } from 'src'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Overlay } from 'src/layout'
import { SizeTable } from './components'
import { AddedToBasket } from './modals'
import { ProductCardPlaceholder } from './components/ProductCard/ProductCardPlaceholder'
import { ProductCardSlidePlaceholder } from 'src/components/ProductCard/ProductCardSlide/ProductCardSlidePlaceholder'
import { PathPlaceholder } from 'src/components/Path/PathPlaceholder'
import { toJS } from 'mobx'
import { yandexEcommerce } from 'src/analytics'
import { useIsInViewport } from 'src/hooks'

export const Product = observer(() => {
    const { productStore } = useContext(AppContext)
    const content = Array.from(new Set([...productStore.recently_watched, ...productStore.recommended]))
    const slides = content.map((product, index) => {
        return <ProductCardSlide product={product} key={index} index={index} />
    })
    const slidesPlaceholder = [
        <ProductCardSlidePlaceholder key='placeholder_slide_01' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_02' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_03' />,
        <ProductCardSlidePlaceholder key='placeholder_slide_04' />,
    ]
    
    const { id } = useParams()    
    // const handleAddProductToBasket = (product) => basketStore.addProduct(product)
    
    const [sizeTable, setSizeTable] = useState(false)
    const openSizeTable = (type) => setSizeTable(type)
    const closeSizeTable = () => setSizeTable(false)

    const [addedToBasket, setAddedToBasket] = useState(false)
    const openAddedToBasket = (variationId) => setAddedToBasket(variationId)
    // const closeAddedToBasket = () => setAddedToBasket(false)
    
    useEffect(() => {
        productStore.getProduct(id)
            .then(() => {
                const product = productStore.product
                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(product.price),
                    brand: 'DDX Fitness',
                    category,
                    variant: product.name.split(' ').slice(-1).join(''),
                    list: '',
                    position: 1,
                }

                yandexEcommerce.openProduct(mappedForYandexEcommerce)
                isMetricsSent.current = false
            })

    }, [id]) // eslint-disable-line


    // следим когда слайдер с рекомендациями попадает в зону видимости
    const recentlyViewedProductsSwiperElement = useRef(null)
    const isIntersected = useIsInViewport(recentlyViewedProductsSwiperElement)
    const isMetricsSent = useRef(null)

    useEffect(() => {
        if (isIntersected && slides?.length !== 0 && !isMetricsSent.current) {
            const productsForYandexEcommerce = content.map((product, index) => {
                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(product.price),
                    brand: 'DDX Fitness',
                    category,
                    variant: product.name.split(' ').slice(-1).join(''),
                    list: 'недавно смотрели',
                    position: index + 1,
                }
                // console.log(mappedForYandexEcommerce)
                return mappedForYandexEcommerce
            })

            yandexEcommerce.openCatalog(productsForYandexEcommerce)
            isMetricsSent.current = true
        }
    }, [isIntersected, slides])
    
    // const productPath = [
    //     { name: 'Главная', path: '/' },
    //     { name: 'Женское', path: '/products' },
    //     { name: 'Streetwear', path: '/products' },
    //     { name: productStore.product?.name, path: '/products/' + id }
    // ]

    const productPath = [
        { name: 'Главная', path: '/' },
    ]
    
    if (productStore.productBreadCrumbs.length > 0) {
        const path = productStore.productBreadCrumbs.map(bc => ({ name: bc.value, path: `/products?${bc.name}=${bc.value}` }))
        productPath.push(...path)
    }

    productPath.push({ name: productStore.product?.name, path: `/products/${id}`})

    const isProductLoading = !productStore.product || productStore.loading

    return (
        <Container>
            {/* {addedToBasket && 
                <Overlay center onClick={closeAddedToBasket}>
                    <AddedToBasket variationId={addedToBasket} onClose={closeAddedToBasket} />
                </Overlay>} */}

            {sizeTable &&
                <Overlay onClick={closeSizeTable}>
                    <SizeTable type={sizeTable} onClose={closeSizeTable} />
                </Overlay>}

            {/* {isProductLoading ? 
                <PathPlaceholder /> :
                <Path path={productPath} />} */}

            {isProductLoading ?
                <ProductCardPlaceholder /> :
                <ProductCard
                    openAddedToBasket={openAddedToBasket}
                    product={productStore.product}
                    // addProductToBasket={handleAddProductToBasket}
                    openSizeTable={openSizeTable}
                />}

            {/* <ProductCardPlaceholder /> */}
            
            <div ref={recentlyViewedProductsSwiperElement}>
                <SwiperSlider
                    title='ВЫ НЕДАВНО СМОТРЕЛИ'
                    slides={slides.length ? slides : slidesPlaceholder}
                    offset title_sm
                />
            </div>
        </Container>
    )
})