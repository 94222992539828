import axios from "axios"

export const createOrder = async (data) => {
    try {
        const headers = {
            'Woocommerce-Session': 'Session ' + localStorage.getItem('woo-session')
        }
        const response = await axios.post('https://api.ddxmerch.ru/wp-json/wc/custom/orders', data, { headers })
        return response.data
    } catch (err) {
        // console.log(err)
        return err?.response?.data?.message
    }
}