import { NavLink } from 'react-router-dom'
import './Hero.scss'

import heroImage from './img/hero.png'

export const Hero = () => {
    return (
        <div className='hero'>
            <div className='hero__container'>

                <NavLink to='/products?collection=STREETWEAR'>
                    <button className='hero__collection-btn'>
                        Коллекция
                    </button>
                </NavLink>

                <h1 className='title title--lg title--light'>
                    streetwear
                </h1>

                <NavLink to='/products'>
                    <button className='hero__catalog-btn'>
                        КАТАЛОГ
                    </button>
                </NavLink>
            </div>

            <img className='hero__img' src={heroImage} alt='hero' />
                
        </div>
    )
}