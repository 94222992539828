import { useContext } from 'react'
import './style.scss'
import { AppContext } from 'src'

export const OrderChangedStockModal = ({ text, onClose }) => {
    const { basketStore } = useContext(AppContext)

    const stopPropagation = (event) => event.stopPropagation()

    const handleUpdateBasketBtn = () => {
        basketStore.getBasket()
        onClose()
    }

    return (
        <div className='order-error-modal' onClick={stopPropagation}>
            <div className='order-error-modal__container'>
                <div className='order-error-modal__header flex flex--ai-c'>
                    <button onClick={onClose}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <rect x='7.41406' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41406 6)' fill='#676785' />
                            <rect x='6' y='17.3138' width='16' height='2' rx='1' transform='rotate(-45 6 17.3138)' fill='#676785' />
                        </svg>
                    </button>
                </div>

                <div className='order-error-modal__body flex flex--column flex--g-30'>
                    <h3 className='order-error-modal__title'>Упс!</h3>
                    <p className='text'>{text}</p>

                    <button onClick={handleUpdateBasketBtn} className='btn'>Обновить корзину</button>
                    <div className='mt--10'></div>
                </div>

                {/* <div className='order-error-modal__footer flex flex--jc-sb flex--g-10 mt--40'>
                    <button className='btn'>ПЕРЕЙТИ В КОРЗИНУ</button>
                </div> */}
            </div>
        </div>
    )
}