import React, { useEffect, useRef, useState } from 'react'
import { SelectArrow } from './SelectArrow'
import { SelectList } from './SelectList'

export const SelectBase = ({
    formik,
    placeholder,
    search,
    searchString,
    setSearchString,
    notFoundText,
    options = [],
    value,
    onChange,
    onBlur,
    error,
    classPostfix,
    ...props 
}) => {
    const [isOpened, setIsOpened] = useState(false)
    const rootEl = useRef(null)
    const inputEl = useRef(null)

    // const [searchStringEnternal, setSearchStringEnternal] = useState('')

    const filteredOptions = search ? 
        options.filter(option =>
            option.name.toLowerCase().includes(searchString.toLowerCase().trim()) 
        ) : options

    const baseClassName = classPostfix
        ? ' select-' + classPostfix
        : ' select'
        
    const selectClassNames = baseClassName
        + (isOpened ? baseClassName + '--active' : '')
        + (search ? baseClassName + '--search' : '')
        + (isOpened ? baseClassName + '--opened' : '')
        + (error ? baseClassName + '--error' : '')

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setIsOpened(false)
        if (isOpened) document.addEventListener('click', onClick)
        return () => { isOpened && document.removeEventListener('click', onClick) }
    }, [isOpened]);

    const handleSelect = (item) => {
        onChange(item)

        if (search) {
            setSearchString('')
        }
        // onChange({ target: { value: item, name: props.name, id: props.id } })
        toggleSelect()
    }

    const handleSelectFormik = (item) => {
        onChange({ target: { value: item, name: props.name, id: props.id } })

        if (search) {
            setSearchString('')
        }

        toggleSelect()
    }

    const toggleSelect = () => {
        if (isOpened) inputEl.current.blur()
        setIsOpened(prev => !prev)
    }

    const showSearchText = search && isOpened
    const showLargeValue = !classPostfix && value && !showSearchText

    return (
        <div className={selectClassNames} ref={rootEl}>
            {showLargeValue &&
                <div className={baseClassName + '__value'}>
                    <p>{value.name}</p>
                    <p>{value.address}</p>
                </div>}

            <input
                type='text'
                ref={inputEl}
                // value={value ? value.name : ''}
                value={!search ? (value ? value.name : '') : isOpened ? searchString : (value.name || '')}
                onChange={e => setSearchString(e.target.value)}
                onClick={toggleSelect}
                readOnly={!search}
                placeholder={placeholder}
                onBlur={onBlur}
            />

            <SelectArrow baseClassName={baseClassName} isOpened={isOpened} />

            {isOpened &&
                <div className={baseClassName + '__container'}>
                    <SelectList
                        options={filteredOptions}
                        value={value}
                        handleSelect={formik ? handleSelectFormik : handleSelect}
                        baseClassName={baseClassName}
                        notFoundText={notFoundText}
                    />
                </div>
            }
        </div>
    )
}