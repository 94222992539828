import { NavLink } from 'react-router-dom'
import { Logo } from 'src/components'
import { BasketIcon, ProfileIcon } from '../../common'

export const DesktopHeader = ({ active = '', handleCategoryClick, backetItemCount, handleProfileClick, handleAboutClick }) => {

    const handleLinkStyle = (itemName) => {
        return 'header__link' + (active === itemName ? ' header__link--active' : '')
    }

    return (
        <div className='header__container'>
            <div className='header__links'>
                <button
                    onClick={handleCategoryClick('ЖЕНСКОЕ')}
                    className={handleLinkStyle('ЖЕНСКОЕ')}
                >ЖЕНСКОЕ
                </button>

                <button
                    onClick={handleCategoryClick('МУЖСКОЕ')}
                    className={handleLinkStyle('МУЖСКОЕ')}
                >МУЖСКОЕ
                </button>

                <button
                    onClick={handleCategoryClick('КОЛЛЕКЦИИ')}
                    className={handleLinkStyle('КОЛЛЕКЦИИ')}
                >КОЛЛЕКЦИИ
                </button>

                <button
                    onClick={handleCategoryClick('search')}
                    className={handleLinkStyle('ПОИСК')}
                >ПОИСК
                </button>
            </div>

            <NavLink to='/'>
                <Logo />
            </NavLink>

            <div className='header__links'>

            <a href='/#about' onClick={handleAboutClick}>
                <button
                    className='header__link'
                >О БРЕНДЕ
                </button>
            </a>

                <NavLink to='/for-customers'>
                    <button
                        onClick={() => {}}
                        className='header__link'
                    >ПОКУПАТЕЛЯМ
                    </button>
                </NavLink>

                <button>
                    <BasketIcon itemCount={backetItemCount} />
                </button>

                {/* <button onClick={handleProfileClick}>
                    <ProfileIcon />
                </button> */}

            </div>
        </div>
    )
}