import './ProductCard.scss'
import { PricePlaceholder } from 'src/components/ProductCard/common'

export const ProductCardPlaceholder = () => {
    return (
        <div className='product-card'>

            {/* only mobile */}
            <div className='product-card__images product-card__images--mobile'>
                <div className='product-card__image placeholder'></div>
            </div>

            {/* desktop and tablet */}
            <div className='product-card__images'>
                <div className='product-card__image placeholder'></div>
                <div className='product-card__image placeholder'></div>
                <div className='product-card__image placeholder'></div>
                <div className='product-card__image placeholder'></div>
            </div>

            <div className='product-card__descriptions'>

                <div className='product-card__header'>
                    <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                    <div className='flex flex--column flex--g-5'>
                        <h2 className='placeholder' data-attr='placeholder'></h2>
                        <h2 className='placeholder placeholder--w-75' data-attr='placeholder'></h2>
                    </div>
                </div>

                <div className='product-card__flex-40'>
                    <PricePlaceholder />
                </div>

                <div className='product-card__size'>
                    <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                    <button className='btn-size placeholder placeholder--w-75' data-attr='placeholder'></button>
                </div>
                
                <div className='product-card__btn-and-alert'>
                    <button className='btn placeholder placeholder--w-50' data-attr='placeholder'></button>
                </div>

                <div className='product-card__about'>
                    
                    <div className='product-card__about-container'>
                        <p className='placeholder placeholder--w-75' data-attr='placeholder'></p>
                        <p className='placeholder' data-attr='placeholder'></p>
                        <p className='placeholder' data-attr='placeholder'></p>
                        <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                        <p className='placeholder placeholder--w-25' data-attr='placeholder'></p>
                    </div>

                    <div className='product-card__about-container'>
                        <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                        <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                        <p className='placeholder placeholder--w-50' data-attr='placeholder'></p>
                    </div>

                </div>
            </div>
        </div>
    )
}