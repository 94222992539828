import './Container.scss'

export const Container = ({ small, children, ...props }) => {
    const containerClassNames = 'container'
        + (small ? ' container--sm' : '')
        
    return (
        <div className={containerClassNames} {...props}>
            {children}
        </div>
    )
}