import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
query MyQuery {
    products {
      nodes {
        name
        databaseId
        ... on SimpleProduct {
          id
          name
          price
          salePrice
        }
        ... on VariableProduct {
          id
          name
          price(format: RAW)
          regularPrice(format: RAW)
          onSale
          productCategories {
            nodes {
              databaseId
              name
            }
          }
        }
        galleryImages {
          nodes {
            altText
            id
            sourceUrl
            srcSet
          }
        }
        image {
          databaseId
          sourceUrl
          srcSet
          altText
        }
      }
    }
  }
`