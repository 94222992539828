import { useSwiper } from 'swiper/react'
import '../SwiperSlider.scss'

export const SwiperControls = ({ offset }) => {
    const swiper = useSwiper()
    const swiperControlsClassNames = 'swiper-slider__controls'
        + (offset ? ' swiper-slider__controls--offset' : '')

    return (
        <div className={swiperControlsClassNames}>
            <button onClick={() => swiper.slidePrev()}>
                <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8 1L2 7L8 13' stroke='#12123B' strokeWidth='2' strokeLinecap='round' />
                </svg>
            </button>

            <button onClick={() => swiper.slideNext()}>
                <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1 1L7 7L1 13' stroke='#12123B' strokeWidth='2' strokeLinecap='round' />
                </svg>
            </button>
        </div>
    )
}