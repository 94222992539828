import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from 'src'
import { TotalPriceBlock } from 'src/components'

export const BasketOrderAuthorized = observer(() => {
    const { basketStore } = useContext(AppContext)

    const navigate = useNavigate()
    const handleNavigateToOrder = () => navigate('/order')
    
    return (
        <>
            <TotalPriceBlock
                totalPrice={basketStore.newPrice}
                totalPriceWithoutDiscount={basketStore.basketPriceWithoutDiscount}
            />

            <button onClick={handleNavigateToOrder} className='btn'>Перейти к оформлению</button>
        </>
    )
})