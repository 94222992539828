import React, { useContext, useEffect } from 'react'
import { AppContext } from 'src'
import { Container, Path, ProductCardSmall, TotalPriceBlock } from 'src/components'
import { BasketOrder, PendingOrders } from './components'
import './Basket.scss'
import { observer } from 'mobx-react-lite'
import { Link, NavLink } from 'react-router-dom'
import { ProductCardSmallPlaceholder } from 'src/components/ProductCard/ProductCardSmall/ProductCardSmallPlaceholder'
// import { PathPlaceholder } from 'src/components/Path/PathPlaceholder'

import { yandexEcommerce, ym } from 'src/analytics'
import { TotalPriceBlockPlaceholder } from 'src/components/TotalPriceBlock/TotalPriceBlockPlaceholder'
import { toJS } from 'mobx'

// const basketPath = [
//     { name: 'Главная', path: '/' },
//     { name: 'Корзина', path: '/basket' }
// ]

export const Basket = observer(() => {
    const { basketStore, orderStore } = useContext(AppContext)
    const handleProductDelete = (basketItem, index) => () => {
        basketStore.deleteFromBasket(basketItem.variationId)
            .then(() => {
                ym.DelToCart()

                const category = basketItem?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? basketItem.collection + '/Аксессуары/' + basketItem.name.split(' «')[0]
                    : basketItem.collection + '/Одежда/' + basketItem.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: basketItem.id,
                    name: basketItem.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(basketItem.newPrice),
                    brand: 'DDX Fitness',
                    category,
                    quantity: basketItem.quantity,
                    list: '',
                    position: index + 1,
                }

                yandexEcommerce.deleteFromCart(mappedForYandexEcommerce)
            })
    }
    const handleQuantityChange = (basketItem, action, index) => {
        if (action === 'increase' && basketItem.quantity + 1 <= basketItem.stockQuantity) {
            return () => {
                basketStore.updateBasket(basketItem.variationId, basketItem.quantity + 1)
                    .then(() => {
                        ym.AddToCart()

                        const category = basketItem?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                            ? basketItem.collection + '/Аксессуары/' + basketItem.name.split(' «')[0]
                            : basketItem.collection + '/Одежда/' + basketItem.name.split(' «')[0]

                        const mappedForYandexEcommerce = {
                            id: basketItem.id,
                            name: basketItem.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                            price: parseFloat(basketItem.newPrice),
                            brand: 'DDX Fitness',
                            category,
                            quantity: 1,
                            list: '',
                            position: index + 1,
                        }

                        yandexEcommerce.addToCart(mappedForYandexEcommerce)
                    })
            }
        }

        if (action === 'decrease' && basketItem.quantity - 1 >= 0) {
            return () => {
                basketStore.updateBasket(basketItem.variationId, basketItem.quantity - 1)
                    .then(() => {
                        ym.DelToCart()

                        const category = basketItem?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                            ? basketItem.collection + '/Аксессуары/' + basketItem.name.split(' «')[0]
                            : basketItem.collection + '/Одежда/' + basketItem.name.split(' «')[0]

                        const mappedForYandexEcommerce = {
                            id: basketItem.id,
                            name: basketItem.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                            price: parseFloat(basketItem.newPrice),
                            brand: 'DDX Fitness',
                            category,
                            quantity: 1,
                            list: '',
                            position: index + 1,
                        }

                        yandexEcommerce.deleteFromCart(mappedForYandexEcommerce)
                    })
            }
        }

        return () => {}
    }
    const handleBasketDelete = () => basketStore.deleteBasket()

    const isBasketLoading = !basketStore.basket ? (!basketStore.basket || basketStore.loading) : false
    const isBasketEmpty = basketStore.basket?.length === 0
    
    const formattedBasket = basketStore.basket?.map((basketItem) => {
        return {
            id: basketItem.variation.node.parent.node.databaseId,
            variationId: basketItem.variation.node.databaseId,
            collection: 'Streetwear Collection',
            name: basketItem.variation.node.name.split('-').shift(),
            newPrice: parseFloat(basketItem.variation.node.price),
            oldPrice: parseFloat(basketItem.variation.node.regularPrice),
            size: basketItem.variation.node.name.split('-').pop(),
            images: [basketItem.variation.node.image.sourceUrl],
            quantity: basketItem.quantity,
            stockQuantity: basketItem.variation.node.stockQuantity,
        }
    })
    // console.log(formattedBasket)

    useEffect(() => {
        orderStore.getPendingOrders()
    }, [])
    
    return (
        <Container>
            {/* {isBasketLoading ?
                <PathPlaceholder /> :
                <Path path={basketPath} />} */}

            <div className='basket mt--50-20'>
                <h2 className='title'>Корзина</h2>
                
                {isBasketEmpty ? <>
                    <p className='text text--20-16 mt--60-30'>Ваша корзина пока пуста</p>
                    <Link to='/products'>
                        <button className='btn mt--60-30'>Перейти в каталог</button>
                    </Link></>

                    :

                    <div className='basket__grid'>
                        <div className='basket__products'>
                            {isBasketLoading ?
                                <div className='flex flex--jc-sb flex--ai-c'>
                                    <div className='placeholder placeholder--h-16'></div>
                                </div> :
                                <div className='flex flex--jc-sb flex--ai-c'>
                                    <p className='text text--16-14'>Товаров: {basketStore.basketItemCount}</p>
                                    <button onClick={handleBasketDelete} className='link-underlined'>Очистить корзину</button>
                                </div>}

                            {isBasketLoading ? 
                                Array(3).fill('').map((_, index) => {
                                    const isLastItem = 3 <= index + 1
                                    return (
                                        <React.Fragment key={'placeholder_basket_' + index}>
                                            <ProductCardSmallPlaceholder/>
                                            {!isLastItem && <div className='divider'></div>}
                                        </React.Fragment>
                                    )
                                }) :

                                formattedBasket.map((basketItem, index) => {
                                    const isLastItem = formattedBasket?.length <= index + 1
                                    return (
                                        <React.Fragment key={index}>
                                            <ProductCardSmall
                                                product={basketItem}
                                                key={index}
                                                index={index}
                                                onDelete={handleProductDelete(basketItem, index)}
                                                onIncreaseQuantity={handleQuantityChange(basketItem, 'increase', index)}
                                                onDecreaseQuantity={handleQuantityChange(basketItem, 'decrease', index)}
                                            />
                                            {!isLastItem && <div className='divider'></div>}

                                            
                                        </React.Fragment>
                                    )
                                })

                                
                            }
                            <div className='basket__tablet-price'>
                                <div className='divider'></div>
                                {isBasketLoading
                                    ? <TotalPriceBlockPlaceholder />
                                    : <TotalPriceBlock
                                        // delivery={formik.values.cdekDeliveryWay?.price}
                                        totalPrice={basketStore.newPrice}
                                        totalPriceWithoutDiscount={basketStore.basketPriceWithoutDiscount}
                                    />}
                            </div>
                            
                            {isBasketLoading
                                ? <button className='basket__mobile-btn btn placeholder--w-50' data-attr='placeholder'></button>
                                : <NavLink to='/order' className='basket__mobile-btn'>
                                    <button className='btn'>Перейти к оформлению</button>
                                </NavLink>}

                            {/* <div className='divider'></div> */}
                            {/* <TotalPriceBlock
                                totalPrice={basketStore.newPrice}
                                totalPriceWithoutDiscount={basketStore.newPrice}
                            /> */}
                        </div>

                        <PendingOrders />

                        <BasketOrder
                            className='basket__basket-order'
                            totalPrice={basketStore.totalPrice}
                            totalPriceWithoutDiscount={basketStore.totalPriceWithoutDiscount}
                        />
                    </div>}
            </div>
        </Container>
    )
})