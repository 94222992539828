import './TotalPriceBlock.scss'

export const TotalPriceBlockPlaceholder = () => {
    return (
        <div className='total-price-block flex flex--column'>
            <p className='placeholder placeholder--h-18'></p>

            <div className='divider'></div>

            <p className='placeholder placeholder--h-18'></p>
        </div>
    )
}