import { useContext, useEffect, useRef, useState } from 'react'
import './ProductCard.scss'
import { Price } from 'src/components/ProductCard/common'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation } from 'swiper'
import { yandexEcommerce, ym } from 'src/analytics'

const templateImages = [
    { id: 'template_01', sourceUrl: 'https://placehold.co/400x600', srcSet: 'https://placehold.co/400x600', altText: 'template' },
    { id: 'template_02', sourceUrl: 'https://placehold.co/400x600', srcSet: 'https://placehold.co/400x600', altText: 'template' },
    { id: 'template_03', sourceUrl: 'https://placehold.co/400x600', srcSet: 'https://placehold.co/400x600', altText: 'template' },
    { id: 'template_04', sourceUrl: 'https://placehold.co/400x600', srcSet: 'https://placehold.co/400x600', altText: 'template' },
]

const templateNames = { 'оверсайз худи': 'hoodie-oversize', 'оверсайз футболка': 't-shirt-oversize' }

const ProductCardSwiperControls = () => {
    const swiper = useSwiper()
    return (
        <div className='product-card__controls'>
            <button onClick={() => swiper.slidePrev()}>
                <svg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14' fill='none'>
                    <path d='M8 1L2 7L8 13' stroke='#12123B' strokeWidth='2' strokeLinecap='round' />
                </svg>
            </button>

            <button onClick={() => swiper.slideNext()}>
                <svg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14' fill='none'>
                    <path d='M1 1L7 7L1 13' stroke='#12123B' strokeWidth='2' strokeLinecap='round' />
                </svg>
            </button>
        </div>
    )
}

export const ProductCard = observer(({ openAddedToBasket, product, openSizeTable }) => {
    const navigate = useNavigate()
    const { basketStore } = useContext(AppContext)
    const [image, setImage] = useState(0)

    const isSizeTable = templateNames[Object.keys(templateNames).find(element => {
        const searchWords = element.split(' ')
        return searchWords.every(element => product.name?.toLowerCase().includes(element))
    })]
    const handleSizeTable = () => openSizeTable(isSizeTable)

    // Следит за изменением размера окна
    const [windowWidth, setWindowWidth] = useState(null)

    useEffect(() => {
        if (!windowWidth) {
            setWindowWidth(window.innerWidth)
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth])


    const sizes = product?.variations.nodes.map(variation => {
        return { 
            id: variation.databaseId,
            quantity: variation.stockQuantity,
            name: variation.name.split(' ').pop()
        }
    }).filter(v => v.quantity)
    const [size, setSize] = useState(sizes[0])
    const [isInBasket, setIsInBasket] = useState(basketStore.isItemInCart(size.id))

    useEffect(() => {
        const isInBasket = basketStore.isItemInCart(size.id)
        // console.log(size, size.id, isInBasket)
        setIsInBasket(isInBasket)
        if (isInBasket) {
            setQuantity(isInBasket)
        }
        // console.log('basket changed', basketStore.basket.length)
    }, [basketStore.basket, size]) // eslint-disable-line

    const [quantity, setQuantity] = useState(isInBasket || 1)
    // console.log(product)

    const handleImageChange = (direction) => {
        return () => {
            const imageCount = images.length
            const nextSlide = image + direction
            if (nextSlide < 0) {
                setImage(imageCount - 1)
                return
            }
            if (nextSlide > (imageCount - 1)) {
                setImage(0)
                return
            }
            setImage(nextSlide)
        }
    }
    // console.log(sizes, sizes[0])
    
    const handleSizeChange = (size) => {
        return () => {
            setSize(size)
            basketStore.isItemInCart(size.id)
                ? setQuantity(isInBasket)
                : setQuantity(1)
            // console.log(isProductInBasket, 'in basket')
        }
    }

    const imagesLength = product?.galleryImages.nodes.length
    const images = [...product?.galleryImages.nodes, ...templateImages.slice(imagesLength)]

    const handleQuantityChange = (direction) => {
        const nextQuantity = quantity + direction
        if (nextQuantity > 0 && nextQuantity <= size.quantity) {
            setQuantity(nextQuantity)
        }
    }

    const handleSizeClassNames = (item) => item === size.id
        ? 'btn-size btn-size--active'
        : 'btn-size'

    const oldPrice = product.regularPrice.split(',').shift()
    const newPrice = product.price.split(',').shift()

    const isBtnDisabled = basketStore.loading
    const btnText = isInBasket    
        ? 'Перейти в корзину'
        : 'В корзину'

    const btnClassNames = 'product-card__btn ' 
        + (isInBasket ? 'btn btn--light' : 'btn')
    
    const addToBasket = () => {
        basketStore.addToBasket(product.databaseId, size.id, quantity).then(() => {
            ym.AddToCart()

            const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                : product.collection + '/Одежда/' + product.name.split(' «')[0]

            const mappedForYandexEcommerce = {
                id: product.databaseId,
                name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                price: parseFloat(product.price),
                brand: 'DDX Fitness',
                category,
                // variant: product.name.split(' ').slice(-1).join(''),
                quantity,
                list: '',
                position: 1,
            }

            yandexEcommerce.addToCart(mappedForYandexEcommerce)

            // windowWidth > 600
            //     ? openAddedToBasket(size.id)
            //     : setAddedToBasketAlert(true)
        setAddedToBasketAlert(true)})
    }
    const handleBtnClick = () => isInBasket
        ? navigate('/basket')
        : addToBasket()
    // console.log(addToCartRes)

    const canIncreaseQuantity = size.quantity >= quantity + 1

    const increaseBasket = () => {
        basketStore.updateBasket(size.id, quantity + 1)
            .then(() => {
                ym.AddToCart()

                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    price: parseFloat(product.price),
                    brand: 'DDX Fitness',
                    category,
                    // variant: product.name.split(' ').slice(-1).join(''),
                    quantity: 1,
                    list: '',
                    position: 1,
                }

                yandexEcommerce.addToCart(mappedForYandexEcommerce)
            })
    }

    const handleIncreaseQuantityBtn = () => isInBasket
            ? increaseBasket()
            : handleQuantityChange(1)

    const decreaseBasket = () => {
        basketStore.updateBasket(size.id, quantity - 1)
            .then(() => {
                ym.DelToCart()

                const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
                    ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
                    : product.collection + '/Одежда/' + product.name.split(' «')[0]

                const mappedForYandexEcommerce = {
                    id: product.databaseId,
                    name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
                    category,
                    quantity: 1,
                    list: '',
                    position: 1,
                }

                yandexEcommerce.deleteFromCart(mappedForYandexEcommerce)
            })
    }

    const handleDesreaseQuantityBtn = () => isInBasket
        ? decreaseBasket()
        : handleQuantityChange(-1)

    const alertTimeout = useRef(null)
    const [addedToBasketAlert, setAddedToBasketAlert] = useState(false)

    useEffect(() => {
        if (addedToBasketAlert) {
            if (alertTimeout.current) clearTimeout(alertTimeout.current)
            alertTimeout.current = setTimeout(() => { setAddedToBasketAlert(false) }, 4000)
        }
    }, [addedToBasketAlert])


    useEffect(() => {
        setSize(sizes[0])
        setQuantity(1)
    }, [product.databaseId]) // eslint-disable-line

    return (
        <div className='product-card'>

            {/* only mobile */}
                <Swiper
                    key='product-card-slider'
                    slidesPerView='1'
                    spaceBetween={1}
                    direction='horizontal'
                    loop={true}
                    modules={[Navigation]}
                    className='product-card__images product-card__images--mobile'
                >
                    {images.map((image, idx) => {
                        return (
                            <SwiperSlide className='product-card__image' key={image?.id}>
                                <img
                                    src={image?.sourceUrl}
                                    srcSet={image?.srcSet}
                                    alt={image?.altText}
                                />
                            </SwiperSlide>
                        )
                    })}
                    <ProductCardSwiperControls />
                </Swiper>
                
            {/* desktop and tablet */}
            <div className='product-card__images'>
                {images &&
                    images.map((image, index) => {
                        return (
                            <div key={image?.id} className='product-card__image'>
                                <img
                                    src={image?.sourceUrl}
                                    srcSet={image?.srcSet}
                                    alt={image?.altText}
                                />
                            </div>
                        )
                    })}
            </div>

            <div className='product-card__descriptions'>

                <div className='product-card__header'>
                    <p>{product.collection}</p>
                    <h2 className='title'>{product.name}</h2>
                </div>

                <div className='product-card__flex-40'>

                    <Price
                        onSale={product.onSale}
                        oldPrice={oldPrice}
                        newPrice={newPrice}
                    />

                    <div className='product-card__quantity'>
                        <button disabled={!canIncreaseQuantity} onClick={handleIncreaseQuantityBtn}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='10' height='24' viewBox='0 0 10 24' fill='none'>
                                <rect x='6' y='7' width='10' height='2' rx='1' transform='rotate(90 6 7)' fill='#B2B2C1' />
                                <rect y='11' width='10' height='2' rx='1' fill='#B2B2C1' />
                            </svg>
                        </button>

                        <p>{quantity}</p>

                        <button onClick={handleDesreaseQuantityBtn}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='10' height='24' viewBox='0 0 10 24' fill='none'>
                                <rect y='11' width='10' height='2' rx='1' fill='#B2B2C1' />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className='product-card__size'>
                    <p>Выберите размер:</p>

                    <div className='product-card__flex-40'>
                        <div className='product-card__size-list'>
                            {sizes &&
                                sizes.map((size, index) => {
                                    return (
                                        <button
                                            key={index}
                                            onClick={handleSizeChange(size)}
                                            className={handleSizeClassNames(size.id)}
                                        >
                                            {size.name}
                                        </button>
                                    )
                                })}
                        </div>

                        {isSizeTable &&
                            <button onClick={handleSizeTable} className='link-underlined'>Таблица размеров</button>}
                    </div>
                </div>
                
                <div className='product-card__btn-and-alert'>
                    {addedToBasketAlert && 
                        <div className='product-card__added-to-card text'>
                            Товар добавлен в корзину
                        </div>}

                    <button
                        onClick={handleBtnClick}
                        className={btnClassNames}
                        disabled={isBtnDisabled}
                    >
                        {btnText}
                    </button>
                </div>

                <div className='product-card__about'>

                    {product.color &&
                        <div className='product-card__about-container'>
                            <h3>Цвет:</h3>
                            <p>{product.color}</p>
                        </div>}

                    {product.article &&
                        <div className='product-card__about-container'>
                            <p>Артикул: {product.article}</p>
                        </div>}

                    {product.shortDescription &&
                        <div
                            className='product-card__about-container'
                            dangerouslySetInnerHTML={{ __html: product.shortDescription }}
                        >
                        </div>}
                    {/* {product.description &&
                        <div className='product-card__about-container'>
                            <p>{product.description}</p>
                        </div>} */}

                    {product.description &&
                        <div className='product-card__about-container'>
                            <h3>Состав и уход:</h3>
                            <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                        </div>}
                </div>
            </div>
        </div>
    )
})