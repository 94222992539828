import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AppContext } from 'src'

export const PendingOrders = observer(() => {
    const { orderStore } = useContext(AppContext)

    const handlePendingOrdersWord = (number) => {        
        if (number > 10) {
            const lastTwoDigits = Number(String(number).split('').slice(-2).join(''))
            if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
                return 'неоплаченных заказов'
            }
        }

        const lastDigit = Number(String(number).split('').slice(-1).join(''))
        if (lastDigit === 1) {
            return 'неоплаченный заказ'
        }
        if (lastDigit >= 2 && lastDigit <= 4) {
            return 'неоплаченных заказа'
        }
        if ((lastDigit >= 5 && lastDigit <= 9) || lastDigit === 0) {
            return 'неоплаченных заказов'
        }
    }
    const pendingOrdersLength = orderStore.pendingOrders?.length
    const isPendingOrders = pendingOrdersLength > 0
    const pendingOrdersText = `У вас ${pendingOrdersLength} ${handlePendingOrdersWord(pendingOrdersLength)}`

    if (!isPendingOrders) return

    return (
        <div className='basket__pending-orders' style={{ backgroundColor: '#E5E5EB' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='22' height='24' viewBox='0 0 22 24' fill='none'>
                <path d='M1 5V19L11 23M1 5L11 1L15.5 2.8M1 5L6 7M11 9V23M11 9L21 5M11 9L6 7M11 23L21 19V5M21 5L15.5 2.8M6 7V12M6 7L15.5 2.8' stroke='#ED692A' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>

            <div>
                <p className='text text--16-14'>{pendingOrdersText}</p>

                <NavLink to='/pending-orders'>
                    <button className='link-underlined'>Подробнее</button>
                </NavLink>
            </div>
        </div>
    )
})