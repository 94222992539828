import './ContactUs.scss'
import { Checkbox } from 'src/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validateEmail } from 'src/utils'

import personalData from 'src/files/personal_data.pdf'
import offer from 'src/files/offer.pdf'
import { useState } from 'react'
import { sendQuestion } from 'src/api/question'

export const ContactUs = ({ openQuestionSentModal }) => {
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            question: '',
            personalData: true,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().max(50).test('invalid email', 'invalid email', validateEmail),
            question: Yup.string().required().min(3).max(255),
            personalData: Yup.bool().test('invalid value', 'invalid value', val => val),
        }),
        onSubmit: async (values, actions) => {
            setLoading(true)
            sendQuestion(values.email.trim(), values.question.trim())
                .then(res => {
                    formik.resetForm()
                    openQuestionSentModal()
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    })

    const handleInputClassNames = (name, type = 'input') => {
        const isError = formik.errors[name] && formik.touched[name]
        return isError ? `${type} ${type}--error` : `${type}`
    }

    const isSubmitButtonDisabled = Object.keys(formik.errors).length || loading

    return (
        <div className='contact-us block block--sm'>
            <h3 className='title title--sm'>Свяжитесь с нами</h3>

            <form onSubmit={formik.handleSubmit} className='flex flex--column flex--g-10'>
                <input
                    // tabIndex='2'
                    className={handleInputClassNames('email')}
                    type='text' placeholder='Email' name="email" id="email"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={loading}
                />
                <textarea 
                    className={handleInputClassNames('question', 'textarea')}
                    // tabIndex={0}
                    error={(formik.errors.question && formik.touched.question) && 'true'}
                    type='text' placeholder='Введите вопрос...' name="question" id="question"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    value={formik.values.question}
                    disabled={loading}
                ></textarea>

                <Checkbox
                    name='personalData' id='personalData'
                    checked={formik.values.personalData}
                    onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                    disabled={loading}
                >
                    {/* Я согласен с <a href={offer} target='_blank' rel='noreferrer'>публичным договором-офертой</a> и <a href={personalData} target='_blank' rel='noreferrer'>политикой обработки персональных данных</a> */}
                    Я согласен с <a href={personalData} target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                </Checkbox>
                {/* <Checkbox
                    name='rim' id='rim'
                    checked={formik.values.rim}
                    onClick={() => formik.setFieldValue('rim', !formik.values.rim)}
                    disabled={loading}
                >
                    Я согласен(а) на <a href='https://www.ddxfitness.ru/consent-advertising/' target='_blank' rel='noreferrer'>рассылку новостей и предложений DDX Fitness</a>
                </Checkbox> */}

                <div className='contact-us__footer mt--10'>
                    <button
                        // disabled
                        className='btn'
                        disabled={isSubmitButtonDisabled}
                        type='submit'
                    >
                        Отправить
                    </button>
                </div>
            </form>

        </div>
    )
}