import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { ProductCardSmall, TotalPriceBlock } from 'src/components'
import './OrderBasket.scss'

export const OrderBasket = observer(() => {
    const { basketStore } = useContext(AppContext)

    const isBasketLoading = !basketStore.basket ? (!basketStore.basket || basketStore.loading) : false

    const formattedBasket = basketStore.basket?.map((basketItem) => {
        // console.log(basketItem.variation.node)
        return {
            id: basketItem.variation.node.parent.node.databaseId,
            collection: 'Streetwear Collection',
            name: basketItem.variation.node.name.split('-').shift(),
            newPrice: parseFloat(basketItem.variation.node.price),
            oldPrice: parseFloat(basketItem.variation.node.regularPrice),
            size: basketItem.variation.node.name.split('-').pop(),
            images: [basketItem.variation.node.image.sourceUrl],
            quantity: basketItem.quantity,
            stockQuantity: basketItem.variation.node.stockQuantity,
        }
    })

    return (
        isBasketLoading
            ? <div className='order-basket placeholder' data-attr='placeholder'></div>
            : <div className='order-basket block flex flex--column flex--g-30'>
                <div className='order-basket__title'>
                    <h3 className='title title--sm'>Корзина</h3>
                    <p className='text text--16-14'>({basketStore.basketItemCount})</p>
                </div>

                <div className='flex flex--column flex--g-20'>
                    {formattedBasket?.map((product, index) => {
                        return <ProductCardSmall product={product} key={index} />
                    })}

                    <div className='divider'></div>

                    <TotalPriceBlock
                        delivery={basketStore.deliveryPrice}
                        totalPrice={basketStore.newPrice}
                        totalPriceWithoutDiscount={basketStore.basketPriceWithoutDiscount}
                    />
                </div>

                {/* <div className='grid grid--c-2-2-1 grid--g-10'>
                    <input className='input' placeholder='Введите промокод' type='text' />
                    <button className='btn btn--dark'>Применить</button>
                </div> */}
            </div>
    )
})