export const addSeparators = (number) => {
    // Преобразуем число в строку
    let numberString = number.toString()

    // Разделяем строку на части по точке (если есть десятичная часть)
    let parts = numberString.split('.')

    // Добавляем разделители тысяч к целой части числа
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    // Собираем строку обратно, включая десятичную часть (если она есть)
    let result = parts.join('.')

    return result
}