import './SwiperSlider.scss'
import 'swiper/css'
import placeholderSwiperTitleImage from './images/420x40.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import { SwiperControls } from './common'
import { useContext, useEffect, useRef } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

// можно принимать данные о блоке (популярное, недавно смотрели и тд в пропсах)
export const SwiperSlider = observer(({ title = 'ПОПУЛЯРНОЕ', slides = [], offset, title_sm, autoSlide }) => {
    const titleClassNames = 'title'
        + (title_sm ? ' swiper-slider__title' : '')
    
    const showControls = slides.length > 3
    const showSlides = showControls ? [...slides, ...slides] : slides

    // отключаем слайдер - если идет загрузка
    const { productStore, basketStore, orderStore } = useContext(AppContext)
    const swiperElement = useRef(null)

    useEffect(() => {
        const isLoading = productStore.loading || basketStore.loading || orderStore.loading
        isLoading
            ? swiperElement.current.swiper.autoplay.stop()
            : swiperElement.current.swiper.autoplay.start()
    }, [productStore.loading, basketStore.loading, orderStore.loading])

    const autoplay = autoSlide ? {
        delay: 3000,
        disableOnInteraction: false,
    } : {}

    const isTitle = title.length > 0
        
    return (
            <div className='swiper-slider mt--100-60'>
                {isTitle &&
                    <h2 className={titleClassNames}>{title}</h2>}
                
                <Swiper
                    ref={swiperElement}
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    autoplay={autoplay}
                    loop={true}
                    modules={[Autoplay, Navigation]}
                    className='swiper-slider__swiper'
                    breakpoints={{
                        1000: {
                            slidesPerView: 4,
                        },
                        650: {
                            slidesPerView: 3,
                        },
                        500: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {showSlides.map((slide, idx) => {
                        return (
                            <SwiperSlide key={idx + Date.now()} className='swiper-slider__slide'>
                                {slide}
                            </SwiperSlide>
                        )
                    })}

                    {showControls &&
                        <SwiperControls offset={offset} />}

                </Swiper>
            </div>
    )
})