import { Container, Orders, Path } from 'src/components'
import './PendingOrders.scss'
import { Link, NavLink } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { toJS } from 'mobx'

const pendingOrdersPath = [{ name: 'Главная', path: '/' }, { name: 'Заказы', path: '/pending-orders' }]

export const PendingOrders = observer(() => {
    const { orderStore, productStore } = useContext(AppContext)

    const [order, setOrder] = useState(null)
    const selectOrder = (order) => () => setOrder(order)
    const deleteOrder = () => setOrder(null)

    // закрывать подробности при смене разрешения на десктопное

    useEffect(() => {
        orderStore.getPendingOrders()
    }, [])


    const isPendingOrders = orderStore.pendingOrders?.length > 0
    const isProducts = productStore.products?.length > 0

    const mappedPendingOrders = (isPendingOrders && isProducts)
        ? orderStore.pendingOrders?.map((order) => {
            const items = order.items.map((orderItem) => {
                const itemFromStorage = productStore.getProductFromStorage(orderItem.id)
                // console.log(toJS(itemFromStorage))
                return { 
                    ...itemFromStorage,
                    id: orderItem.id,
                    quantity: orderItem.quantity,
                    newPrice: orderItem.newPrice,
                    images: [itemFromStorage?.galleryImages?.nodes[0].sourceUrl],
                    size: orderItem.size,
                }
            })
            return { ...order, items }
        })
        : []

    const showPendingOrders = isPendingOrders && isProducts

    return (
        <Container>
            <div className='pending-orders'>
                {/* <Path path={pendingOrdersPath} /> */}

                {order
                    ? <></>
                    : <>
                        <button className='mt--30-20 flex flex--ai-c flex--g-10'>
                            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M13.5 9L10.5 12L13.5 15' stroke='#676785' strokeWidth='2' strokeLinecap='round' />
                                <rect x='23' y='1' width='22' height='22' rx='11' transform='rotate(90 23 1)' stroke='#676785' strokeOpacity='0.5' strokeWidth='2' />
                            </svg>
                            <NavLink to='/basket'>
                                <p className='text text--16-14 text--500'>Вернуться назад</p>
                            </NavLink>
                        </button>

                        <h2 className='order__title title mt--30-20'>Ваши заказы</h2>
                    </>}

                {showPendingOrders
                    ?
                        <div className='pending-orders__grid mt--60-30'>
                            <Orders
                                orders={mappedPendingOrders}
                                isPayButton
                                showOrder={selectOrder}
                                hideOrder={deleteOrder}
                                order={order}
                            />
                        </div>
                    :
                        <>
                            <p className='mt--60-30 text text--20-14'>
                                У вас нет неоплаченных заказов
                            </p>

                            <Link to='/products'>
                                <button className='mt--60-30 btn'>Перейти в каталог</button>
                            </Link>
                        </>}
            </div>
        </Container>
    )
})