import './Refund.scss'

export const Refund = () => {
    return (
        <div className='for-customers-refund'>
            <div>
                <p className='mt--10 text text--16-14'>Предположим, что вы обнаружили заводской брак в присланном товаре или же вам не подошёл размер.
            Вы можете вернуть или обменять товар в течение 7 календарных дней с момента получения заказа (п. 4 ст. 26.1 Закона о защите прав потребителей). Если найден заводской брак, но вещь не носили, не стирали и сохранены все этикетки, бодрый вид и товарные чеки – мы оформим возврат за наш счёт. Если вы ошиблись с размером, вернуть или обменять вещь можно за свой счёт. Внимательно сверяйтесь с размерными таблицами!
            Как оформить возврат или обмен</p>
            </div>

            <div>
                <p className='mt--10 text text--16-14'>1. Заполните заявление о возврате и отправьте его вместе с фото товара на почту: АДРЕС НАШЕЙ ЭЛ. ПОЧТЫ.</p>
                <p className='mt--10 text text--16-14'>2. Аккуратно упакуйте товар, вложите заявление о возврате.</p>
                <p className='mt--10 text text--16-14'>3. Обратитесь в ближайший офис СДЭК для отправки на адрес: 117279, г. Москва, ул. Миклухо-Маклая 36А, каб. 608.</p>
            </div>

            <div>
                <p className='mt--10 text text--16-14'>После проверки с нашей стороны:</p>
                <p className='mt--10 text text--16-14'>Оформлен ВОЗВРАТ — запрос на возмещение денежных средств передаётся в банк;</p>
                <p className='mt--10 text text--16-14'>Оформлен ОБМЕН — отправим вам выбранную замену.</p>
                <p className='mt--10 text text--16-14'>Мы полностью берём на себя расходы за доставку вовзращаемого или обменного заказа.</p>
                <p className='mt--10 text text--16-14'>Срок на возврат денежных средств</p>
                <p className='mt--10 text text--16-14'>Мы перечислим деньги в течение 10 дней. Отсчет начинается на следующий день после поступления изделия на наш склад</p>
                <p className='mt--10 text text--16-14'>Если вы оплачивали заказ при получении, мы переведем деньги по реквизитам в заявлении на возврат. Если вы оплачивали заказ банковской картой на сайте, деньги вернутся на ту же карту. Пожалуйста, учитывайте, что на карту деньги могут поступить позже — срок обработки платежа зависит от вашего банка.</p>
                <p className='mt--10 text text--16-14'>Если вы хотите получить информацию о статусе вашего возврата, свяжитесь с нами по электронной почте: АДРЕС НАШЕЙ ЭЛ. ПОЧТЫ</p>
            </div>
        </div>
    )
}