export const DownUpIcon = ({ isActive, ...props }) => {
    const iconClassNames = 'header-icon-stroke' +
        (isActive ? 'header-icon__rotate-vertically' : '')
        
    return (
        <div className={iconClassNames} {...props}>
            <svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1 1L7 7L13 1' strokeWidth='2' strokeLinecap='round' />
            </svg>
        </div>
    )
}