import { useNavigate } from 'react-router-dom'
import { DeleteBtn, Price, Quantity } from '../common'
import './ProductCardSmall.scss'
import { yandexEcommerce, ym } from 'src/analytics'

export const ProductCardSmall = ({ product, onDelete, onIncreaseQuantity, onDecreaseQuantity, index }) => {
    const showQuantityControls = onIncreaseQuantity && onDecreaseQuantity 
    const navigate = useNavigate()
    const handleProductClick = () => {
        ym.ProductClick()
        

        // const category = product?.productCategories?.nodes?.find(category => category.name === 'Бейсболки')
        //     ? product.collection + '/Аксессуары/' + product.name.split(' «')[0]
        //     : product.collection + '/Одежда/' + product.name.split(' «')[0]

        // const mappedForYandexEcommerce = {
        //     id: product.databaseId,
        //     name: product.name.split(' ').slice(0, -1).join(' '), // Оверсайз футболка «С удовольствием» белая - убираем последнее слово с цветом
        //     price: parseFloat(product.price),
        //     brand: 'DDX Fitness',
        //     category,
        //     variant: product.name.split(' ').slice(-1).join(''),
        //     list: '',
        //     position: index + 1,
        // }
        // console.log(toJS(product))

        // yandexEcommerce.clickProduct(mappedForYandexEcommerce)

        navigate('/products/' + product.id)
    }
    const handleCollectionClick = () => navigate('/products?collection=STREETWEAR')

    // console.log(toJS(product))

    return (
        <div className='product-card-small'>
            <div className='product-card-small__image'>
                <img src={product.images[0]} alt='card' />
            </div>

            <div className='product-card-small__container'>
                <div>
                    <p onClick={handleCollectionClick} className='product-card-small__sub-title'>{product.collection}</p>
                    <h3 onClick={handleProductClick} className='product-card-small__title'>{product.name}</h3>
                </div>

                <Price
                    className='product-card-small__price'
                    newPrice={product.newPrice}
                    oldPrice={product.oldPrice}
                />

                <div className='product-card-small__footer'>
                    {showQuantityControls &&
                        <Quantity
                            value={product.quantity}
                            onIncreaseQuantity={onIncreaseQuantity}
                            onDecreaseQuantity={onDecreaseQuantity}
                        />}

                    <div className='flex flex--ai-c flex--g-10'>
                        <p className='text text--half-grey text--14-12'>Размер:</p>
                        <p className='product-card-small__size text text--16-14 text--700'>{product.size}</p>
                    </div>
                </div>
            </div>

            <div className='product-card-small__container'>
                {onDelete &&
                    <DeleteBtn
                        className='product-card-small__delete'
                        onDelete={onDelete}
                    />}
            </div>
        </div>
    )
}