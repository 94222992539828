const yandexMetrics = (goal) => {
    const ymCode = 95843776
    if (goal) {
        // console.log(goal)
        window.ym(ymCode, 'reachGoal', goal)
    }
}

const ProductClick = () => {
    yandexMetrics('Product_click')
}

const AddToCart = () => {
    yandexMetrics('AddToCart')
}

const InitiateCheckout = () => {
    yandexMetrics('InitiateCheckout')
}

const DelToCart = () => {
    yandexMetrics('DelToCart')
}

const Purchase = () => {
    yandexMetrics('Purchase')
}

const PurchaseFiled = () => {
    yandexMetrics('PurchaseFiled')
}

export const ym = {
    ProductClick,
    AddToCart,
    InitiateCheckout,
    DelToCart,
    Purchase,
    PurchaseFiled,
}