import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_ID = gql`
    query MyQuery($id: ID!) {
        product(id: $id, idType: DATABASE_ID) {
        name
        onSale
        ... on VariableProduct {
            databaseId
            name
            onSale
            regularPrice
            price
            shortDescription
            description
            variations {
                nodes {
                    name
                    databaseId
                    stockQuantity
                    stockStatus
                    purchasable
                }
            }
            productCategories {
                nodes {
                    databaseId
                    name
                }
            }
            galleryImages {
                nodes {
                    altText
                    id
                    sourceUrl
                    srcSet
                }
            }
        }
        }
    }  
`