import axios from "axios"

export const getRegions = async () => {
    try {
        // const response = await axios.get('https://api.ddxmerch.ru/odrow_cdek/cdek_location_cities.php?country_codes=RU')
        const response = await axios.post('https://api.ddxmerch.ru/wp-json/wc/custom/cities?country_codes=RU')
        
        return response.data
    } catch (err) {
        console.log(err)
    }
}