import { gql } from "@apollo/client"

export const GET_CART = gql`
    query GET_CART {
        cart(recalculateTotals: true) {
            contents {
                nodes {
                    variation {
                        node {
                            databaseId
                            name
                            onSale
                            price
                            regularPrice
                            image {
                                databaseId
                                sourceUrl
                                srcSet
                                altText
                            }
                            stockQuantity
                            stockStatus
                            width
                            weight
                            height
                            length
                            parent {
                                node {
                                    databaseId
                                }
                            }
                        }
                            attributes {
                            id
                            name
                            value
                        }
                    }
                    quantity
                    key
                }
            }
            appliedCoupons {
                code
                discountAmount
            }
            shippingTotal
            total
            discountTotal
        }
    }
`