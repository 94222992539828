import { SelectBase } from '../common'
import './Select.scss'

const template = [
    // { id: 1, name: 'DDX Авиапарк', address: '(Ходынский б-р, д. 4, ТРЦ "Авиапарк", 3 этаж)' },
    // { id: 2, name: 'DDX Мытищи', address: '(Ходынский б-р, д. 4, ТРЦ "Авиапарк", 3 этаж)' },
    // { id: 3, name: 'DDX Ясенево', address: '(Ходынский б-р, д. 4, ТРЦ "Авиапарк", 3 этаж)' },
]

export const Select = ({ 
    formik,
    placeholder,
    search,
    searchString,
    setSearchString,
    notFoundText,
    options = template,
    value,
    onChange,
    onBlur,
    error,
    ...props
}) => {
    return (
        <SelectBase
            formik={formik}
            search={search}
            searchString={searchString}
            setSearchString={setSearchString}
            notFoundText={notFoundText}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            {...props}
        />
    )
}