import { makeAutoObservable } from 'mobx'

class AuthStore {

    isAuth = false

    loading = false
    error = ''

    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

}

export default AuthStore