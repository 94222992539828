// import style from '../Select.module.css'

export const SelectArrow = ({ baseClassName }) => {

    if (baseClassName.includes('select-small')) {
        return (
            <svg className={baseClassName + '__arrow'} xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6' fill='none'>
                <path d='M1 1.5L4 4.5L7 1.5' strokeWidth='2' strokeLinecap='round' />
            </svg>
        )
    }

    return (
        <svg className={baseClassName + '__arrow'} xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'>
            <path d='M1 1.5L6 6.5L11 1.5' strokeWidth='2' strokeLinecap='round' />
        </svg>
    )
}
