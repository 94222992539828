import { SelectOption } from './SelectOption'

export const SelectList = ({ options, value, handleSelect, baseClassName, notFoundText = 'Ничего не найдено'}) => {
    const largeOption = !baseClassName.includes('select-small')

    return (
        <div className={baseClassName + '__options'}>
            {options.map((option, idx) => {
                return <SelectOption
                    key={idx + Date.now()}
                    onClick={_ => handleSelect(option)}
                    active={value?.id === option.id}
                    baseClassName={baseClassName}
                >
                    {largeOption ? 
                        <>
                            <p>{option.name}</p>
                            <p>{option.address}</p>
                        </> :

                        option.name}
                </SelectOption>
            })}
            {!options.length 
                && <SelectOption baseClassName={baseClassName} disabled>{notFoundText}</SelectOption>}
        </div>
    )
}
