import './CatalogSort.scss'

export const CatalogSortPlaceholder = () => {
    return (
        <div className='catalog-sort'>
            <div className='catalog-sort__container'>
                <div className='catalog-sort__params'></div>

                <div className='catalog-sort__param catalog-sort__param--light'>
                    <p className='placeholder' data-attr='placeholder'></p>
                </div>
            </div>

            <div className='catalog-sort__divider'></div>
        </div>
    )
}