import { AboutBlock, SliderImage, SwiperSlider } from 'src/components'
import './About.scss'

import slide01 from './images/slide01.png'
import slide02 from './images/slide02.png'
import slide03 from './images/slide03.png'
import slide04 from './images/slide04.png'

const slides = [
    <SliderImage image={slide01} />,
    <SliderImage image={slide02} />,
    <SliderImage image={slide03} />,
    <SliderImage image={slide04} />,
    <SliderImage image={slide01} />,
    <SliderImage image={slide02} />,
    <SliderImage image={slide03} />,
    <SliderImage image={slide04} />,
]

export const About = () => {
    return (
        <div className='about'>
            <AboutBlock />

            <div className='container'>
                <SwiperSlider title='DDX Fitness - это ...' slides={slides} />

                <div className='flex flex--column flex--ai-c mt--100-60'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='46' height='42' viewBox='0 0 46 42' fill='none'>
                        <path d='M2.44467 0.0111715C2.75863 0.00970002 3.06978 0.0704513 3.36027 0.189936C3.65075 0.309421 3.91483 0.485285 4.13736 0.707429C4.35988 0.929573 4.53645 1.19362 4.65694 1.4844C4.77742 1.77519 4.83944 2.08698 4.83944 2.40187V6.71407C4.83944 12.9813 6.64387 17.8856 10.2082 21.1029C13.3604 23.9964 17.7935 25.5715 23.0174 25.5715C38.8341 25.5715 41.1954 13.7856 41.1954 6.75875V2.40187C41.1954 1.76485 41.4477 1.15393 41.8968 0.703492C42.3459 0.253053 42.9551 0 43.5902 0C44.2253 0 44.8344 0.253053 45.2836 0.703492C45.7327 1.15393 45.985 1.76485 45.985 2.40187V6.71407C45.985 15.1262 43.3786 21.4716 38.2549 25.5604C33.3205 29.5039 27.2389 30.3306 23.0174 30.3306C16.5794 30.3306 11.0324 28.3644 6.97803 24.6331C2.45581 20.4773 0.0610352 14.2772 0.0610352 6.75875V2.40187C0.0610352 1.76782 0.312167 1.15973 0.759185 0.711391C1.2062 0.263048 1.81249 0.0111715 2.44467 0.0111715Z' fill='#676785' />
                        <path d='M13.616 0H32.4178C33.3956 0 34.3334 0.389585 35.0248 1.08305C35.7162 1.77652 36.1046 2.71706 36.1046 3.69776C36.1046 12.5568 31.3373 17.651 23.0169 17.651C14.6965 17.651 9.9292 12.5568 9.9292 3.69776C9.9292 2.71706 10.3176 1.77652 11.009 1.08305C11.7005 0.389585 12.6382 0 13.616 0ZM29.4215 4.79257H16.6123C16.3698 4.79407 16.1306 4.84958 15.9121 4.9551C15.6936 5.06062 15.5012 5.21351 15.3487 5.40272C15.1963 5.59193 15.0877 5.81273 15.0308 6.04918C14.9738 6.28562 14.97 6.5318 15.0195 6.76992C15.8437 10.8587 18.4947 12.8472 23.0503 12.8472C26.1134 12.8472 30.0341 12.1546 31.0812 6.76992C31.1276 6.52671 31.1193 6.27617 31.057 6.03657C30.9947 5.79696 30.8798 5.57432 30.7208 5.38488C30.5618 5.19544 30.3627 5.04396 30.1379 4.9415C29.9131 4.83903 29.6684 4.78815 29.4215 4.79257Z' fill='#676785' />
                        <path d='M23.0169 25.5273C31.3373 25.5273 36.1046 30.6104 36.1046 39.4805V39.7486C36.1046 40.3412 35.8699 40.9095 35.4521 41.3285C35.0344 41.7475 34.4677 41.9829 33.8769 41.9829C33.2063 41.9829 32.5632 41.7158 32.089 41.2402C31.6149 40.7646 31.3485 40.1196 31.3485 39.447C31.3485 31.2471 26.6035 30.2976 23.0392 30.2976C17.4699 30.2976 14.741 33.2915 14.741 39.447V39.7151C14.741 40.3077 14.5063 40.876 14.0885 41.295C13.6708 41.714 13.1041 41.9494 12.5133 41.9494C11.8446 41.9644 11.1972 41.713 10.7128 41.2503C10.2285 40.7876 9.94671 40.1512 9.9292 39.4805C9.9292 30.6104 14.6965 25.5273 23.0169 25.5273Z' fill='#676785' />
                    </svg>

                    <div className='about__ddx text text--24-14 text--500'>
                        <p>DDX FITNESS</p>
                        <p className='text--half-grey'>Est. 2018</p>
                    </div>
                </div>
            </div>
        </div>
    )
}