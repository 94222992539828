import { Path } from 'src/components'
import './style.scss'
import { Link } from 'react-router-dom'

// const paymentErrorPath = [{ name: 'Главная', path: '/' }, { name: 'Корзина', path: '/basket' }]

export const Error = () => {
    return (
        <div className='container error'>
            {/* <Path path={paymentErrorPath} /> */}

            <div className='mt--50-20 error__container'>
                <h2 className='title'>Упс!</h2>
                <p className='mt--60-30 text text--20-14'>
                    При оформлении заказа произошла ошибка
                </p>

                <Link to='/products'>
                    <button className='mt--60-30 btn'>Вернуться к покупкам!</button>
                </Link>
                
            </div>
        </div>
    )
}