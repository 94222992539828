

const openCatalog = (products) => {
    // Просмотр списка товаров
    // Данные должны отправляться в момент открытия списка товаров. (каталог или рекомендации, когда они в видимой зоне)
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",
            "impressions": products
        }
    })

    // console.log('open catalog ym __', products)
}

const clickProduct = (product) => {
    // Клик по товару из списка
    // Данные должны передаваться в момент, когда пользователь совершил клик по ссылке товара.
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",
            "click": {
                "products": [
                    product
                ]
            }
        }
    })

    // console.log('click product ym __', product)
}

const openProduct = (product) => {
    // Просмотр товара
    // Данные должны отправляться в момент открытия страницы с карточкой товара.
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",
            "detail": {
                "products": [
                    product
                ]
            }
        }
    })

    // console.log('open product ym __', product)
}

const addToCart = (product) => {
    // Добавление товара в корзину
    // Данные должны отправляться в момент добавления заказа в корзину. Например, при нажатии кнопки «Добавить в корзину».
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",    
            "add": {
                "products": [
                    product
                ]
            }
        }
    })

    // console.log('add to cart ym __', product)
}

const deleteFromCart = (product) => {
    // Удаление товара из корзины
    // Данные должны отправляться в момент удаления заказа из корзины.
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",
            "remove": {
                "products": [
                    product
                ]
            }
        }
    })

    // console.log('delete from cart ym __', product)
}

const purchase = (orderId, products) => {
    // Покупка
    // Данные должны отправляться в момент подтверждения заказа.
    window?.dataLayer?.push({
        "ecommerce": {
            "currencyCode": "RUB",
            "purchase": {
                "actionField": {
                    "id" : orderId
                },
                "products": products
            }
        }
    })

    // console.log('purchase ym __', orderId, products)
}

export const yandexEcommerce = {
    openCatalog,
    clickProduct,
    openProduct,
    addToCart,
    deleteFromCart,
    purchase,
}