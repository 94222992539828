import { useContext, useState } from 'react'
import MultiRangeSlider from 'multi-range-slider-react'
import { AppContext } from 'src'
import { Logo, SelectSmall } from 'src/components'
import './styles.scss'
import { observer } from 'mobx-react-lite'

export const FilterModal = observer(({ onClose }) => {
    const { productStore } = useContext(AppContext)
    const [filters, setFilters] = useState(productStore.filter.filters)

    // const isChecked = (filterType, filterValue) => {
    //     return filters[filterType].find(param => param.id === filterValue.id)
    // }
    // const handleFilterClick = (filterType, filterValue) => () => {
    //     if (isChecked(filterType, filterValue)) {
    //         setFilters(prevFilters => ({ ...prevFilters, [filterType]: prevFilters[filterType].filter(filterItem => filterItem.id !== filterValue.id) }))
    //     } else {
    //         setFilters(prevFilters => ({ ...prevFilters, [filterType]: [...prevFilters[filterType], filterValue] }))
    //     }
    // }

    const handleFilterSubmit = () => { 
        productStore.setFilters(filters)
        if (minValue === productStore.prices[0] && maxValue === productStore.prices[1]) {
            productStore.handleFilterPrice(null, null)
        } else {
            productStore.handleFilterPrice(minValue, maxValue)
        }
        onClose()
    }
    const handleFilterDelete = () => {
        productStore.deleteFilters(['sizes', 'colors'])
        productStore.handleFilterPrice(null, null)
        setFilters(productStore.filter.filters)
        setMinValue(productStore.prices[0])
        setMaxValue(productStore.prices[1])
        onClose()
    }

    const [minValue, setMinValue] = useState(filters.minPrice || productStore.prices[0])
    const [maxValue, setMaxValue] = useState(filters.maxPrice || productStore.prices[1]);

    const handleInput = (e) => {
        setMinValue(e.minValue)
        setMaxValue(e.maxValue)
    }

    const isSort = filters.sortBy && filters.sortOrder
    const sortText = isSort && filters.sortBy === 'price'
        ? filters.sortOrder === 'asc'
            ? 'Сначала дешевле'
            : 'Сначала дороже'
        : 'Сортировать по'

    const handleSortSelect = (value) => {
        if (value.name === 'Сначала дешевле') {
            setFilters(prev => ({ ...prev, sortBy: 'price', sortOrder: 'asc' }))
        } else if (value.name === 'Сначала дороже') {
            setFilters(prev => ({ ...prev, sortBy: 'price', sortOrder: 'desc' }))
        }
    }

    return (
        <div className='catalog-modal container'>
            <div className='catalog-modal__header'>
                <Logo />

                <button onClick={onClose}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' />
                        <rect x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' />
                    </svg>
                </button>
            </div>

            <div className='catalog-modal__body'>

                <h3 className='catalog-modal__title'>Фильтр</h3>

                <div className='catalog-modal__container catalog-modal__container--filter'>

                    <div className='catalog-modal__body-price'>

                        <p className='text text--inter text--500'>Цена:</p>

                        <div className='flex flex--ai-c flex--g-10'>
                            <input
                                className='input-price'
                                placeholder='от'
                                value={minValue}
                                onChange={(e) => setMinValue(e.target.value)}
                            />
                            <div>-</div>
                            <input
                                className='input-price'
                                placeholder='до'
                                value={maxValue}
                                onChange={(e) => setMaxValue(e.target.value)}
                            />
                        </div>

                        <MultiRangeSlider
                            ruler={false}
                            label={false}
                            // preventWheel={false}
                            className='range'
                            min={productStore.prices[0]}
                            max={productStore.prices[1]}
                            step={1}
                            minValue={minValue}
                            maxValue={maxValue}
                            onInput={(e) => {
                                handleInput(e)
                            }}
                        />

                    </div>

                    {/* <div className='flex flex--column flex--g-20-20-10'>

                        <p className='text text--inter text--500'>Размер:</p>

                        <div className='flex flex--column flex--g-10'>
                            {productStore.sizes.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index + '_size'}
                                        checked={isChecked('sizes', filterItem)}
                                        onClick={handleFilterClick('sizes', filterItem)}
                                    >
                                        {filterItem.name}
                                    </Checkbox>)
                            })}
                        </div>

                    </div>
                    <div className='flex flex--column flex--g-20-20-10'>

                        <p className='text text--inter text--500'>Цвет:</p>

                        <div className='flex flex--column flex--g-10'>
                            {productStore.colors.map((filterItem, index) => {
                                return (
                                    <Checkbox
                                        key={index + '_color'}
                                        checked={isChecked('colors', filterItem)}
                                        onClick={handleFilterClick('colors', filterItem)}
                                    >
                                        {filterItem.name}
                                    </Checkbox>)
                            })}
                        </div>

                    </div> */}

                </div>

            </div>

            <div className='catalog-modal__footer'>

                <SelectSmall
                    onChange={handleSortSelect}
                    placeholder={sortText}
                    options={[{ id: '1', name: 'Сначала дешевле' }, { id: '2', name: 'Сначала дороже' }]}
                    value={null}
                />

                <div className='catalog-modal__footer-btns catalog-modal__footer-btns--160'>
                    <button onClick={handleFilterSubmit} className='btn btn--sm'>ПРИМЕНИТЬ</button>
                    <button onClick={handleFilterDelete} className='btn btn--sm btn--light'>СБРОСИТЬ</button>
                </div>

            </div>
        </div>
    )
})