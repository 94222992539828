import axios from "axios"

export const getDeliveryPoints = async (cityCode = 270, type = 'PVZ', weight_in_kilos) => {
    try {
        const response = await axios.get(`https://api.ddxmerch.ru/odrow_cdek/cdek_deliverypoints.php?city_code=${cityCode}&type=${type}&weight_max=${10}`)
        // const response = await axios.get(`https://api.ddxmerch.ru/odrow_cdek/cdek_deliverypoints.php?weight_max=${weight_in_kilos}&city_code=${cityCode}&type=${type}`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}