import { NavLink } from 'react-router-dom'
import { Logo } from 'src/components'
import { BasketIcon, MenuIcon, ProfileIcon, SearchIcon } from '../../common'

export const TabletMobileHeader = ({ handleCategoryClick, handleMenuClick, isMenuOpened, basketItemCount, handleProfileClick }) => {
    const toggleMenu = () => handleMenuClick(isOpened => !isOpened)
    return (
        <div className='header__container'>
            <NavLink to='/'>
                <Logo />
            </NavLink>

            <div className='header__links'>

                {/* скрывать кнопки в шапке при открытом меню */}
                {/* {!isMenuOpened && <> */}
                    <button onClick={handleCategoryClick('search')}>
                        <SearchIcon />
                    </button>

                    <button onClick={() => {}}>
                        <BasketIcon itemCount={basketItemCount} />
                    </button>

                    {/* <button onClick={handleProfileClick}>
                        <ProfileIcon />
                    </button> */}
                {/* </>} */}

                <button onClick={toggleMenu}>
                    <MenuIcon isOpened={isMenuOpened} />
                </button>

            </div>
        </div>
    )
}