import './SelectSlide.scss'

export const SelectSlide = ({ value, onChange, options = [], ...props }) => {
    const isActive = (id) => id === value?.id
    const slideBtnClassNames = (id) => isActive(id)
    ? 'select-slide__btn select-slide__btn--active'
    : 'select-slide__btn'
    
    // const handleChange = (value) => () => onChange(value)
    const handleChange = (value) => () => onChange({ target: { value, name: props.name, id: props.id } })

    return (
        <div className='select-slide'>
            {options.map((option, index) => {
                return (
                    <div
                        key={index}
                        className={slideBtnClassNames(option.id)}
                        onClick={handleChange(option)}
                    >{option.name}</div>
                )
            })}
        </div>
    )
}