import { addSeparators } from 'src/utils'
import './style.scss'

export const Price = ({ newPrice, oldPrice, onSale, className = '' }) => {
    return (
        <div className={'price ' + className}>
            <div className='price__new'>{addSeparators(parseInt(newPrice))} ₽</div>
            {onSale &&
                <div className='price__old'>{addSeparators(parseInt(oldPrice))} ₽</div>}
        </div>
    )
}