import { gql } from "@apollo/client"

export const UPDATE_CART = gql`
    mutation UPDATE_CART($input: UpdateItemQuantitiesInput!) {
        updateItemQuantities(input: $input) {
            items {
                key
                quantity
                total
            }
        }
    }
`


  