import { Container, Path } from 'src/components'
import './ForCustomers.scss'
import { ContactUs, Navigation } from './common'

import { useEffect, useState } from 'react'
import { Contacts, Delivery, FAQ, Loyalty, Politics, Refund } from './components'
import qs from 'qs'
import { Overlay } from 'src/layout'
import { QuestionSentModal } from './modals'

const forCustomersPath = [{ name: 'Главная', path: '/' }, { name: 'Покупателям', path: '/for-customers' }]
const categories = ['contacts', 'delivery', 'refund', 'faq']

export const ForCustomers = () => {
    const [page, setPage] = useState('')

    const [isQuestionSentModal, setQuestionSentModal] = useState(false)
    const closeQuestionSentModal = () => setQuestionSentModal(false)
    const openQuestionSentModal = () => setQuestionSentModal(true)
    
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    useEffect(() => {
        if (categories.includes(queryParams.category)) {
            setPage(queryParams.category)
        } else {
            window.history.replaceState({}, '', `?category=contacts`)
            setPage('contacts')
        }
    }, [queryParams.category])

    
    return (
        <Container>
            {/* <Path path={forCustomersPath} /> */}

            {isQuestionSentModal && 
                <Overlay center onClick={closeQuestionSentModal}>
                    <QuestionSentModal onClose={closeQuestionSentModal} />
                </Overlay>}

            <h2 className='title mt--50-20'>Покупателям</h2>

            <div className='for-customers mt--60-30'>
                <Navigation active={page} onClick={setPage} />

                {page === 'contacts' &&
                    <Contacts />}

                {page === 'delivery' &&
                    <Delivery />}

                {page === 'refund' &&
                    <Refund />}

                {/* {page === 'loyalty' &&
                    <Loyalty />} */}

                {page === 'faq' &&
                    <FAQ />}

                {/* {page === 'politics' &&
                    <Politics />} */}

                <ContactUs openQuestionSentModal={openQuestionSentModal} />
            </div>
        </Container>
    )
}