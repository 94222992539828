import { makeAutoObservable, toJS } from 'mobx'
import client from 'src/api/apollo-client'
import { GET_PRODUCTS, GET_PRODUCT_BY_ID } from 'src/api/queries'

const templateColors = [
    { id: 0, name: 'Красный' },
    { id: 1, name: 'Серый' },
    { id: 2, name: 'Черный' }, 
    { id: 3, name: 'Оранжевый' },
    { id: 4, name: 'Синий' },
]

const templateSizes = [
    { id: 0, name: 'XS' },
    { id: 1, name: 'S' },
    { id: 2, name: 'M' },
    { id: 3, name: 'L' },
    { id: 4, name: 'XL' },
]

const noFilters = {
    minPrice: null,
    maxPrice: null,
    colors: [],
    sizes: [],
    genders: [],
    recommendations: [],
    collections: [],
    clothes: [],
    accessories: []
}

class ProductStore {
    filter = {
        all: false,
        filters: {
            colors: [],
            sizes: [],

            minPrice: null,
            maxPrice: null,

            genders: [],
            recommendations: [],
            collections: [],
            clothes: [],
            accessories: [],

            sortBy: null,
            sortOrder: null,

            limit: 9,
            page: 1,
        },
    }

    colors = [...templateColors]
    sizes = [...templateSizes]

    prices = [0, 10000]
    genders = ['МУЖСКОЕ', 'ЖЕНСКОЕ']

    // ПОЛУЧАТЬ СПИСКИ ИЗ WP
    collections_temp = ['STREETWEAR', 'SPORTSWEAR']
    clothes_temp = ['Верхняя одежка', 'Свитшоты и худи', 'Футболки', 'Брюки', 'Леггинсы', 'Велосипедки']
    accessories_temp = ['Носки', 'Сумки', 'Рюкзаки', 'Панамы', 'Бейсболки', 'Носки', 'Шапки']

    products = []

    mens_catalog = {}
    womens_catalog = {}
    shared_catalog = null

    collection_catalog = []

    product = null
    product_categories = null

    // recentlyWatched = 
    // recommended = templateProducts

    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }


    *getProducts() {
        try {
            this.loading = true
            const getResponse = yield client.query({
                query: GET_PRODUCTS,
                variables: {
                    notifyOnNetworkStatusChange: true,
                },
                // fetchPolicy: 'network-only',
            })

            // const filters = this.filter.filters.collections
            // rawFilter = rawFilter.filter(prod => {
            //     return prod.productCategories.nodes.some(node => filters.includes(node.name.toUpperCase()))
            // })

            this.products = getResponse.data.products.nodes

            this.products = this.products.map(product => {
                let collection = null

                product.productCategories.nodes.forEach(c => {
                    if (this.collections_temp.includes(c.name)) {
                        collection = c.name
                    }
                })

                return { ...product, collection }
            })
            // this.products = getResponse.data.products.nodes.map(product => {
            //     const updatedProduct = { ...product }
            //     product.productCategories.nodes.forEach(c => {
            //         if (this.collections_temp.includes(c.name)) {
            //             updatedProduct.collection = c.name
            //         }
            //     })
            //     return updatedProduct
            // })

            // console.log(getResponse.data.products.nodes) // delete

            const mens_temp = []
            const womens_temp = []
            let mens_on_sale_temp = false
            let womens_on_sale_temp = false

            // поиск мужских и женских категорий
            getResponse.data.products.nodes.forEach((node) => {
                if (node.productCategories.nodes?.find(c => c.name.toLowerCase() === 'мужское') !== -1) {
                    if (node.onSale) mens_on_sale_temp = true

                    node.productCategories.nodes?.forEach(c => {
                        if (c.name.toLowerCase() !== 'женское' && c.name.toLowerCase() !== 'мужское') mens_temp.push(c.name)
                    })
                }
    
                if (node.productCategories.nodes?.find(c => c.name.toLowerCase() === 'женское') !== -1) {
                    if (node.onSale) womens_on_sale_temp = true

                    node.productCategories.nodes?.forEach(c => {
                        if (c.name.toLowerCase() !== 'женское' && c.name.toLowerCase() !== 'мужское') womens_temp.push(c.name)
                    })
                }
            })

            const mens_unique_temp = Array.from(new Set(mens_temp))
            const womens_unique_temp = Array.from(new Set(womens_temp))

            const mens_catalog_temp = {
                recommendations: ['НОВИНКИ', 'ПОПУЛЯРНОЕ'],
                collections: [],
                clothes: [],
                accessories: [],
            }
            const womens_catalog_temp = {
                recommendations: ['НОВИНКИ', 'ПОПУЛЯРНОЕ'],
                collections: [],
                clothes: [],
                accessories: [],
            }

            const collection_catalog_temp = []

            if (mens_on_sale_temp) mens_catalog_temp.recommendations.push('SALE')
            mens_unique_temp.forEach((category) => {
                this.collections_temp.forEach((collection) => {
                    if (category === collection) {
                        mens_catalog_temp.collections.push(category)
                        collection_catalog_temp.push(category)
                        // console.log(category)
                    }
                })

                this.clothes_temp.forEach((cloth) => {
                    if (category === cloth) mens_catalog_temp.clothes.push(category)
                })

                this.accessories_temp.forEach((accessory) => {
                    if (category === accessory) mens_catalog_temp.accessories.push(category)
                })
            })

            if (womens_on_sale_temp) womens_catalog_temp.recommendations.push('SALE')
            womens_unique_temp.forEach((category) => {
                this.collections_temp.forEach((collection) => {
                    if (category === collection) {
                        womens_catalog_temp.collections.push(category)
                        collection_catalog_temp.push(category)
                    }
                })

                this.clothes_temp.forEach((cloth) => {
                    if (category === cloth) womens_catalog_temp.clothes.push(category)
                })

                this.accessories_temp.forEach((accessory) => {
                    if (category === accessory) womens_catalog_temp.accessories.push(category)
                })
            })
            
            this.mens_catalog = mens_catalog_temp
            this.womens_catalog = womens_catalog_temp
            this.collection_catalog = Array.from(new Set(collection_catalog_temp))

            this.shared_catalog = {
                recommendations: Array.from(new Set([...mens_catalog_temp.recommendations, ...womens_catalog_temp.recommendations])),
                collections: Array.from(new Set([...mens_catalog_temp.collections, ...womens_catalog_temp.collections])),
                clothes: Array.from(new Set([...mens_catalog_temp.clothes, ...womens_catalog_temp.clothes])),
                accessories: Array.from(new Set([...mens_catalog_temp.accessories, ...womens_catalog_temp.accessories])),
            }
            
            // console.log('mens_catalog_', mens_catalog_temp)
            // console.log('womens_catalog_', womens_catalog_temp)
            // console.log('shared_catalog_', this.shared_catalog)
            // console.log('collection_catalog_', this.collection_catalog)
        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    getProductFromStorage(id) {
        return [...this.products].find(product => {
            return product.databaseId === id
        })
    }

    *getProduct(id) {
        try {
            this.loading = true
            const getResponse = yield client.query({
                query: GET_PRODUCT_BY_ID,
                variables: {
                    id,
                    notifyOnNetworkStatusChange: true,
                },
                // fetchPolicy: 'network-only',
            })

            // console.log(getResponse.data.product)
            // добавляем id в недавно просмотренные
            const recentlyWatchedIds = JSON.parse(localStorage.getItem('ddxmerch-recently-watched'))?.map(id => +id) || []
            localStorage.setItem('ddxmerch-recently-watched', JSON.stringify(Array.from(new Set([id, ...recentlyWatchedIds]))))

            this.product = getResponse.data.product
            this.product.productCategories.nodes.forEach(c => {
                if (this.collections_temp.includes(c.name)) {
                    this.product.collection = c.name
                }
            })
            this.product_categories = getResponse.data.product.productCategories.nodes
            // console.log(getResponse.data)
            // this.products = getResponse.data.products.nodes
        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    get filtered_products () {
        let rawFilter = this.products
        if (this.filter.filters.minPrice) rawFilter = rawFilter.filter(prod => parseInt(prod.price) >= this.filter.filters.minPrice)
        if (this.filter.filters.maxPrice) rawFilter = rawFilter.filter(prod => parseInt(prod.price) <= this.filter.filters.maxPrice)

        if (this.filter.filters.genders.length) {
            // if (this.filter.filters.genders?.find(g => g ==='МУЖСКОЕ') && this.filter.filters.genders?.find(g => g === 'ЖЕНСКОЕ')) {
            //     //
            // } else if (this.filter.filters.genders.find(g => g ==='МУЖСКОЕ')) {
            //     // filter only mens
            //     rawFilter = rawFilter.filter(prod => prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'МУЖСКОЕ'))
            // } else if (this.filter.filters.genders.find(g => g ==='ЖЕНСКОЕ')) {
            //     // filter only womens
            //     rawFilter = rawFilter.filter(prod => prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'ЖЕНСКОЕ'))
            // }

            const filters = this.filter.filters.genders
            rawFilter = rawFilter.filter(prod => {
                return prod.productCategories.nodes.some(node => filters.includes(node.name.toUpperCase()))
            })
        }
        
        if (this.filter.filters.recommendations.length) {
            const isOnlySaleFilter =
                this.filter.filters.recommendations.length === 1 &&
                this.filter.filters.recommendations.find(r => r === 'SALE')

            if (isOnlySaleFilter) {
                rawFilter = rawFilter.filter(prod => prod.onSale)
            }

            // const filters = this.filter.filters.recommendations
            // rawFilter = rawFilter.filter(prod => {
            //     return prod.productCategories.nodes.some(node => filters.includes(node.name.toUpperCase()))
            // })
        }

        if (this.filter.filters.collections.length) {
            // if (this.filter.filters.collections.find(c => c === 'STREETWEAR') && this.filter.filters.collections.find(c => c === 'SPORTSWEAR')) {
            //     rawFilter = rawFilter.filter(prod => {
            //         return prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'STREETWEAR') ||
            //         prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'SPORTSWEAR')
            //     })
            // } else if (this.filter.filters.collections.find(c => c === 'STREETWEAR')) {
            //     rawFilter = rawFilter.filter(prod => prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'STREETWEAR'))
            // } else if (this.filter.filters.collections.find(c => c === 'SPORTSWEAR')) {
            //     rawFilter = rawFilter.filter(prod => prod.productCategories.nodes.find(node => node.name.toUpperCase() === 'SPORTSWEAR'))
            // }

            const filters = this.filter.filters.collections
            rawFilter = rawFilter.filter(prod => {
                return prod.productCategories.nodes.some(node => filters.includes(node.name.toUpperCase()))
            })
        }

        if (this.filter.filters.clothes.length || this.filter.filters.accessories.length) {
            const filters = [...this.filter.filters.clothes, ...this.filter.filters.accessories]
            rawFilter = rawFilter.filter(prod => {
                return prod.productCategories.nodes.some(node => filters.includes(node.name))
            })
        }

        if (this.filter.filters.sortBy === 'price' && this.filter.filters.sortOrder) {
            if (this.filter.filters.sortOrder === 'asc') {
                rawFilter = [...rawFilter].sort((a, b) => parseInt(a.price) - parseInt(b.price))
            } else if (this.filter.filters.sortOrder === 'desc') {
                rawFilter = [...rawFilter].sort((a, b) => parseInt(b.price) - parseInt(a.price))
            }
        }

        const page = this.filter.filters.page
        const limit = this.filter.filters.limit
        let pages = 1

        if (page && limit) {
            const pageOffset = (page * limit) - limit
            // console.log(limit, pageOffset, page, pageOffset + limit)
    
            const productsQuantity = rawFilter.length
            pages = Math.ceil(productsQuantity / limit)
            rawFilter = rawFilter.slice(pageOffset, pageOffset + limit)
        }

        return { data: rawFilter, pages }
    }

    get catalogBreadCrumbs() {
        const result = []
        const filters = this.filter.filters

        if (filters.genders.length === 1) {
            result.push({ name: 'gender', value: filters.genders[0].toUpperCase() })
        }

        if (filters.collections.length === 1) {
            result.push({ name: 'collection', value: filters.collections[0] })
        }

        if ([...filters.clothes, ...filters.accessories].length === 1) {

            if (filters.clothes.length === 1) {
                result.push({ name: 'cloth', value: filters.clothes[0] })
            }

            if (filters.accessories.length === 1) {
                result.push({ name: 'accessory', value: filters.accessories[0] })
            }

        }
        
        return result
    }

    get productBreadCrumbs() {
        if (!this.product_categories) {
            return []
        }

        const result = []

        const productGenders = this.product_categories.filter(category => this.genders.includes(category.name.toUpperCase())).map(c => c.name)
        const productCollections = this.product_categories.filter(category => this.collections_temp.includes(category.name.toUpperCase())).map(c => c.name)

        if (productGenders.length === 1) {
            result.push({ name: 'gender', value: productGenders[0].toUpperCase() })
        }

        if (productCollections.length === 1) {
            result.push({ name: 'collection', value: productCollections[0] })
        }

        return result
    }

    get recommended() {
        const filters = this.shared_catalog?.clothes
        return this.products.filter(prod => {
            return prod.productCategories.nodes.some(node => filters.includes(node.name))
        })
    }

    get recently_watched() {
        const recentlyWatchedIds = JSON.parse(localStorage.getItem('ddxmerch-recently-watched')) || []
        const recentlyWatched = recentlyWatchedIds.map(r => {
            return this.products.find(productItem => productItem.databaseId === +r)  
        }).filter(r => r)
        return recentlyWatched
    }

    searchByName(searchString) {
        return this.products.filter((product) => product.name.toLowerCase().includes(searchString.toLowerCase()))
    }

    setFilters(filters) {
        this.filter.filters = filters
    }

    deleteFilters(filters) {
        if (filters) {
            filters.forEach(filterType => {
                this.filter.filters[filterType] = []
            })
        } else {
            this.filter.filters = noFilters
        }
    }

    handlePageChange(page) {
        this.filter.filters = {
            ...this.filter.filters,
            page,
        }
    }

    handleSortChange(sortBy, sortOrder) {
        // console.log(sortBy, sortOrder)
        this.filter.filters = {
            ...this.filter.filters,
            sortBy,
            sortOrder,
        }
    }

    handleFilterChange(filterType, filterValue) {
        // console.log(filterType, filterValue)
        const isChecked = this.filter.filters[filterType].find(filterItem => filterItem === filterValue)

        if (isChecked) {
            this.filter.filters[filterType] = this.filter.filters[filterType].filter(filterItem => filterItem !== filterValue)
        } else {
            this.filter.filters[filterType].push(filterValue)
        }
        // console.log(toJS(isChecked), toJS(filterValue), toJS(this.filter.filters))
    }

    handleFilterPrice(minPrice, maxPrice) {
        this.filter.filters.minPrice = minPrice
        this.filter.filters.maxPrice = maxPrice
    }

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default ProductStore