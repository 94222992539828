// import style from '../Select.module.css'

export const SelectOption = ({ children, active, disabled, baseClassName, ...props }) => {
    const optionClassNames = baseClassName + '__option'
        + (active ? baseClassName + '__option--active' : '')
        + (disabled ? baseClassName + '__option--disabled' : '')
        
    return (
        <div
            {...props}
            className={optionClassNames}
        >
            {children}
        </div>
    )
}
