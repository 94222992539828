export const PaginationItem = ({ selected, placeholder, children, ...props }) => {
    const itemClasses = 'pagination__item'
        + (selected ? ' pagination__item--selected' : '')
        + (placeholder ? ' pagination__item--placeholder' : '')

    return (
        <div {...props} className={itemClasses}>
            {children}
        </div>
    )
}
