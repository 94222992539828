import '../styles/AboutBlock.scss'
import aboutImage from '../images/about.png'
import { NavLink } from 'react-router-dom'

export const AboutBlockSmall = () => {
    return (
        <div className='container container--sm'>
            <div className='about-block mt--100-60'>
                <div className='about-block__info'>
                    <h2 className='title title--light'>
                        О БРЕНДЕ
                    </h2>

                    <p className='about-block__text text text--16-14 text--white'>
                        DDX Fitness – первая в России сеть фитнес-клубов, работающая по модели Luxury Low Cost. Это не классический фитнес клуб, а велнесс-пространство нового формата с удобной системой оплаты и facilities высочайших стандартов.
                        <span> В настоящее время это 35 оснащенных первоклассным оборудованием спортивных клубов, и более 15 новых площадок готовятся к открытию в ближайшее время.</span>
                    </p>

                    <NavLink to='/about'>
                        <button className='about-block__btn'>
                            ПОДРОБНЕЕ
                        </button>
                    </NavLink>
                </div>

                <img className='about-block__img' src={aboutImage} alt='about' />
            </div>
        </div>
    )
}