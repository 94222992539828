// import { useState } from 'react'
import './RadioButton.scss'

export const RadioButton = ({ value, checked, onChange, children }) => {
    const radioBtnClassNames = checked ? 'radio-btn radio-btn--checked' : 'radio-btn'

    return (
        <div className={radioBtnClassNames}>
            <label>
                <input
                    type='radio'
                    value={value}
                    onChange={onChange}
                    checked={false}
                />
		        {children}
            </label>
        </div>
    )
}