import { gql } from "@apollo/client"

export const ADD_TO_CART = gql`
  mutation ADD_TO_CART($input: AddToCartInput!) {
    addToCart(input: $input) {
      cart {
        contents {
          nodes {
            quantity
            product {
              node {
                ... on VariableProduct {
                  name
                  databaseId
                }
              }
            }
          }
        }
      }
    }
  }
`
