// import React, { useState } from 'react'
import './OrdersMobile.scss'
import { OrderInfo, OrderList } from './components'

export const OrdersMobile = ({ orders, showOrder, hideOrder, order }) => {
    // const [order, setOrder] = useState(null)
    // const selectOrder = (order) => () => setOrder(order)
    // const deleteOrder = () => setOrder(null)

    return (
        order 
            ? <OrderInfo deleteOrder={hideOrder} order={order} /> 
            : <OrderList selectOrder={showOrder} orders={orders} />
            
            
    )
}