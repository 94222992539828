import { makeAutoObservable, toJS } from 'mobx'

import client from 'src/api/apollo-client'
import { ADD_TO_CART, CLEAR_CART, UPDATE_CART } from 'src/api/mutations'
import { GET_CART } from 'src/api/queries'
import { v4 } from 'uuid'

class BasketStore {
    warehouses = [
        { id: 9, name: 'DDX Авиапарк', address: 'г. Москва, Ходынский бульвар, д. 4, ТРЦ "Авиапарк", 3 этаж', coordinates: [55.797999, 37.535028] },
        { id: 10, name: 'DDX Мытищи Красный Кит', address: 'г. Мытищи, Шараповский проезд д.2, ТРК "Красный Кит", 3 этаж', coordinates: [55.917774, 37.758658] },
        { id: 7, name: 'DDX Красногорск', address: 'г. Красногорск, ул. Ленина, д. 2, ТЦ "Красный Кит", 4 этаж', coordinates: [55.819846, 37.319980] },
    ]
    
    basket = null
    newPrice = 0

    __deliveryPrice = null

    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    *getBasket() {
        try {
            this.loading = true
            const getResponse = yield client.query({
                query: GET_CART,
                variables: {
                    notifyOnNetworkStatusChange: true,
                },
                fetchPolicy: 'network-only',
            })

            // console.log(getResponse.data.cart) // delete
            this.basket = getResponse.data.cart.contents.nodes
            this.newPrice = parseFloat(getResponse.data.cart.total)

            /////////////////////////////////////////////////////////////////////////////
            // ПРОВЕРЯЕМ ДОСТУПНЫ ЛИ ВСЕ ДОБАВЛЕННЫЕ В КОРЗИНУ ТОВАРЫ
            let shouldUpadateBasketItems = false
            const updatedCart = this.basket.map(bi => {
                // console.log(bi.key, ' qt: ', bi.quantity, ' availble: ', bi.variation.node.stockQuantity)
                if (bi.quantity > bi.variation.node.stockQuantity) {
                    shouldUpadateBasketItems = true
                    return { key: bi.key, quantity: bi.variation.node.stockQuantity }
                }
                return { key: bi.key, quantity: bi.quantity }
            })

            if (shouldUpadateBasketItems) {
                const updateResponse = yield client.mutate({
                    mutation: UPDATE_CART,
                    variables: {
                        input: {
                            clientMutationId: v4(),
                            items: updatedCart
                        },
                    },
                    // fetchPolicy: 'network-only',
                })
    
                // console.log(updateResponse)
    
                const getResponse = yield client.query({
                    query: GET_CART,
                    variables: {
                        notifyOnNetworkStatusChange: true,
                    },
                    fetchPolicy: 'network-only',
                })
    
                // console.log(getResponse.data.cart.contents.nodes) // delete
                this.basket = getResponse.data.cart.contents.nodes
                this.newPrice = parseFloat(getResponse.data.cart.total)
            }

        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    *deleteBasket() {
        try {
            this.loading = true
            const deleteResponse = yield client.mutate({
                mutation: CLEAR_CART,
                variables: {
                    input: {
                        clientMutationId: v4(),
                        all: true,
                    },
                },
            })

            // console.log(deleteResponse) // delete
            this.basket = []
        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    *addToBasket(productId, variationId, quantity) {
        // console.log(productId, variationId, quantity, 'add to basket')
        if (!productId || !variationId || !quantity) return

        try {
            this.loading = true
            const addResponse = yield client.mutate({
                mutation: ADD_TO_CART,
                variables: {
                    input: {
                        productId,
                        variationId,
                        quantity,
                        clientMutationId: v4(),
                    }
                }
            })
            // console.log(addResponse)

            const getResponse = yield client.query({
                query: GET_CART,
                variables: {
                    notifyOnNetworkStatusChange: true,
                },
                fetchPolicy: 'network-only',
            })

            // console.log(getResponse.data.cart.contents.nodes) // delete
            this.basket = getResponse.data.cart.contents.nodes
            this.newPrice = parseFloat(getResponse.data.cart.total)
        } catch (err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    *deleteFromBasket(id) {
        if (!id) return

        try {
            this.loading = true

            const updatedCart = this.basket.map((cartItem) => {

                if (cartItem.variation.node.databaseId === id) {
                    return { key: cartItem.key, quantity: 0 }
                }
                return { key: cartItem.key, quantity: cartItem.quantity }
            })

            // console.log(updatedCart)

            const updateResponse = yield client.mutate({
                mutation: UPDATE_CART,
                variables: {
                    input: {
                        clientMutationId: v4(),
                        items: updatedCart
                    },
                },
            })

            // console.log(updateResponse)

            const getResponse = yield client.query({
                query: GET_CART,
                variables: {
                    notifyOnNetworkStatusChange: true,
                },
                fetchPolicy: 'network-only',
            })

            // console.log(getResponse.data.cart.contents.nodes) // delete
            this.basket = getResponse.data.cart.contents.nodes
            this.newPrice = parseFloat(getResponse.data.cart.total)
        } catch(err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    updateDeliveryPrice(price) {
        this.__deliveryPrice = price
    }
    
    get deliveryPrice() {
        return this.__deliveryPrice
    }

    get basketItems() {
        return this.basket?.map((productItem) => {
            return { 
                product_id: productItem.variation.node.databaseId,
                quantity: +productItem.quantity 
            }
        })
    }

    get basketItemsWithBaseId() {
        return this.basket?.map((productItem) => {
            return { 
                product_id: productItem.variation.node.databaseId,
                id: productItem.variation.node.parent.node.databaseId,
                quantity: +productItem.quantity 
            }
        })
    }

    get basketItemParams() {
        const packages = []
        this.basket.forEach((productItem) => {
            const quantity = +productItem.quantity
            const { weight, length, width, height } = productItem.variation.node
            
            for (let i = 0; i < quantity; i++) {
                packages.push({ weight: Number(weight), length: Number(length), width: Number(width), height: Number(height) })
            }
        })
        return packages
    }

    get basketTotalWeight() {
        return this.basketItemParams.reduce((sum, currentPackage) => {
            return sum + currentPackage.weight
        }, 0)
    }

    *updateBasket(id, quantity) {
        // console.log(id, quantity, 'update basket')
        if (!id || quantity === undefined) return
        
        try {
            this.loading = true
            const updatedCart = this.basket.map((cartItem) => {
                // console.log(toJS(cartItem.variation.node))
                if (cartItem.variation.node.databaseId === id) {
                    return { key: cartItem.key, quantity }
                }
                return { key: cartItem.key, quantity: cartItem.quantity }
            })

            const updateResponse = yield client.mutate({
                mutation: UPDATE_CART,
                variables: {
                    input: {
                        clientMutationId: v4(),
                        items: updatedCart
                    },
                },
                // fetchPolicy: 'network-only',
            })

            // console.log(updateResponse)

            const getResponse = yield client.query({
                query: GET_CART,
                variables: {
                    notifyOnNetworkStatusChange: true,
                },
                fetchPolicy: 'network-only',
            })

            // console.log(getResponse.data.cart.contents.nodes) // delete
            this.basket = getResponse.data.cart.contents.nodes
            this.newPrice = parseFloat(getResponse.data.cart.total)
        } catch(err) {
            console.log(err)
        } finally {
            this.loading = false
        }
    }

    isItemInCart(id) {
        if (this.basket) {
            const item = this.basket.find((cartItem) => id === cartItem.variation.node.databaseId)
            // console.log(id, toJS(item))
            return item?.quantity
        }
        return null
    }

    get basketItemCount() {
        if (this.basket) {
            return this.basket.reduce((sum, cur) => {
                return sum + cur.quantity
            }, 0)
        }
        return 0
    }

    get basketPriceWithoutDiscount() {
        if (this.basket) {
            return this.basket.reduce((sum, cur) => {
                return sum + (cur.quantity * parseFloat(cur.variation.node.regularPrice))
            }, 0)
        }
        return 0
    }

    setLoading(loading) {
        this.loading = loading
    }

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default BasketStore