export const MenuIcon = ({ isOpened, ...props }) => {
    return (
        <div className='header-icon-fill' {...props}>

            {isOpened ?

                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <rect x='7.41422' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41422 6)' />
                    <rect x='6' y='17.3137' width='16' height='2' rx='1' transform='rotate(-45 6 17.3137)' />
                </svg> :

                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <rect x='4' y='6' width='16' height='2' rx='1' />
                    <rect x='4' y='11' width='16' height='2' rx='1' />
                    <rect x='4' y='16' width='16' height='2' rx='1' />
                </svg>
            }
            
        </div>
    )
}