import { useContext, useEffect, useRef, useState } from 'react'
import { ColorModal, OrderModal, PriceModal, SizeModal } from './Modals'
import './CatalogSort.scss'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

// const selected = ['от 500 до 3500', 'Белый', 'XS']

export const CatalogSort = observer(() => {
    const { productStore } = useContext(AppContext)
    const [sortModal, setSortModal] = useState(false)
    
    const toggleSortModal = (modal) => {
        return () => {
            if (modal === sortModal) {
                setSortModal(false)
            } else {
                setSortModal(modal)
            }
        }
    }
    
    // close sort dropdown on side click
    const rootElement = useRef(null)
    useEffect(() => {
        const onClick = event => rootElement.current.contains(event.target) || setSortModal(false)
        if (sortModal) document.addEventListener('click', onClick)
        return () => { sortModal && document.removeEventListener('click', onClick) }
})

    const closeSortModal = () => setSortModal(false)

    const isPriceFilter = Boolean(productStore.filter.filters.minPrice || productStore.filter.filters.maxPrice)
    const minPriceFilter = productStore.filter.filters.minPrice
    const maxPriceFilter = productStore.filter.filters.maxPrice

    const isColorFilter = Boolean(productStore.filter.filters.colors.length)
    const colorFilter = productStore.filter.filters.colors

    const isSizeFilter = Boolean(productStore.filter.filters.sizes.length)
    const sizeFilter = productStore.filter.filters.sizes

    const handleSortClick = (sortBy, sortOrder) => () => {
        productStore.handleSortChange(sortBy, sortOrder)
        setSortModal(false)
    }
    const handleFilterClick = (filterType, filterValue) => () => productStore.handleFilterChange(filterType, filterValue)
    const handleFilterPrice = (minPrice, maxPrice) => productStore.handleFilterPrice(minPrice, maxPrice)
    const deletePriceFilter = () => handleFilterPrice(null, null)

    const isSort = productStore.filter.filters.sortBy && productStore.filter.filters.sortOrder
    const sortText = isSort && productStore.filter.filters.sortBy === 'price'
        ? productStore.filter.filters.sortOrder === 'asc'
            ? 'Сначала дешевле'
            : 'Сначала дороже'
        : 'Сортировать по'

    return (
        <div ref={rootElement} className='catalog-sort'>
            <div className='catalog-sort__container'>
                <div className='catalog-sort__params'>

                    {/* <div className='catalog-sort__param'>
                        <p onClick={toggleSortModal('price')}>Цена</p>
                        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M5 7L8 10L11 7' strokeWidth='2' strokeLinecap='round' />
                        </svg>

                        {sortModal === 'price' &&
                            <PriceModal
                                onSubmit={closeSortModal}
                                handleFilterPrice={handleFilterPrice}
                                // priceFilter={priceFilter}
                                minPriceFilter={minPriceFilter}
                                maxPriceFilter={maxPriceFilter}
                                prices={productStore.prices}
                            />}
                    </div> */}

                    {/* <div className='catalog-sort__param'>
                        <p onClick={toggleSortModal('size')}>Размер</p>
                        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M5 7L8 10L11 7' strokeWidth='2' strokeLinecap='round' />
                        </svg>

                        {sortModal === 'size' &&
                            <SizeModal
                                handleFilterClick={handleFilterClick}
                                sizeFilter={sizeFilter}
                                sizes={productStore.sizes}
                            />}
                    </div>

                    <div className='catalog-sort__param'>
                        <p onClick={toggleSortModal('color')}>Цвет</p>
                        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M5 7L8 10L11 7' strokeWidth='2' strokeLinecap='round' />
                        </svg>

                        {sortModal === 'color' &&
                            <ColorModal
                                handleFilterClick={handleFilterClick}
                                colorFilter={colorFilter}
                                colors={productStore.colors}
                            />}
                    </div> */}
                </div>

                <div className='catalog-sort__param catalog-sort__param--light'>
                    <p onClick={toggleSortModal('order')}>{sortText}</p>
                    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M5 7L8 10L11 7' strokeWidth='2' strokeLinecap='round' />
                    </svg>

                    {sortModal === 'order' &&
                        <OrderModal
                            sortBy={productStore.filter.filters.sortBy}
                            sort={productStore.filter.filters.sort}
                            handleSortClick={handleSortClick}
                        />}
                </div>
            </div>

            <div className='catalog-sort__divider'></div>

            <div className='catalog-sort__selected'>

                {isPriceFilter &&
                    <div key='0_price' className='catalog-sort__selected-item'>
                        <p>От&nbsp;{minPriceFilter || 0}&nbsp;до&nbsp;{maxPriceFilter || '-'}</p>
                        <svg onClick={deletePriceFilter} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M5 5L8 8M8 8L11 5M8 8L5 11M8 8L11 11' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                    </div>}

                {isColorFilter &&
                    colorFilter.map((filterItem, index) => {
                        return (
                            <div key={index + '_color'} className='catalog-sort__selected-item'>
                                <p>{filterItem.name}</p>
                                <svg onClick={handleFilterClick('colors', filterItem)} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M5 5L8 8M8 8L11 5M8 8L5 11M8 8L11 11' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>)})}

                {isSizeFilter &&
                    sizeFilter.map((filterItem, index) => {
                        return (
                            <div key={index + '_size'} className='catalog-sort__selected-item'>
                                <p>{filterItem.name}</p>
                                <svg onClick={handleFilterClick('sizes', filterItem)} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M5 5L8 8M8 8L11 5M8 8L5 11M8 8L11 11' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>)})}
            </div>
        </div>
    )
})