import React from 'react'
import './OrdersDesktop.scss'
import { ProductCardExtraSmall } from 'src/components'
import { NavLink } from 'react-router-dom'

export const OrdersDesktop = ({ orders, isPayButton }) => {
    return (
        <div className='orders-desktop flex flex--column flex--g-40'>
            <div className='orders-desktop__table'>
                <div className='orders-desktop__table-row orders-desktop__table-row--header'>
                    <div className='text'>Номер</div>
                    <div className='text'>Заказ</div>
                    <div className='text'>Дата заказа</div>
                    <div className='text'>Доставка</div>
                    <div className='text'>Сумма</div>

                    {isPayButton
                            ? <div className='text'></div>
                            : <div className='text'>Статус</div>}
                </div>

                {orders.map((order, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className='divider'></div>

                            <div className='orders-desktop__table-row'>
                                <div className='orders-desktop__order-id text'>{order.id}</div>
                                <div className='flex flex--column flex--g-20'>
                                    {order.items.map((item, index) => {
                                        return (
                                            <ProductCardExtraSmall product={item} key={index} index={index} />
                                        )
                                    })}
                                </div>
                                <div className='text'>{order.date}</div>
                                <div className='text'>
                                    <p className='text--dark'>{order.delivery.type}</p>
                                    <p>{order.delivery.address}</p>
                                </div>
                                <div className='text'>
                                    {order.newPrice && <p className='text--dark text--700'>{order.newPrice} ₽</p>}
                                    {order.oldPrice && <p className='text--line-th'>{order.oldPrice} ₽</p>}
                                </div>

                                {isPayButton
                                    ? <NavLink to={order.paymentUrl}><button className='btn btn--sm'>Оплатить</button></NavLink>
                                    : <div className='text'>{order.status}</div>}
                                
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>

            {!isPayButton &&
                <div>
                    <button className='btn'>Перейти в каталог</button>
                </div>}
        </div>
    )
}
