import { Path } from 'src/components'
import './Thanks.scss'
import qs from 'qs'
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { yandexEcommerce, ym } from 'src/analytics'

const thanksPath = [{ name: 'Главная', path: '/' }, { name: 'Корзина', path: '/basket' }]

export const Thanks = observer(() => {
    const { basketStore } = useContext(AppContext)
    const navigate = useNavigate()
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const orderId = queryParams.order
    
    useEffect(() => {
        // console.log(orderId)
        ym.Purchase()
        basketStore.deleteBasket()
        
        if (!orderId) {
            navigate('/')
        }

        if (orderId) {
            const orderItems = localStorage.getItem(`order-${orderId}`)
            
            if (orderItems) {
                yandexEcommerce.purchase(orderId, JSON.parse(orderItems))
                localStorage.removeItem(`order-${orderId}`)
            }
        }
    }, [])

    return (
        <div className='container thanks'>
            {/* <Path path={thanksPath} /> */}

            <div className='mt--50-20 thanks__container'>
                <h2 className='title'>Спасибо!</h2>
                <p className='mt--60-30 text text--20-14'>
                    Ваш заказ успешно оформлен. Мы свяжемся с вами в ближайшее время. Номер вашего заказа <span className='text--500'>№{orderId}.</span>
                </p>

                <Link to='/products'>
                    <button className='mt--60-30 btn'>Вернуться к покупкам!</button>
                </Link>
                
            </div>
        </div>
    )
})