import { useContext, useEffect, useRef, useState } from 'react'
import { getDeliveryPoints } from 'src/api/cdek/get-delivery-points'

import markerIcon from './images/marker.png'
import selectedMarkerIcon from './images/selectedMarker.png'

import './style.scss'
import { Select } from '../Select'
import { AppContext } from 'src'

const MapComponent = ({
    select,
    height,
    zoom = 10,
    points,
    selectedPoint,
    onPointClick
}) => {
    const mapRef = useRef(null)
    const mapInstance = useRef(null)
    const placemarks = useRef([])
    const bounds = useRef([])
    const [infoWindow, setInfoWindow] = useState(null)

    useEffect(() => {
        const ymaps = window.ymaps
        if (ymaps && mapRef.current && !mapInstance.current) {
            ymaps.ready(() => {

                const mapOptions = {
                    // center: [55.7558, 37.6176],
                    center: points[0].coordinates,
                    zoom: zoom,
                    behaviors: ['drag', 'dblClickZoom', 'multiTouch'],
                    controls: ['zoomControl']
                }
                mapInstance.current = new ymaps.Map(mapRef.current, mapOptions)

                mapInstance.current.behaviors.disable('scrollZoom')

                bounds.current = new ymaps.GeoObjectCollection()

                placemarks.current = points.map((point) => {
                    const placemark = new ymaps.Placemark(
                        point.coordinates,
                        {
                            hintContent: point.name,
                            // balloonContent: point.name
                            point,
                        },
                        {
                            preset: 'islands#blueIcon',
                            iconLayout: 'default#image',
                            iconImageHref: selectedPoint.id === point.id ? selectedMarkerIcon : markerIcon,
                            iconImageSize: [30, 39],
                            iconOffset: [0, -(39 / 2)],
                            // hintContent: 'test 123'
                        }
                    )
                    // placemark.current.id = point.id
                    // bounds.current.add(placemark)

                    placemark.events.add('click', () => {
                        // onPointClick(point)
                        // onSelectPointFromMap(point)
                        setInfoWindow({
                            point,
                            coordinates: point.coordinates,
                        })
                    })
                    // mapInstance.current.geoObjects.add(placemark)
                    // placemark.balloon.open()
                    return placemark
                })

                const clusterer = new ymaps.Clusterer({
                    clusterDisableClickZoom: true, // Отключает изменение зума при клике на кластер
                    clusterOpenBalloonOnClick: false, // Открывает балун при клике на кластер
                    // clusterIconLayout: customClusterIconLayout,
                    preset: 'islands#darkOrangeClusterIcons'
                })
                
                // Добавьте placemarks в кластеризатор
                clusterer.add(placemarks.current)
                clusterer.events.add('click', (e) => {
                    const cluster = e.get('target')
                    // зум если в кластере есть геообъекты
                    const canZoom = Boolean(cluster.properties?._data?.geoObjects)
                    if (canZoom) {
                        const map = cluster.getMap()
                        map.setCenter(cluster.geometry.getCoordinates(), map.getZoom() + 1) // Увеличьте зум на 1
                    }
                })

                mapInstance.current.geoObjects.add(clusterer)

                

                if (bounds.current.getLength()) {
                    console.log(bounds.current.getLength(), '______')
                    mapInstance.current.setBounds(bounds.current.getBounds(), { checkZoomRange: true })
                }

                return () => {
                    placemarks.current.forEach((placemark) => {
                        placemark.events.remove('click')
                        mapInstance.current.geoObjects.remove(placemark)
                    })
                }
            })
        }
    }, [points, onPointClick])

    useEffect(() => {
        if (selectedPoint && mapInstance.current && !infoWindow) {
            mapInstance.current.setCenter(selectedPoint.coordinates, 15)
            
            // Обновите иконку для предыдущей выбранной точки (если таковая имеется)
            if (placemarks.current) {
                placemarks.current.forEach((placemark) => {
                    const pointInPlacemark = placemark.properties.get('point')
                    const isCurrentlySelected = pointInPlacemark.id === selectedPoint.id
                    placemark.options.set('iconImageHref', isCurrentlySelected ? selectedMarkerIcon : markerIcon)
                })
            }
        }

        
    }, [selectedPoint, infoWindow])

    // close menu on side click
    const containerElement = useRef(null)
    useEffect(() => {
        const onClick = event => containerElement.current.contains(event.target) || setInfoWindow({})
        if (infoWindow?.point) document.addEventListener('click', onClick)
        return () => { infoWindow && document.removeEventListener('click', onClick) }
    })

    const handleSelectButton = (event) => {
        event.preventDefault()
        if (infoWindow && infoWindow.point) {
            onPointClick(infoWindow.point)
            infoWindow.point?.infoWindow?.close()
            setInfoWindow(null)
        }
    }

    const handleCloseInfo = (event) => {
        event.preventDefault()
        if (infoWindow && infoWindow.point) {
            // onSelectPointFromMap(null)
            infoWindow.point?.infoWindow?.close()
            setInfoWindow({})
        }
    }

    return (
        <div ref={containerElement} className='select-on-ym' style={{ position: 'relative', width: '100%', height: height }}>
            <div className='select-on-ym__map' ref={mapRef} />
            {infoWindow && infoWindow.point && (
                <div className='select-on-ym__popup'>
                    <button className='select-on-ym__popup-btn' onClick={handleCloseInfo}>
                        <svg onClick={handleCloseInfo} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="7.41406" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41406 6)" fill="#676785" />
                            <rect x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="#676785" />
                        </svg>
                    </button>

                    <div className='select-on-ym__popup-body flex flex--column flex--g-5'>
                        <p className='title title--sm'>{infoWindow.point.name}</p>
                        <p className='text'><b>Адрес:</b> <br />{infoWindow.point.address}</p>
                        {infoWindow.point?.address_comment &&
                            <p className='text'><b>Как добраться:</b> <br />{infoWindow.point.address_comment}</p>}
                        {infoWindow.point?.work_time &&
                            <p className='text'><b>Время работы:</b> <br />{infoWindow.point.work_time}</p>}
                        {infoWindow.point?.phones && infoWindow.point?.phones[0]?.number &&
                            <p className='text'><b>Телефон:</b> <br />{infoWindow.point.phones[0]?.number}</p>}
                    </div>

                    {select &&
                        <button className='btn' onClick={handleSelectButton}>Выбрать</button>}
                </div>
            )}
        </div>
    )
}

const Dropdown = ({ selectProps, formik, error, points, onChange, selectedPoint = {}, placeholder }) => {
    // const handleSelectChange = (value) => {
    //     const selectedPointId = value?.id
    //     const selectedPoint = points.find((point) => point.id === selectedPointId)
    //     onSelect(selectedPoint)
    // }
    const [searchStr, setSearchStr] = useState('')

    return (
        <div className='mb--20'>
            <Select
                search
                searchString={searchStr}
                setSearchString={setSearchStr}
                // formik={formik}
                error={error}
                onChange={onChange}
                value={selectedPoint}
                options={points}
                placeholder={placeholder}
                {...selectProps}
            />
        </div>
    )
}

export const SelectOnYandexMap = ({ 
    formik,
    error,
    regionId = 270,
    type = 'PVZ',
    weight,
    placeholder,
    value,
    onChange,
    select,
    height = '500px',
    zoom = 10,
    ...props
}) => {
    const [loading, setLoading] = useState(false)
    const [points, setPoints] = useState([])
    const { basketStore } = useContext(AppContext)
    // const [selectedPoint, setSelectedPoint] = useState({})

    useEffect(() => {
        if (type === 'PVZ' || type === 'POSTAMAT') {
            setLoading(true)
            getDeliveryPoints(regionId, type, weight).then(res => {
                const mapRegions = (data = []) => data.map(pvz => ({ 
                    ...pvz,
                    id: pvz.code,
                    name: pvz.name,
                    coordinates: [pvz.location.latitude, pvz.location.longitude],
                    postcode: pvz.location.postal_code, 
                    address: pvz.location.address, 
                    address_full: pvz.location.address_full, }
                ))

                setPoints(mapRegions(res) || [])
                setLoading(false)
            })
        }

        if (type === 'PICKUP') {
            setPoints(basketStore.warehouses || [])
        }

    }, [])

    const handlePointClick = (point) => {
        if (formik) {
            onChange({ target: { value: point, name: props.name, id: props.id } })
        } else {
            onChange(point)
        }
    }

    // const handleDropdownSelect = (selectedPoint) => {
    //     if (formik) {
    //         onChange({ target: { value: selectedPoint, name: props.name, id: props.id } })
    //     } else {
    //         onChange(selectedPoint)
    //     }
    // }

    // const handleSelectPointFromMap = (selectedPoint) => {
    //     if (formik) {
    //         onChange({ target: { value: selectedPoint, name: props.name, id: props.id } })
    //     } else {
    //         onChange(selectedPoint)
    //     }
    // }

    if (!points.length || loading) return <div className='select-on-ym__map placeholder' data-attr='placeholder' style={{ height }}></div>

    return (
        <div className='flex flex--column flex--g-20'>
            {select &&
                <Dropdown
                    selectProps={props}
                    formik={formik}
                    error={error}
                    points={points}
                    onChange={handlePointClick}
                    selectedPoint={value}
                    placeholder={placeholder}
                />}

            <MapComponent
                select={select}
                height={height}
                zoom={zoom}
                points={points}
                selectedPoint={value}
                onPointClick={handlePointClick}
                // onSelectPointFromMap={handleSelectPointFromMap}
            />
        </div>
    )
}