import axios from "axios"

export const calculateTariffs = async (regionCode = 44, address = '', products = []) => {
    try {
        // const response = await axios.post('https://api.ddxmerch.ru/odrow_cdek/cdek_calculator_tarifflist.php', {
        const response = await axios.post('https://api.ddxmerch.ru/wp-json/wc/custom/tariffs', {
        
            type: 1,
            // "date": "2020-11-03T11:49:32+0700",
            currency: 1,
            lang: "rus",
            from_location: {
                code: 44
            },
            to_location: {
                code: regionCode,
                address,
            },
            products,
        })
        // console.log(response.data.tariff_codes)
        return response.data.tariff_codes
    } catch (err) {
        console.log(err)
    }
}


// '138': 'Пункт выдачи заказов'
// '139': 'Курьер',
// '366': 'Постамат',


// to_location: {
//     code: regionCode,
//     address: "Москва, ул. Миклухо-Маклая, д.36А",
// },