import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { Auth, Container, Loader, ProductCardExtraSmall } from 'src/components'
import './Header.scss'

import { DesktopHeader } from './adaptive/HeaderLinks/DesktopHeader'
import { TabletMobileHeader } from './adaptive/HeaderLinks/TabletMobileHeader'

import { DesktopTabletMenu } from './adaptive/DropMenu/DesktopTabletMenu'
import { MobileMenu } from './adaptive/DropMenu/MobileMenu'
import { Overlay } from '..'
import { useLocation, useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'

const forCustomers = [
    { name: 'Контакты', link: '/for-customers?category=contacts' },
    { name: 'Доставка и оплата', link: '/for-customers?category=delivery' },
    { name: 'Возврат и обмен', link: '/for-customers?category=refund-and-change' },
    // { name: 'Программа лояльности', link: '/for-customers?category=loyalry-program' },
    { name: 'FAQ', link: '/for-customers?category=faq' },
    // { name: 'Политика конфиденциальности', link: '/for-customers?category=politics' },
]

export const Header = observer(({ scrolledY, light }) => {
    const { productStore, basketStore, orderStore } = useContext(AppContext)

    const categoryNameToLinkObject = (categoryType, gender) => {
        return (category) => {
            const genderQuery = gender ? `&gender=${gender}` : ``
            const link = `/products?${categoryType}=${category}` + genderQuery
            return { name: category, link }
        }
    }

    const womens_recommendations = productStore.womens_catalog.recommendations?.map(categoryNameToLinkObject('recommendation', 'ЖЕНСКОЕ')) || []
    const womens_collections = productStore.womens_catalog.collections?.map(categoryNameToLinkObject('collection', 'ЖЕНСКОЕ')) || []
    const womens_clothes = productStore.womens_catalog.clothes?.map(categoryNameToLinkObject('cloth', 'ЖЕНСКОЕ')) || []
    const womens_accessories = productStore.womens_catalog.accessories?.map(categoryNameToLinkObject('accessory', 'ЖЕНСКОЕ')) || []

    const mens_recommendations = productStore.mens_catalog.recommendations?.map(categoryNameToLinkObject('recommendation', 'МУЖСКОЕ')) || []
    const mens_collections = productStore.mens_catalog.collections?.map(categoryNameToLinkObject('collection', 'МУЖСКОЕ')) || []
    const mens_clothes = productStore.womens_catalog.clothes?.map(categoryNameToLinkObject('cloth', 'МУЖСКОЕ')) || []
    const mens_accessories = productStore.mens_catalog.accessories?.map(categoryNameToLinkObject('accessory', 'МУЖСКОЕ')) || []

    const catalog = {
        'ЖЕНСКОЕ': [
            { title: null, items: [...womens_recommendations, ...womens_collections] },
            { title: 'Одежда', items: womens_clothes },
            { title: 'Аксессуары', items: womens_accessories },
        ],
        'МУЖСКОЕ': [
            { title: null, items: [...mens_recommendations, ...mens_collections] },
            { title: 'Одежда', items: mens_clothes },
            { title: 'Аксессуары', items: mens_accessories },
        ],
        'КОЛЛЕКЦИИ': [
            { title: null, items: productStore.collection_catalog.map(categoryNameToLinkObject('collection')) },
        ],
        'О БРЕНДЕ': [],
        'ПОКУПАТЕЛЯМ': [
            { title: null, items: forCustomers }
        ]
    }
    // auth store
    const auth = false

    const [menu, setMenu] = useState(false)
    const closeMenu = () => setMenu(false)
    
    // close menu on side click
    const headerElement = useRef(null)
    useEffect(() => {
        const onClick = event => headerElement.current.contains(event.target) || setMenu(false)
        if (menu) document.addEventListener('click', onClick)
        return () => { menu && document.removeEventListener('click', onClick) }
    }, [menu])

    // close menu on ESCAPE keydown
    const handleEsc = event => event.key === 'Escape' ? setMenu(false) : null
    useEffect(() => {
        document.addEventListener('keydown', handleEsc)
        return () => document.removeEventListener('keydown', handleEsc)
    }, []) // eslint-disable-line

    const handleCategoryToggle = (category) => {
        return () => {
            menu === category
                ? setMenu(false)
                : setMenu(category)
        }
    }

    const handleCategoryOpen = (category) => {
        return () => setMenu(category)
    }

    const { pathname } = useLocation()
    const isMainPage = pathname === '/'

    const isSearch = menu === 'search'
    const isMenu = menu && !isSearch
    const headerClassNames = 'header' + ((menu || (scrolledY && isMainPage) || light) ? ' header--active' : '')
    const menuClassNames = 'header__menu' + (menu ? ' header__menu--active' : '')

    const [authMenu, setAuthMenu] = useState(false)
    const navigate = useNavigate()
    const handleProfileClick = () => {
        auth
            ? navigate('/profile')
            : setAuthMenu(true)
    }
    const closeAuthMenu = () => setAuthMenu(false)

    const handleAboutClick = (event) => {
        if (pathname !== '/') {
            event.preventDefault()
            navigate('/about')
        }
    }

    const [searchString, setSearchString] = useState('')
    const [searchedProducts, setSearchedProducts] = useState([])

    const handleSearchedClick = () => {
        closeMenu()
        setSearchString('')
        setSearchedProducts([])
    }
    
    useEffect(() => {
        // console.log(toJS(productStore.products))
        const formatData = (data) => data.map((product) => {
            const isDiscount = parseFloat(product.price) !== parseFloat(product.regularPrice)
            const oldPrice = isDiscount ? parseFloat(product.regularPrice) : null

            return {
                id: product.databaseId,
                collection: product?.collection,
                name: product.name,
                newPrice: parseFloat(product.price),
                oldPrice: oldPrice,
                images: [product.image.sourceUrl],

                productCategories: product.productCategories,
            }
        })
        setSearchedProducts(formatData(productStore.searchByName(searchString)))
    }, [searchString, productStore])
    
    const isLoading = productStore.loading || basketStore.loading || orderStore.loading


    return (
        <header ref={headerElement} className={headerClassNames}>

            {isLoading &&
                <Loader />}

            {authMenu &&
                <Overlay onClick={closeAuthMenu}>
                    <Auth onClose={closeAuthMenu} />
                </Overlay>}

            {/* HEADER CONTENT */}
            <Container>
                {/* десктопные ссылки */}
                <div className='header--desktop'>
                    <DesktopHeader
                        active={menu}
                        handleCategoryClick={handleCategoryToggle}
                        backetItemCount={basketStore.basketItemCount}
                        handleProfileClick={handleProfileClick}
                        handleAboutClick={handleAboutClick}
                    />
                </div>

                {/* планшетные и мобильные ссылки */}
                <div className='header--tablet-and-mobile'>
                    <TabletMobileHeader
                        handleCategoryClick={handleCategoryOpen}
                        handleMenuClick={setMenu}
                        isMenuOpened={menu}
                        basketItemCount={basketStore.basketItemCount}
                        handleProfileClick={handleProfileClick}
                    />
                </div>
            </Container >

            <div className={menuClassNames}>
                <Container>
                    {isSearch &&
                        <>
                            <input
                                autoFocus
                                className='header__search'
                                placeholder='ВВЕДИТЕ ЗАПРОС'
                                value={searchString}
                                onChange={(event) => setSearchString(event.target.value)}
                            />

                            <div className='flex flex--column flex--g-10 mt--20' style={{ overflowY: 'auto', maxHeight: '300px'}}>
                                {searchedProducts.map((searchedItem, index) => {
                                    return (
                                        // <ProductCardSmall product={searchedItem} />
                                        <div onClick={handleSearchedClick} key={index}>
                                            <ProductCardExtraSmall product={searchedItem} isSearch index={index} />
                                        </div>
                                    )
                                })}
                            </div>
                        </>}

                    {isMenu &&
                        <>
                            {/* десктопное и планшетное меню */}
                            <div className='header__menu--desktop-and-tablet'>
                                <DesktopTabletMenu
                                    active={menu}
                                    handleCategoryClick={handleCategoryOpen}
                                    catalogItems={catalog}
                                    onClose={closeMenu}
                                />
                            </div>

                            {/* мобильное меню */}
                            <div className='header__menu--mobile'>
                                <MobileMenu
                                    active={menu}
                                    handleCategoryClick={handleCategoryOpen}
                                    catalogItems={catalog}
                                    onClose={closeMenu}
                                />
                            </div>
                        </>}
                </Container>
            </div>

        </header >
    )
})