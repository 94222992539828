import './Loader.scss'

export const Loader = () => {
    return (
        <div className='loader_overlay'>
            <div className='loader'>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}