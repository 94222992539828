import { Link, NavLink } from "react-router-dom"

export const DesktopTabletMenu = ({ active = '', handleCategoryClick, catalogItems, onClose }) => {
    const handleLinkStyle = (itemName) => {
        return 'header__link' + (active === itemName ? ' header__link--active' : '') + ' header__link--large'
    }

    return (
        <>
            <div className='header__menu-column header__menu-column--tablet'>
                <button
                    onClick={handleCategoryClick('ЖЕНСКОЕ')}
                    className={handleLinkStyle('ЖЕНСКОЕ')}
                >
                    ЖЕНСКОЕ
                </button>
                <button
                    onClick={handleCategoryClick('МУЖСКОЕ')}
                    className={handleLinkStyle('МУЖСКОЕ')}
                >
                    МУЖСКОЕ
                </button>
                <button
                    onClick={handleCategoryClick('КОЛЛЕКЦИИ')}
                    className={handleLinkStyle('КОЛЛЕКЦИИ')}
                >
                    КОЛЛЕКЦИИ
                </button>
                <NavLink to='/about' className='mt--20'>
                    <button
                        onClick={onClose}
                        className={handleLinkStyle('О БРЕНДЕ')}
                    >
                        О БРЕНДЕ
                    </button>
                </NavLink>
                <button
                    onClick={handleCategoryClick('ПОКУПАТЕЛЯМ')}
                    className={handleLinkStyle('ПОКУПАТЕЛЯМ')}
                >
                    ПОКУПАТЕЛЯМ
                </button>
            </div>

            {
                catalogItems[active]?.map((col, idx) => {
                    return (
                        <div key={idx} className='header__menu-column'>
                            {col.title &&
                                <p className='header__link header__link--title'>{col.title}</p>}
                                
                            {col.items?.map((item, index) => {
                                // console.log(item.link)
                                return (
                                    <Link to={item.link} key={index}>
                                        <p onClick={onClose} className='header__link'>{item.name}</p>
                                    </Link>
                                )
                            })}
                        </div>
                    )
                })
            }
        </>
    )
}