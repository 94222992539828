import React from 'react'
import { DownUpIcon } from '../../common'
import { Link, NavLink } from 'react-router-dom'

export const MobileMenu = ({ active = '', handleCategoryClick, catalogItems, onClose }) => {

    const handleLinkStyle = (itemName) => {
        return 'header__link' + (active === itemName ? ' header__link--active' : '') + ' header__link--large'
    }

    const toggleCategoryClick = (category) => {
        return () => {
            if (active === category) {
                handleCategoryClick(true)()
            } else {
                handleCategoryClick(category)()
            }
        }
    }
    
    const catalogItemKeys = Object.keys(catalogItems)

    return (
        <>
            {catalogItemKeys.map((categoryName, index) => {
                const isActive = categoryName === active
                const isAbout = categoryName === 'О БРЕНДЕ'
                
                if (isAbout) {
                    return (
                        <NavLink key='9999999' to='/about'>
                            <button
                                onClick={onClose}
                                className={handleLinkStyle(categoryName)}
                            >
                                {categoryName}
                            </button>
                        </NavLink>
                    )
                }

                return (
                    <React.Fragment key={index}>
                        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                            <button
                                onClick={toggleCategoryClick(categoryName)}
                                className={handleLinkStyle(categoryName)}
                            >
                                {categoryName}
                            </button>
                            <DownUpIcon isActive={isActive} />

                        </div>

                        {isActive &&
                            catalogItems[active].map((col, index) => {
                                return (
                                    <div key={index} className='header__menu-column'>
                                        {col.title &&
                                            <p className='header__link header__link--title'>{col.title}</p>}

                                        {col.items?.map((item, index) => {
                                            return (
                                                <Link to={item.link} key={index}>
                                                    <p onClick={onClose} className='header__link'>{item.name}</p>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                    </React.Fragment>
                )
            })}

        </>
    )
}