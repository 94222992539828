import { useState } from 'react'
import './FAQ.scss'

export const FAQ = () => {
    const [answers, setAnswers] = useState([1])

    const isCurrentAnswer = (id) => answers.indexOf(id) !== -1
    const handleAnwser = (id) => {
        return () => {
            isCurrentAnswer(id)
                ? setAnswers(prevAnswers => prevAnswers.filter(answ => answ !== id))
                : setAnswers(prevAnswers => [...prevAnswers, id])
        }
    }

    return (
        <div className='for-customers-faq flex flex--column flex--g-30-30-20'>
            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Какие способы оплаты вы принимаете?</h3>

                    <button onClick={handleAnwser(1)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(1) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(1) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>Принимаем оплату по картам.</p>
                    </div>}
            </div>
            
            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Сколько стоит доставка?</h3>

                    <button onClick={handleAnwser(2)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(2) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(2) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>Стоимость доставки рассчитывается автоматически при оформлении заказа, исходя из способа доставки и адреса получателя.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Как быстро будет доставлен мой заказ?</h3>

                    <button onClick={handleAnwser(3)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(3) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(3) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>С момента оплаты доставка займёт 5-7 дней.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Какие способы доставки доступны в интернет-магазине?</h3>

                    <button onClick={handleAnwser(4)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(4) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(4) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>Доставляем заказы СДЭКом до пункта выдачи, постамата, до двери. Также у нас есть самовывоз из клубов: Мытищи Красный Кит, Красногорск и Авиапарк.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Товар не подошел, можно ли его вернуть?</h3>

                    <button onClick={handleAnwser(5)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(5) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(5) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>Возврат осуществляем только в случае заводского брака изделия.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>В какой срок поступят средства, внесенные за возвращенный товар?</h3>

                    <button onClick={handleAnwser(6)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(6) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(6) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>В среднем у банка это занимает 5 рабочих дней.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Как выбрать правильный размер?</h3>

                    <button onClick={handleAnwser(7)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(7) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(7) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>В карточке товара можно найти размерные таблицы и свериться по ним.</p>
                    </div>}
            </div>

            <div className='flex flex--column flex--g-10'>
                <div className='for-customers-faq__header flex flex--jc-sb flex--ai-c'>
                    <h3>Как изменить информацию в уже сделанном заказе?</h3>

                    <button onClick={handleAnwser(8)} className='flex flex--jc-c flex--ai-c'>
                        {isCurrentAnswer(8) ?
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                                <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' fill='#12123B' />
                            </svg> :

                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' fill='#12123B' />
                            </svg>}
                    </button>
                </div>
                    
                {isCurrentAnswer(8) &&
                    <div className='text text--16-14 flex flex--column flex--g-20-20-10'>
                        <p>Свяжитесь с менеджером через раздел обратной связи на сайте.</p>
                    </div>}
            </div>

        </div>
    )
}