import { useState, useEffect } from 'react'
import { visiblePages } from './utils/pages'
import { PaginationButton, PaginationItem } from './common'
import './Pagination.scss'

export const Pagination = ({ setPage, page, pages }) => {
    const [windowWidth, setWindowWidth] = useState(null)
    const pageList = visiblePages(pages, page, (windowWidth <= 768))
    const paginationStyles = 'pagination'

    // перенести в app

    useEffect(() => {
        if (!windowWidth) {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        
        return () => window.removeEventListener('resize', handleResize)
    }, [windowWidth])

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const handleNextPage = () => {
        if (page < pages) {
            setPage(page + 1)
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }


    const renderedPageList = pageList.map((p, idx) => {
        return (
            <PaginationItem
                key={idx + Date.now()}
                selected={p === page}
                placeholder={!p}
                onClick={p ? () => setPage(p) : null}
            >
                {p || '...'}
            </PaginationItem>
        )
    })

    return (
        <div className={paginationStyles}>
            <PaginationButton left onClick={handlePrevPage} />

            {renderedPageList}
            
            <PaginationButton onClick={handleNextPage} />
        </div>
    )
}
