import './ProductCardSlide.scss'

export const ProductCardSlidePlaceholder = () => {    
    return (
        <div className='product-card-slide product-card-slide--placeholder'>
            <div className='product-card-slide__image-container'>
                <div className='product-card-slide__image placeholder'></div>
            </div>

            <div className='product-card-slide__info'>
                <p className='product-card-slide__name placeholder' data-attr='placeholder'></p>
                <p className='product-card-slide__name placeholder placeholder--w-50' data-attr='placeholder'></p>
            </div>
        </div>
    )
}