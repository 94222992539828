import '../CatalogSort.scss'

export const OrderModal = ({ handleSortClick }) => {
    return (
        <div className='catalog-sort__param-modal' style={{ gap: 10 }}>
            <button
                onClick={handleSortClick('price', 'asc')}
                className='text text--16 text--hover-dark'
            >
                Сначала&nbsp;дешевле
            </button>

            <button
                onClick={handleSortClick('price', 'desc')}
                className='text text--16 text--hover-dark'
            >
                Сначала&nbsp;дороже
            </button>
        </div>
    )
}