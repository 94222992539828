import './Checkbox.scss'

export const Checkbox = ({ children, disabled, checked = false, onClick }) => {
    const checkboxClasses = 'checkbox'
        + (disabled ? ' checkbox--disabled' : '')

    if (disabled) {
        onClick = () => { }
    }

    return (
        <div className={checkboxClasses}>
            <input />
            <svg onClick={onClick} type='checkbox' width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect y='0.5' width='16' height='16' rx='4' fill={checked ? '#676785' : '#fff'} />
                <rect x='0.5' y='1' width='15' height='15' rx='3.5' stroke='#676785' strokeOpacity='0.3' />
                <path className='animate-svg' strokeDashoffset={checked ? '0' : '9'} strokeDasharray='9' d='M5 8.1L7.25 10.5L11 6.5' stroke='#fff' strokeWidth='2' strokeLinecap='round' />
            </svg>
            <label>{children}</label>
        </div>
    )
}
