import './ProductCardSmall.scss'

export const ProductCardSmallPlaceholder = () => {
    return (
        <div className='product-card-small'>
            <div className='product-card-small__image'></div>

            <div className='product-card-small__container'>
                <div>
                    <p className='product-card-small__sub-title placeholder placeholder--w-50' data-attr='placeholder'></p>

                    <div className='flex flex--column flex--g-5 mt--10'>
                        <div className='product-card-small__title placeholder' data-attr='placeholder'></div>
                        <div className='product-card-small__title placeholder placeholder--w-50' data-attr='placeholder'></div>
                    </div>
                </div>

                <div className='product-card-small__price placeholder placeholder--w-25' data-attr='placeholder'></div>

                <div className='product-card-small__footer placeholder' data-attr='placeholder'></div>
            </div>
        </div>
    )
}