import '../styles/AboutBlock.scss'
import aboutImage from '../images/about.png'

export const AboutBlock = () => {
    return (
        <div className='container container--sm' id='about'>
            <div className='about-block mt--10'>
                <div className='about-block__info'>
                    <h2 className='title title--light'>
                        О БРЕНДЕ
                    </h2>

                    {/* <p className='about-block__text text text--16-14 text--white'>
                        DDX Fitness – первая в России сеть фитнес-клубов, работающая по модели Luxury Low Cost. Это не классический фитнес клуб, а велнесс-пространство нового формата с удобной системой оплаты и facilities высочайших стандартов.
                        <span> В настоящее время это 35 оснащенных первоклассным оборудованием спортивных клубов, и более 15 новых площадок готовятся к открытию в ближайшее время.</span>
                    </p> */}

                    {/* <p className='about-block__text text text--16-14 text--white'>
                        DDX Fitness – первая в России сеть фитнес-клубов, работающая по модели Luxury Low Cost. Это не классический фитнес клуб, а велнесс-пространство нового формата с удобной системой оплаты и facilities высочайших стандартов.
                        <span> В настоящее время это 35 оснащенных первоклассным оборудованием спортивных клубов, и более 15 новых площадок готовятся к открытию в ближайшее время.</span>
                    </p> */}
                    

                    <p className='about-block__text text text--16-14 text--white'>
                        DDX Fitness – это твоё велнесс-пространство нового формата с итальянским премиальным оборудованием Technogym, спа-зоной и групповыми программами от высокоинтенсивных силовых для бодрости до йоги для успокоения. Сегодня открыто более 35 клубов в Москве, Московской области, Казани, Ростове и Ярославле. Дальше – больше!
                    </p>

                    <p className='about-block__text text text--16-14 text--white'>
                        Наш фитнес – про здоровое отношение, любовь и принятие себя, про активный образ жизни и энергию. Это по любви и с удовольствием. Эту мысль мы и несём с 2018 года, а теперь ещё и в коллекции мерча.
                    </p>

                    <a
                        className='about-block__text text text--16-14 text--white'
                        href='https://www.ddxfitness.ru/tarrifs/'
                        target='_blank' rel='noreferrer'
                    >
                        Присоединяйся к DDX Fitness от 1700 руб/мес!
                    </a>
                    
                </div>

                <img className='about-block__img' src={aboutImage} alt='about' />
            </div>
        </div>
    )
}