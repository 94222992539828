import './Delivery.scss'

export const Delivery = () => {
    return (
        <div className='for-customers-delivery flex flex--column flex--g-30'>
            {/* <div>
                <h3 className='text text--futura text--20-16 text--dark text--uppercase'>КУРЬЕРСКАЯ ДОСТАВКА СЛУЖБОЙ СДЭК</h3>
                <p className='mt--10 text text--16-14'>Рассчитывается при оформлении, бесплатно от 5 000Р.Сообщим ожидаемый срок и трек-номер по электронной почте в течение дня после оплаты. График отправлений: понедельник–суббота в 15:00 GMT+3 со склада в Москве.
                    Доставка работает в будние с 10:00 до 18:00 по местному времени.Доставка без возможности примерить или сдать товар курьеру.Для доставки в ПВЗ напишите комментарий к заказу.
                    В период проведения распродажи сроки обработки и доставки могут быть увеличены на 4-5 рабочих дней.</p>
            </div>

            <div>
                <h3 className='text text--futura text--20-16 text--dark text--uppercase'>САМОВЫВОЗ ИЗ клубов сети</h3>
                <p className='mt--10 text text--16-14'>Рассчитывается при оформлении, бесплатно от 5 000Р.Сообщим ожидаемый срок и трек-номер по электронной почте в течение дня после оплаты. График отправлений: понедельник–суббота в 15:00 GMT+3 со склада в Москве.Доставка работает в будние с 10:00 до 18:00 по местному времени.Доставка без возможности примерить или сдать товар курьеру.Для доставки в ПВЗ напишите комментарий к заказу. В период проведения распродажи сроки обработки и доставки могут быть увеличены на 4-5 рабочих дней.</p>
            </div>

            <div>
                <h3 className='text text--futura text--20-16 text--dark text--uppercase'>Оплата</h3>
                <p className='mt--10 text text--16-14'>Рассчитывается при оформлении, бесплатно от 5 000Р.Сообщим ожидаемый срок и трек-номер по электронной почте в течение дня после оплаты. График отправлений: понедельник–суббота в 15:00 GMT+3 со склада в Москве.Доставка работает в будние с 10:00 до 18:00 по местному времени.Доставка без возможности примерить или сдать товар курьеру.Для доставки в ПВЗ напишите комментарий к заказу. В период проведения распродажи сроки обработки и доставки могут быть увеличены на 4-5 рабочих дней.</p>
            </div> */}

            <p className='text text--16-14'>Заказы, размещенные через сайт АДРЕС САЙТА, обрабатываются и отправляются в рабочие дни (с понедельника по пятницу, за исключением официальных выходных в России) с 11:00 до 19:00</p>
            <p className='text text--16-14'>Доставка по России осуществляется курьерской службой СДЭК. Доставка выполняется курьером до вашей двери или до пункта выдачи СДЭК.</p>
            <p className='text text--16-14'>Информацию о работе пунктов выдачи заказа вы можете получить на сайте СДЭК.</p>
            <p className='text text--16-14'>Мы рекомендуем вскрывать упаковку и проверять целостность заказа при курьере. В случае, если в вашей посылке обнаружен брак, запросите у курьера акт приема-передачи груза и после его заполнения верните заказ курьеру. Также вам будет необходимо связаться с нашим менеджером.</p>
            <p className='text text--16-14'>Информацию об отслеживании заказа вы получите в виде смс сообщения после его отгрузки. Более детальную информацию об отслеживании вы можете получить на сайте (и в приложении) СДЭК <a className='text text--16-14 text--hover-orange' href='https://www.cdek.ru/ru/TRACKING/' target='_blank' rel='noreferrer'>CDEK.RU/RU/TRACKING.</a></p>
        </div>
    )
}