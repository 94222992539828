import React, { useState } from 'react'
import './SizeTable.scss'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validateEmail } from 'src/utils'

import personalData from 'src/files/personal_data.pdf'
import offer from 'src/files/offer.pdf'
import { sendQuestion } from 'src/api/question'
import { Checkbox } from 'src/components'

const sizeTables = {
    'hoodie-oversize': {
        tableHead: ['Размер', 'Ширина', 'Длина рукава', 'Длина'],
        tableBody: [
            ['xs', '60', '71', '52'],
            ['s', '64', '75', '56   '],
            ['m', '68', '79', '60'],
            ['l', '72', '83', '64'],
        ],
        description: 'Худи оверсайз широкие, но не длинные. Поэтому в первую очередь советуем ориентироваться на длину.',
    },
    't-shirt-oversize': {
        tableHead: ['Размер', 'Ширина', 'Длина рукава', 'Длина'],
        tableBody: [
            ['s', '61', '43', '59'],
            ['m', '63', '45', '65'],
            ['l', '65', '47', '69'],
        ],
        description: 'Футболки оверсайз шире и длиннее стандартных футболок, плюс у них удлиненный рукав.',
    }
}

export const SizeTable = ({ type = 'hoodie-oversize', onClose }) => {
    const stopPropagation = (event) => event.stopPropagation()
    const { tableHead, tableBody, description } = sizeTables[type]

    const [isSendQuestion, setSendQuestion] = useState(false)
    const openQuestionForm = () => setSendQuestion(true)

    const [isThanksMessage, setThanksMessage] = useState(false)

    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            question: '',
            personalData: true,
            // rim: true,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().max(50).test('invalid email', 'invalid email', validateEmail),
            question: Yup.string().required().min(3).max(255),
            personalData: Yup.bool().test('invalid value', 'invalid value', val => val),
        }),
        onSubmit: async (values, actions) => {
            setLoading(true)
            sendQuestion(values.email.trim(), values.question.trim())
                .then(res => {
                    formik.resetForm()
                    setThanksMessage(true)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    })

    const handleInputClassNames = (name, type = 'input') => {
        const isError = formik.errors[name] && formik.touched[name]
        return isError ? `${type} ${type}--error` : `${type}`
    }

    const isSubmitButtonDisabled = Object.keys(formik.errors).length || loading

    const showForm = isSendQuestion && !isThanksMessage
    const showThanks = isSendQuestion && isThanksMessage

    const showSizeTable = !isSendQuestion && !isThanksMessage

    return (
        <div className='size-table' onClick={stopPropagation}>
            <div className='size-table__header flex flex--ai-c'>
                <button onClick={onClose}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <rect x='7.41406' y='6' width='16' height='2' rx='1' transform='rotate(45 7.41406 6)' fill='#676785' />
                        <rect x='6' y='17.3138' width='16' height='2' rx='1' transform='rotate(-45 6 17.3138)' fill='#676785' />
                    </svg>
                </button>
            </div>

            {showForm &&
                <div className='size-table__form block block--sm'>
                    <h3 className='title title--sm'>Свяжитесь с нами</h3>

                    <form onSubmit={formik.handleSubmit} className='flex flex--column flex--g-10'>
                        <input
                            className={handleInputClassNames('email')}
                            type='text' placeholder='Email' name="email" id="email"
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            value={formik.values.email}
                            disabled={loading}
                        />
                        <textarea
                            className={handleInputClassNames('question', 'textarea')}
                            error={(formik.errors.question && formik.touched.question) && 'true'}
                            type='text' placeholder='Введите вопрос...' name="question" id="question"
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            value={formik.values.question}
                            disabled={loading}
                        ></textarea>

                        <Checkbox
                            name='personalData' id='personalData'
                            checked={formik.values.personalData}
                            onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                            disabled={loading}
                        >
                            {/* Я согласен с <a href={offer} target='_blank' rel='noreferrer'>публичным договором-офертой</a> и <a href={personalData} target='_blank' rel='noreferrer'>политикой обработки персональных данных</a> */}
                            Я согласен с <a href={personalData} target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                        </Checkbox>

                        <div className='contact-us__footer mt--10'>
                            <button
                                className='btn btn--sm'
                                disabled={isSubmitButtonDisabled}
                                type='submit'
                            >
                                Отправить
                            </button>
                        </div>
                    </form>
                </div>}

            {showThanks &&
                <div className='question-sent-modal__body flex flex--column flex--g-30'>
                    <h3 className='question-sent-modal__title'>Спасибо!</h3>
                    <p className='text'>Ваше обращение зарегистрировано, скоро вам ответит оператор</p>

                    <button onClick={onClose} className='btn btn--light'>Закрыть</button>
                    <div className='mt--10'></div>
                </div>}

            {showSizeTable &&
                <div className='size-table__body mt--100-30'>
                    <h3 className='size-table__title text text--dark text--uppercase text--500'>Таблица размеров</h3>

                    {description &&
                        <p className='text text--500 mt--20'>{description}</p>}

                    <div className='size-table__table mt--30'>
                        <div className='size-table__table-row size-table__table-row--header'>
                            {tableHead.map((head, index) => {
                                return (
                                    <p key={index} className='text text--14-12'>{head}</p>
                                )
                            })}
                        </div>

                        {tableBody.map((sizeItem, index) => {
                            const isDivider = index < tableBody.length - 1
                            return (
                                <React.Fragment key={index}>
                                    <div className='size-table__table-row'>
                                        {sizeItem.map((size, index) => {
                                            const isHeader = index === 0
                                            const sizeClassNames = isHeader
                                                ? 'text text--16-14 text--dark text--500 text--uppercase'
                                                : 'text text--16'
                                            return (
                                                <p key={index} className={sizeClassNames}>{size}</p>
                                            )
                                        })}
                                    </div>

                                    {isDivider &&
                                        <div className='size-table__table-divider'></div>}
                                </React.Fragment>
                            )
                        })}
                    </div>

                    <p className='text text--16-14 mt--100-30'>Не уверены в правильном выборе размера? Напишите нам и мы вам поможем</p>
                    <button onClick={openQuestionForm} className='size-table__btn btn btn--sm btn--light mt--30-20'>Связаться с нами</button>
                </div>}


        </div>
    )
}