import axios from 'axios'

export const sendQuestion = async (email = '', question = '') => {
    try {
        const response = await axios.post('https://api.ddxmerch.ru/wp-json/wc/custom/send-question', {
            email,
            question,
        })
        
        return response.data
    } catch (err) {
        console.log(err)
    }
}