import axios from "axios"

export const getPendingOrders = async () => {
    try {
        const headers = {
            'Woocommerce-Session': 'Session ' + localStorage.getItem('woo-session')
        }
        const response = await axios.get('https://api.ddxmerch.ru/wp-json/wc/custom/pending_orders', { headers })
        return response.data
    } catch (err) {
        // console.log(err)
        return err?.response?.data?.message
    }
}